@import "../../../../../../assets/scss/variable.scss";

.landing-home-featured-fixture-slider-wrap {
  margin-bottom: 50px;

  @media (max-width: 1080px) {
    margin-bottom: 30px;
  }

  .landing-home-slider-header {
    text-align: center;
  }
}
