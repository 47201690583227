@import "../../../../../../assets/scss/variable.scss";

.landing-home-ranking {
  margin-bottom: 50px;

  @media (max-width: 1080px) {
    margin-bottom: 30px;
  }

  .no-margin-bottom {
    margin-bottom: 0px;
  }
}
