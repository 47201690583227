@import "../../../../assets/scss/variable.scss";

.header-contain-wrap {
  .main-menu-wrap {
    border-bottom: 1px solid #e0e0e0;
    background-color: #fff !important;
    padding: 0px 0px 0px 21px;
    width: 100%;
    top: 46px;
    box-shadow: 0px 1px 5px 0px #00000029;

    // .header-wrap {
    //   padding: 0 20px;
    //   height: 60px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;

    //   .logo-nav-container {
    //     display: flex;
    //     align-items: center;
    //     gap: 40px;

    //     .logo-container {
    //       img {
    //         height: 35px;
    //         width: auto;
    //       }
    //     }

    //     .nav-menu {
    //       display: flex;
    //       align-items: center;
    //       gap: 25px;

    //       .nav-link {
    //         color: #333;
    //         text-decoration: none;
    //         font-size: 14px;
    //         font-weight: 500;
    //         padding: 8px 12px;
    //         border-radius: 4px;
    //         transition: all 0.3s ease;

    //         &:hover,
    //         &.active {
    //           color: #0066cc;
    //           background-color: rgba(0, 102, 204, 0.08);
    //         }
    //       }
    //     }
    //   }
    // }

    .header-left-menu-wrap {
      display: flex;
      align-items: center;
      column-gap: 18px;

      .MenuTopBar-button {
        cursor: pointer;
      }
    }

    .login-buttons {
      padding: 0px !important;
      display: flex;
      justify-content: end;

      .signup-login-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (max-width: 599px) {
          justify-content: space-between;
        }
      }

      .profile-logout-wrap {
        display: flex;
        justify-content: center;
        // justify-content: flex-end;
        align-items: center;
        flex-direction: column;

        .profile-img-wrap {
          width: 36px;
          height: 36px;
          border: 1px solid #fc4714;
          border-radius: 50%;
          cursor: pointer;

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            border-radius: 50%;
          }
        }

        .user-name {
          line-height: 14px;
          font-size: 11.42px;
          // margin-left: 9px;
          color: #4455c7;
          font-weight: 600;
          text-transform: capitalize;
        }
      }

      .auth-btn {
        font-size: 16px;
        font-weight: normal;
        line-height: 19px;
        border: 1px solid #4455c7;
        border-radius: 8px;
        letter-spacing: 0px;
      }

      .signup {
        background: #4455c7;
        color: #fff;
        margin-right: 9px;
        padding: 12px 21px 11px 21px;

        @media (max-width: 799px) {
          width: max-content;
          padding: 6px 12px;
        }
      }

      .signup:hover {
        background: #4455c7 !important;
      }

      .signin {
        background: #fff;
        color: #4455c7;
        padding: 12px 19.5px 11px 19.5px;

        @media (max-width: 799px) {
          width: max-content;
          padding: 6px 12px;
        }
      }

      .signin:hover {
        background: #fff !important;
      }

      .profile-icon {
        background: #4455c7 !important;
        // margin-left: 9px;
        border: 1px solid #fc4714;
        padding: 5px;
      }

      .logout {
        margin-right: 0px;
        margin-left: 9px;
      }

      .bet-slip-button {
        background-color: $color-Accent-1;
        height: 64px;
        display: flex;
        align-items: center;
        padding: 0px 12px;
        cursor: pointer;
        margin-left: 18px;

        @media (max-width: 600px) {
          flex-direction: column;
          justify-content: center;
          padding: 0px 6px;
          min-width: fit-content;
          height: 65px;
        }

        .bet-slip {
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-White;

          @media (max-width: 799px) {
            font-size: 11.42px;
            line-height: 14px;
          }
        }

        .bet-slip-count {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: $color-White;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Accent-1;
          text-align: center;
          margin-left: 11px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 799px) {
            width: 18px;
            height: 18px;
            font-size: 11.42px;
            line-height: 14px;
          }

          @media (max-width: 600px) {
            margin-left: 0px;
          }
        }
      }
    }

    // @media screen and (max-width: 799px) {
    //   .mobile-menu-button {
    //     display: flex;
    //     justify-content: flex-end;
    //     padding-right: 16px;

    //     .MenuTopBar-button {
    //       width: 24px;
    //       height: 24px;
    //       cursor: pointer;
    //     }
    //   }

    //   .header-left-menu-wrap {
    //     padding: 8px 16px;

    //     .App-logo-main {
    //       max-width: 180px !important;
    //     }
    //   }

    //   .signup-login-wrap {
    //     display: none;
    //   }

    //   .profile-logout-wrap {
    //     padding-right: 16px;

    //     .user-name {
    //       display: none;
    //     }

    //     .profile-icon {
    //       padding: 8px;
    //     }
    //   }

    //   .bet-slip-button {
    //     margin-left: 8px;
    //     padding: 4px 8px;

    //     .bet-slip {
    //       display: none;
    //     }

    //     .bet-slip-count {
    //       min-width: 24px;
    //       height: 24px;
    //       padding: 0 6px;
    //       font-size: 14px;
    //     }
    //   }
    // }
  }

  .Menu-collapse {
    width: 228px;
    position: absolute;
    z-index: 9999;
    left: 21px;
    background-color: $color-Primary;
    color: $color-White;
    top: 53px;

    .left-sidebarcontent {
      .menu-wrap {
        padding: 24px 19px 58px;
      }
    }
  }

  .betslip-collapse {
    top: 64px;

    @media (max-width: 799px) {
      top: 0px;
    }
  }
}

// Responsive styles
@media (max-width: 768px) {
  .main-menu-wrap {
    .header-wrap {
      padding: 0 15px;

      .logo-nav-container {
        gap: 20px;

        .nav-menu {
          display: none; // Hide navigation on mobile
        }
      }
    }
  }
}