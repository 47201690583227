@import "../../../../../../assets/scss/variable.scss";

.landing-featured-news-component-wrap {
  margin-bottom: 50px;

  @media (max-width: 1080px) {
    margin-bottom: 30px;
  }

  .grid-container {
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 1080px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
    }

    .slick-slide {
      @media (max-width: 1080px) {
        width: calc(100vw - 24px);
      }
    }

    .grid-item1 {
      @media (max-width: 1080px) {
        height: 440px;
      }
    }

    .responsive-news-view {
      @media (max-width: 1080px) {
        display: flex;

        .details-wrapper {
          border-radius: 0 0 8px 0;
          min-height: 100%;
          position: inherit;
          width: 100%;
        }
      }
    }
  }
}
