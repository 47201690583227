@import "../../../assets/scss/variable.scss";

.new-team-sports-component-wrap {
  .bredcrumn-wrap {
    margin-bottom: 3px;
    margin-top: 33px;

    @media only screen and (max-width: 599px) {
      margin-bottom: 5px;
      margin-top: 12px;
    }

    li.MuiBreadcrumbs-separator {
      color: $color-Black;
    }

    li {
      font-size: 11.42px;
      line-height: 14px;

      p {
        font-size: 11.42px;
        line-height: 14px;
        color: $color-Accent-1;
        text-transform: uppercase;
      }

      a {
        color: $color-Black;
        font-size: 11.42px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }
  }

  .title-btn-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // @media only screen and (max-width: 799px) {
    //     display: block;
    // }

    h1 {
      line-height: 56px;
      color: $color-Black;

      @media only screen and (max-width: 599px) {
        font-size: 31.36px;
        line-height: 40px;
      }
    }
  }

  .w-full {
    width: 100%;
  }

  .exprt-tips-btn {
    max-width: 212px;
    width: 100%;
    padding: 8px 24px 12px 24px;
    background: $bg-new-home-button;
    color: $color-White;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    font-family: $regulerFont;
    border-radius: 8px;
    text-transform: capitalize;
    cursor: pointer;

    .exprt-btn-wrap {
      display: flex;
      column-gap: 6px;
      justify-content: center;
      align-items: center;

      @media (max-width: 599px) {
        column-gap: 2px;
      }
    }

    @media (max-width: 559px) {
      max-width: 100%;
      padding: 5px;
    }
  }

  .select__control {
    background-color: $color-Light-grey;
    border-radius: 8px;
    max-height: 45px;
    max-width: 400px;

    .select__single-value {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 400;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__placeholder {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 400;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__input-container {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 400;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__indicator-separator {
      width: 0px;
    }

    .select__dropdown-indicator {
      padding: 10px;
    }
  }

  .select__menu-portal {
    z-index: 99;
  }

  .select__control,
  .react-select__control {
    &.select__control--is-disabled {
      // border-color: $border-color;
      .select__indicator-separator {
        //   background-color: $border-color;
      }
    }

    // &.select__control--is-focused,
    // &.react-select__control--is-focused {

    // }

    &.select__control--menu-is-open {
      box-shadow: none;
      // border: none;
    }

    .select__indicator svg {
      cursor: pointer;
    }

    .select__indicator-separator {
      display: none;
    }

    .select__single-value {
      // border: none;
      font-size: 16px;
      line-height: 20px;
      padding-left: 10px;
      outline: none;
      cursor: pointer;
      margin: 0px;
      font-family: "Inter", sans-serif;
    }

    .select__value-container {
      // border: none;
      padding: 0px;
      margin: 0px;

      .select__input-container {
        font-size: 16px;
        font-family: "Inter", sans-serif;
        // margin-left: 10px;
      }
    }

    .select__placeholder {
      font-size: 16px;
      line-height: 20px;
      padding-left: 10px;
      cursor: pointer;
      font-family: $regulerFont;
    }
  }

  .select__menu {
    margin: 0px;
    border-radius: 0px;
    padding: 0px;
    z-index: 999;
  }

  .select__menu-list {
    padding: 0px;
  }

  // Select Menu
  .select__menu,
  .react-select__menu {
    .select__menu-list,
    .react-select__menu-list {
      .select__option,
      .react-select__option {
        cursor: pointer;
        font-size: 16px;
        color: $color-Black;
        font-family: $regulerFont;
        line-height: 19px;
        padding: 11px;

        &.select__option--is-focused {
          background-color: $color-grey;
          color: #000;
        }

        &.select__option--is-selected {
          background-color: $color-grey;
          color: $color-Black;
        }
      }
    }

    .select__menu-list,
    .react-select__menu-list {
      .select__group {
        .select__group-heading {
          margin-bottom: 0.5rem;
          color: green;
          font-weight: bolder;
          font-size: inherit;
        }
      }
    }
  }
}

.new-landing-table-container-details {
  border-radius: 8px;

  .no-border-radius {
    border-radius: 0px !important;
  }

  .MuiTableContainer-root {
    border-radius: 8px;
    margin-bottom: 18px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 6px #0000000d;

    .new-landing-landing-table {
      border-collapse: collapse;

      .text-align-center {
        justify-content: center;
      }

      .MuiTableHead-root {
        .MuiTableRow-head {
          .MuiTableCell-head {
            background-color: $color-Primary;
            color: $color-White;
            font-size: 14px;
            line-height: 16px;
            font-weight: 600;
            font-family: $regulerFont;
            letter-spacing: 0px;
            padding: 13px 13px 12px;

            @media (max-width: 1023px) {
              font-size: 12px;
              line-height: 15px;
              padding: 6px 10px;
            }

            .sorting-cell {
              display: flex;
              align-items: center;
              column-gap: 4px;
              cursor: pointer;

              .sort-icon-wrap {
                display: flex;
                align-items: center;
                flex-direction: column;
                row-gap: 2px;

                span {
                  line-height: 0px;
                }

                .down-arrow {
                  transform: rotate(180deg);
                }
              }

              .sort-icon-wraps span {
                opacity: 0.5;
                transition: opacity 0.2s ease-in-out;
              }

              .sort-icon-wraps span.active {
                opacity: 1;
              }

              .asc-sort {
                path {
                  fill: #4455c7;
                }
              }

              .desc-sort {
                path {
                  fill: #4455c7;
                }
              }
            }
          }

          .pts-head-cell {
            background-color: $color-Accent-1;
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableCell-body {
          padding: 9px 13px;
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          font-family: $regulerFont;
          letter-spacing: 0px;
          color: $color-Black;
          border-bottom: 1px solid #e7e9ec;

          @media (max-width: 1023px) {
            font-size: 14px;
            line-height: 17px;
            padding: 6px 10px;
          }

          .team-wrap {
            display: flex;
            align-items: center;
            column-gap: 4px;

            .team-image-wrap {
              width: 36px;
              min-width: 36px;
              height: 36px;
              border-radius: 50%;

              @media (max-width: 1023px) {
                width: 30px;
                min-width: 30px;
                height: 30px;
              }

              .team-image {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: contain;
              }
            }

            .team-name {
              font-size: 16px;
              line-height: 19px;
              font-weight: 600;

              @media (max-width: 1023px) {
                font-size: 14px;
                line-height: 17px;
              }
            }
          }
        }

        .pts-body-cell {
          background-color: rgb(0 55 100 / 10%);
        }

        .form-cell-details {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 6px;

          .win-loss-status {
            display: flex;
            align-items: center;
            column-gap: 6px;
            width: 155px;

            @media (max-width: 1023px) {
              width: 115px;
            }

            span {
              display: block;
              width: 26px;
              height: 23px;
              border-radius: 3px;
              font-size: 14px;
              line-height: 23px;
              font-weight: 500;
              letter-spacing: 0px;
              color: $color-White;

              @media (max-width: 1023px) {
                width: 18px;
                min-width: 18px;
                height: 19px;
                font-size: 12px;
                line-height: 19px;
              }
            }
          }
        }
      }

      .rnk-cell {
        min-width: 30px;
      }

      .team-cell {
        left: 50px;
      }

      .inner-table-row {
        .MuiTableCell-body {
          padding: 0px;
        }
      }

      .inner-table {
        .MuiTableHead-root {
          .MuiTableRow-head {
            .MuiTableCell-head {
              background-color: $color-Light-grey;
              color: $color-Black;
              font-size: 14px;
              line-height: 16px;
              font-weight: 600;
              font-family: $regulerFont;
              letter-spacing: 0px;
              padding: 10px 33px 9px;

              @media (max-width: 1023px) {
                font-size: 12px;
                line-height: 15px;
                padding: 6px 10px;
              }
            }
          }
        }

        .MuiTableBody-root {
          .MuiTableCell-body {
            padding: 9px 33px;
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;
            letter-spacing: 0px;
            color: $color-Black;
            border-bottom: 1px solid #e7e9ec;

            @media (max-width: 1023px) {
              font-size: 14px;
              line-height: 17px;
              padding: 6px 10px;
            }
          }

          .description-status {
            display: flex;
            align-items: center;

            .win-indicator-status {
              display: block;
              width: 26px;
              height: 23px;
              border-radius: 3px;
              font-size: 14px;
              line-height: 23px;
              font-weight: 500;
              letter-spacing: 0px;
              color: $color-White;
              text-align: center;
              margin-right: 8px;

              @media (max-width: 1023px) {
                width: 18px;
                min-width: 18px;
                height: 19px;
                font-size: 12px;
                line-height: 19px;
              }
            }
          }

          .team-score {
            @media (max-width: 1023px) {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}

.legend-text {
  font-size: 16px;
  line-height: 22.4px;
  font-weight: 400;
  font-family: $regulerFont;
  color: $color-Black;
  letter-spacing: 0px;

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 18px;
  }
}
