@import "../../../../../assets/scss/variable.scss";

.blackbook-listing-modal-details {
  padding: 0px;
  .blackbook-wrap {
    @media (max-width: 439px) {
      margin: 0px;
    }
    .tab-search-section {
      background-color: transparent;
      box-shadow: none;
      border-bottom: 1px solid $color-Light-grey;
      .team-sports-tab {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid $color-Accent-1;
        @media (max-width: 639px) {
          justify-content: flex-end;
          flex-direction: row-reverse;
          border-bottom: none;
          column-gap: 5px;
        }
      }
      .teamsport-tab-detail {
        margin-top: 0px;
        border-bottom: none;
        @media (max-width: 639px) {
          border-bottom: 2px solid #4455c7;
          width: 100%;
        }
      }
      .filter-relative {
        position: relative;
        .filters-container {
          display: flex;
          align-items: center;
          column-gap: 28px;
          background-color: $color-Light-grey;
          padding: 5px 9px;
          border-radius: 8px;
          overflow: hidden;
          cursor: pointer;
          .filters-icon-label {
            display: flex;
            align-items: center;
            column-gap: 6px;
          }
          .filter-icon {
            display: flex;
            svg {
              path {
                stroke: $color-Black-1;
              }
            }
          }
          .up-arrow {
            transform: rotate(180deg);
            transition: all 0.2s;
          }
        }
        .filter-section {
          position: absolute;
          top: 42px;
          right: 0px;
          width: 336px;
          background-color: white;
          z-index: 9;
          padding: 12px;
          box-sizing: border-box;
          box-shadow: 0px 1px 6px #00000026;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          row-gap: 6px;

          @media (max-width: 639px) {
            right: auto;
            left: 0px;
          }

          .filter-list-racing {
            display: flex;
            align-items: center;
            min-height: 40px;
          }

          .Filteritemlist-racing {
            display: flex;
            list-style-type: none;
            padding: 0px;
            margin: 0px;

            li {
              margin-right: 14px;

              label {
                display: flex;
                column-gap: 5.2px;
                font-size: 12px;
                line-height: 15px;
              }

              .MuiButtonBase-root {
                padding: 0px;
              }
            }
          }

          .country-state-wrap {
            display: flex;
            column-gap: 5px;
            .country-select {
              width: 50%;
            }
            .State-select {
              width: 50%;
            }
          }

          .select__control {
            background-color: $color-Light-grey;
            border-radius: 8px;
            max-height: 36px;

            .select__single-value {
              font-family: $regulerFont !important;
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 600;
              color: $color-Black;
              padding: 0px 8px;
            }

            .select__placeholder {
              font-family: $regulerFont !important;
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 600;
              color: $color-Black;
              padding: 0px 8px;
            }

            .select__input-container {
              font-family: $regulerFont !important;
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 600;
              color: $color-Black;
              padding: 0px 8px;
            }

            .select__indicator-separator {
              width: 0px;
            }

            .select__dropdown-indicator {
              padding: 10px;
            }
          }

          .select__menu-portal {
            z-index: 99;
          }

          .select__menu,
          .react-select__menu {
            .select__menu-list,
            .react-select__menu-list {
              .select__option,
              .react-select__option {
                cursor: pointer;
                font-size: 16px;
                color: $color-Black;
                font-family: $regulerFont;
                line-height: 19px;
                padding: 11px;

                &.select__option--is-focused {
                  background-color: #d4d6d8;
                  color: #000;
                }

                &.select__option--is-selected {
                  background-color: #d4d6d8;
                  color: $color-Black;
                }
              }
            }

            .select__menu-list,
            .react-select__menu-list {
              .select__group {
                .select__group-heading {
                  margin-bottom: 0.5rem;
                  color: green;
                  font-weight: bolder;
                  font-size: inherit;
                }
              }
            }
          }

          .switch-box {
            margin-right: 0px;

            .MuiFormControlLabel-root {
              margin-left: 0px;
              width: 100%;
              justify-content: space-between;

              .MuiSwitch-root {
                padding: 0px;
                width: 39px;
                height: 20px;
                border-radius: 10px;
                margin-left: 9px;

                .MuiButtonBase-root {
                  padding: 0px;
                }

                .Mui-checked {
                  color: #4455c7;
                }

                .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
                  background-color: #d6d9f3;
                }
              }

              .MuiTypography-root {
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
                font-family: $regulerFont;
              }
            }
          }

          .reset-all {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .reset-all-text {
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 600;
              color: $color-Black;
              font-family: $regulerFont;
              border-bottom: 1px solid $color-Black;
            }
          }
        }
      }
      .day-tab-details {
        padding: 12px 0px 0px;
      }
    }
    .page-deatils-wrap {
      min-height: 80vh;
      padding: 0px 18px 12px;
      background-color: transparent;
      box-shadow: none;
      .page-content-wrap-v2 {
        margin-top: 0px;
        padding-bottom: 0px;
        .blackbook-runner-details {
          padding: 15px 18px;
          background: transparent;
          .Mobile-racing-data {
            .racing-data-row {
              background-color: $color-Light-grey;
            }
          }

          @media (max-width: 799px) {
            padding: 12px 9px;
          }

          &:not(:last-child) {
            margin-bottom: 12px;
          }
          .runner-details-v2 {
            padding: 9px 0px;
            justify-content: space-between;
            @media (max-width: 1280px) {
              flex-direction: row;
            }
            @media (max-width: 799px) {
              flex-direction: column;
            }
            .runner-info-v2 {
              max-width: calc(100% - 363px);
              width: 100%;
              @media (max-width: 799px) {
                max-width: none;
              }
              .icon-details-wrap {
                display: flex;
                column-gap: 9px;
                justify-content: flex-start;
              }
              .mobile-runner-data-v2 {
                .runner-name {
                  font-size: 14px;
                  line-height: 17px;
                }
              }
            }
            .race-wrap {
              max-width: 363px;
              width: 100%;
              display: flex;
              justify-content: space-between;
              border-radius: 8px;
              overflow: hidden;
              @media (max-width: 799px) {
                max-width: none;
              }
              .race-details-left {
                width: 100%;
                background-color: #e4e9ee;
                padding: 8px 5px 7px 18px;
                .race-name {
                  @media (max-width: 799px) {
                    font-size: 14px;
                    line-height: 17px;
                  }
                }
                .race-time {
                  color: $color-Accent-1;
                  .race-closed {
                    color: $color-Negative;
                  }
                  @media (max-width: 799px) {
                    font-size: 14px;
                    line-height: 17px;
                  }
                }
              }
              .arrow-right {
                background-color: $color-Accent-1;
                max-width: 36px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .top-header-details {
            padding-bottom: 6px;
            @media (max-width: 869px) {
              border-bottom: 1px solid $color-Light-grey;
            }
            .header-details {
              .day-time-details {
                font-size: 11.42px;
                line-height: 14px;

                span {
                  font-weight: 400;
                  color: $color-Black;
                }
              }
            }
            .Updated-date {
              .date {
                font-size: 11.42px;
                line-height: 14px;
                color: $color-gray-dark;
              }
              .delete-box {
                width: 20px;
                height: 20px;
                @media (max-width: 799px) {
                  width: 25px;
                  height: 25px;
                }
                svg {
                  width: 11.8px;
                  height: 11.8px;
                  @media (max-width: 799px) {
                    width: 17px;
                    height: 17px;
                  }
                }
              }
            }
          }
          .track-info {
            padding: 6px 0px;

            ul {
              list-style-type: none;
              padding: 0px;
              margin: 0px;
              display: flex;
              flex-wrap: wrap;
              column-gap: 25px;
              align-items: center;
            }

            li {
              display: flex;
              align-items: center;
              column-gap: 8px;
              padding-bottom: 6px;
              font-size: 11.42px;
              line-height: 14px;
              img {
                height: 14px;
              }
            }
          }
          .comment-section {
            .runner-comment {
              .runner {
                font-size: 14px;
                line-height: 17px;
                @media (max-width: 799px) {
                  font-size: 11.42px;
                  line-height: 14px;
                }
              }
            }
            .edit-comment {
              font-size: 14px;
              line-height: 17px;
              min-width: 108px;
              @media (max-width: 799px) {
                font-size: 11.42px;
                line-height: 14px;
              }

              svg {
                width: 18px;
                height: 18px;
                margin-right: 4px;
              }
            }
          }
          .runner-deatils {
            margin-bottom: 0px;
            .runner-info {
              margin: 0px;
              .runner-name {
                color: $color-Black;
                margin-bottom: 0px;
                text-decoration: none;
                cursor: initial;
                @media (max-width: 799px) {
                  font-size: 14px;
                  line-height: 17px;
                  padding-bottom: 6px;
                }
              }
            }
            .track-table {
              .completed-race {
                opacity: 0.2;
              }
            }
          }
          .track-details {
            align-items: flex-start;
            border-top: 1px solid $color-Light-grey;
            @media (max-width: 799px) {
              padding-bottom: 9px;
            }
            .runner-info {
              margin-top: 12px;
              @media (max-width: 799px) {
                margin-top: 0px;
              }
              .runner-info-details {
                font-size: 11.42px;
                line-height: 14px;
                margin-top: 9px;
                @media (max-width: 799px) {
                  margin-top: 0px;
                }
              }
            }
          }
          .black-book-header {
            border-bottom: none;
            @media (max-width: 799px) {
              padding-bottom: 0px;
            }
          }
        }
        .pagination-section .MuiPagination-root .MuiPagination-ul {
          .MuiButtonBase-root {
            @media (max-width: 799px) {
              width: 25px;
              height: 25px;
            }
          }
          @media (max-width: 799px) {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }
  }
}
