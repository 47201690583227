@import "../../../assets/scss/variable.scss";

.section-wrapper {
  display: flex;
  column-gap: 45px;

  .in-comp-wrap {
    // background-color: $color-off-White;
    max-width: 904px;
    width: 100%;

    @media (max-width: 1279px) {
      max-width: 100%;
    }

    .in-comp-header {
      padding: 36px 0px 3px;
      margin-top: -5px;

      @media (max-width: 799px) {
        padding: 18px 0px 4px;
      }

      h1 {
        color: $color-Black;
        line-height: 56px;
        width: auto;

        @media (max-width: 560px) {
          font-size: 31.36px;
          line-height: 30px;
          width: 62%;
        }
      }

      .bredcrumn-deatils {
        overflow-x: scroll;

        .bredcrumn-wrap {
          margin-bottom: 3px;
          min-width: max-content;

          li.MuiBreadcrumbs-separator {
            color: $color-Black;
          }

          li {
            font-size: 11.42px;
            line-height: 14px;

            p {
              font-size: 11.42px;
              line-height: 14px;
              color: $color-Accent-1;
              text-transform: uppercase;
            }

            a {
              color: $color-Black;
              font-size: 11.42px;
              line-height: 14px;
              text-transform: uppercase;
            }
          }
        }
      }

      .bredcrumn-deatils::-webkit-scrollbar {
        height: 0px;
      }

      .bredcrumn-deatils::-webkit-scrollbar-thumb {
        display: none;
      }

      .comp-details-wrap {
        position: relative;
      }

      .about-wrap {
        margin-top: 3px;
        width: 100%;
        word-wrap: break-word;
        padding-bottom: 12px;

        @media (max-width: 799px) {
          width: 100%;
        }

        .about-label {
          font-size: 14px;
          line-height: 17px;
          font-weight: 600;
          color: $color-Black;
          margin-right: 6px;
        }

        .about-des {
          font-size: 14px;
          line-height: 17px;
          font-weight: 400;
          color: $color-Black;

          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-box-orient: vertical;
          // -webkit-line-clamp: 3;
          .about-line {
            margin: 0px;
            display: inline;
          }
        }
      }

      .header-share-wrap {
        display: flex;
        column-gap: 30px;
        align-items: center;

        @media (max-width: 799px) {
          justify-content: space-between;
        }

        .comp-img-wrap {
          width: 80px;
          height: 80px;

          @media (max-width: 799px) {
            position: static;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
            background-color: $color-White;
            border: 1px solid $color-Accent-1;
          }
        }
      }

      .comp-share-btn-wrap {
        .share-btn {
          border: 1px solid $color-Accent-1;
          max-width: 160px;
          width: 100%;
          border-radius: 8px;
          text-transform: capitalize;

          .MuiButton-label {
            font-size: 16px;
            color: $color-Accent-1;
          }
        }

        .share-tooltip-icon {
          @media (max-width: 479px) {
            width: 50%;
          }
        }

        .comp-custom-tooltip {
          position: relative;

          .tooltip {
            left: 0px;
            right: auto;
            top: 44px;
            transform: translateX(0px);
            width: max-content;
            padding: 8px !important;

            .tooltip-arrow {
              right: auto;
              left: 35px;
              top: -16px;
              transform: rotate(180deg);
            }

            .share-option {
              //   display: flex;
              //   justify-content: space-between;
              //   align-items: center;
              //   flex-wrap: wrap;
              position: relative;
              max-width: 551px;
              width: 100%;
              margin: 0 auto;
              column-gap: 20px;

              @media (max-width: 1048px) {
                justify-content: center;
                max-width: 296px;
                row-gap: 11px;
                margin-top: 12px;
              }

              .copy-link {
                cursor: pointer;
              }

              .jf-start {
                justify-content: start !important;
              }

              .social-box {
                display: flex;
                align-items: center;
                column-gap: 10px;

                justify-content: space-between;
              }

              .option-name {
                font-family: $regulerFont;
                font-size: 11.42px;
                line-height: 15px;
                color: #5d5d5d;

                @media (max-width: 560px) {
                  font-size: 8px;
                }
              }

              .react-share__ShareButton {
                width: 32px;
                height: 32px;

                img {
                  height: 100%;
                  width: 100%;
                }
              }

              .social-icon {
                cursor: pointer;
              }
            }

            .social-option-box {
              display: grid;
              grid-template-columns: auto auto auto auto;
              column-gap: 10px;
              row-gap: 10px;

              .option {
                text-align: center;
              }

              .option-name {
                font-family: $regulerFont;
                font-size: 11.42px;
                line-height: 15px;
                color: #5d5d5d;

                @media (max-width: 560px) {
                  font-size: 11.42px;
                }
              }
            }
          }
        }

        .right-sec {
          .share-btn {
            padding: 6px 15px;
            background-color: $color-White;
            color: $color-Accent-1;
            border: 1px solid $color-Accent-1;
            font-size: 16px;
            line-height: 21px;
            font-weight: 400;
            font-family: "Inter", sans-serif;
            border-radius: 8px;
            text-transform: capitalize;
            cursor: pointer;

            @media (max-width: 799px) {
              font-size: 14px;
              line-height: 17px;
              max-width: max-content;
              margin-bottom: 12px;
            }
          }
        }
      }

      .comp-img-wrap {
        position: absolute;
        top: 10px;
        right: 0px;
        width: 125px;
        height: 125px;
        z-index: 11;

        // @media (max-width: 479px) {
        //   top: 35px;
        // }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          background-color: $color-White;
          border: 1px solid $color-Accent-1;
        }
      }
    }

    .tip-share-btn-wrap {
      text-align: center;
      margin-top: 18px;
      margin-bottom: 18px;

      .share-btn {
        border: 1px solid $color-Accent-1;
        max-width: 160px;
        width: 100%;
        border-radius: 8px;
        text-transform: capitalize;

        .MuiButton-label {
          font-size: 16px;
          color: $color-Accent-1;
        }
      }

      .share-tooltip-icon {
        // @media (max-width: 479px) {
        //   width: 50%;
        // }
      }

      .comp-custom-tooltip {
        position: relative;

        .tooltip {
          left: 40%;
          right: auto;
          top: 65px;
          transform: translateX(0px);
          width: max-content;
          padding: 8px !important;

          @media (max-width: 700px) {
            left: -40%;
          }

          .tooltip-arrow {
            right: auto;
            left: 35px;
            top: -16px;
            transform: rotate(180deg);
          }

          .share-option {
            //   display: flex;
            //   justify-content: space-between;
            //   align-items: center;
            //   flex-wrap: wrap;
            position: relative;
            max-width: 551px;
            width: 100%;
            margin: 0 auto;
            column-gap: 20px;

            @media (max-width: 1048px) {
              justify-content: center;
              max-width: 296px;
              row-gap: 11px;
              margin-top: 12px;
            }

            .copy-link {
              cursor: pointer;
            }

            .jf-start {
              justify-content: start !important;
            }

            .social-box {
              display: flex;
              align-items: center;
              column-gap: 10px;

              justify-content: space-between;
            }

            .option-name {
              font-family: $regulerFont;
              font-size: 11.42px;
              line-height: 15px;
              color: #5d5d5d;

              @media (max-width: 560px) {
                font-size: 8px;
              }
            }

            .react-share__ShareButton {
              width: 32px;
              height: 32px;

              img {
                height: 100%;
                width: 100%;
              }
            }

            .social-icon {
              cursor: pointer;
            }
          }

          .social-option-box {
            display: grid;
            grid-template-columns: auto auto auto auto;
            column-gap: 10px;
            row-gap: 10px;

            .option {
              text-align: center;
            }

            .option-name {
              font-family: $regulerFont;
              font-size: 11.42px;
              line-height: 15px;
              color: #5d5d5d;

              @media (max-width: 560px) {
                font-size: 11.42px;
              }
            }
          }
        }
      }

      .right-sec {
        .mt-0 {
          margin-top: 0px !important;
        }

        .share-btn {
          margin-top: 11px;
          padding: 11px 24px 12px 24px;
          background-color: $color-White;
          color: $color-Accent-1;
          border: 1px solid $color-Accent-1;
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          font-family: "Inter", sans-serif;
          border-radius: 8px;
          text-transform: capitalize;
          cursor: pointer;

          @media (max-width: 900px) {
            padding: 11px 14px 12px 14px;
          }

          @media (max-width: 799px) {
            padding: 11px 24px 12px 24px;
          }

          // @media (max-width: 650px) {
          //   font-size: 11.45px;
          //   padding: 12px 12px 12px 12px;
          // }

          // @media (max-width: 479px) {
          //   width: 100%;
          // }
        }
      }
    }

    .tab-search-section {
      padding: 0px 18px;
      background-color: $color-White;
      box-shadow: 0px 3px 9px 0px #0000000d;
      position: relative;

      // margin-bottom: 18px;
      @media (max-width: 799px) {
        margin: 0px -12px;
        padding: 12px;
      }

      .teamsport-tab-detail {
        //   margin-top: 28px;
        border-bottom: 2px solid $color-Accent-1;

        .MuiTabs-scroller {
          .MuiTabs-indicator {
            display: none;
          }

          .active {
            border-bottom: 3px solid $color-Primary;
          }
        }

        @media (max-width: 799px) {
          margin-top: 6px;
        }

        .MuiButtonBase-root.active {
          color: $color-Accent-1;
        }

        .MuiTab-textColorInherit {
          opacity: 1;
        }

        .MuiButtonBase-root {
          font-size: 16px;
          font-family: VeneerClean-Soft !important;
          color: $color-Black;
          line-height: 21px;
        }

        .MuiTab-root {
          min-width: 120px;
        }

        .MuiTabs-indicator {
          height: 3px;
          color: $color-Primary;
        }

        .MuiTouchRipple-root {
          display: none;
        }
      }

      .stepper-score-sec {
        padding-top: 15px;
        padding-bottom: 18px;

        .stepper-input {
          // margin-top: 15px;
          display: flex;
          //   border: 1px solid #c9c9c9;
          border-radius: 8px;
          max-height: 44px;
          column-gap: 3px;
          width: 77.6%;

          @media (max-width: 799px) {
            width: 100%;
          }

          .stepper-input__button {
            padding: 10px 30px;
            min-width: 35px;
            cursor: pointer;
            color: $color-Black;
            border-left: 0px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            text-transform: capitalize;
            font-size: 16px;
            line-height: 22.4px;
            font-weight: 400;
            background-color: #e8eaec;
            //   background-color: #00000042;

            @media (max-width: 540px) {
              font-size: 12px;
            }
          }

          .Next-btn {
            border-top-right-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
          }

          .stepper-input__content {
            border: 1px solid #c9c9c9;
            font-family: $regulerFont;
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            padding: 8px 26px;

            color: $color-Black;
            width: 100%;
            text-align: center;
          }

          .tipping-select {
            width: 100%;

            .select__control {
              border-radius: 0px;
              max-height: 44px;
              max-width: 100%;
              border-color: hsl(0, 0%, 80%);
              box-shadow: none;

              .select__single-value {
                font-family: $regulerFont !important;
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 600;
                color: $color-Black;
                padding: 0px 8px;
                text-align: center;
              }

              .select__placeholder {
                font-family: $regulerFont !important;
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 600;
                color: $color-Black;
                padding: 0px 8px;
              }

              .select__input-container {
                font-family: $regulerFont !important;
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 600;
                color: $color-Black;
                padding: 0px 8px;
              }

              .select__indicator-separator {
                width: 0px;
              }

              .select__dropdown-indicator {
                padding: 10px;
              }
            }

            .select__menu {
              margin: 0px;
              border-radius: 0px;
              padding: 0px;
              z-index: 999;
            }

            .select__menu-list {
              padding: 0px;
            }

            // Select Menu
            .select__menu,
            .react-select__menu {


              .select__menu-list,
              .react-select__menu-list {

                .select__option,
                .react-select__option {
                  cursor: pointer;
                  font-size: 16px;
                  color: $color-Black;
                  font-family: $regulerFont;
                  line-height: 19px;
                  padding: 11px 40px 11px 11px;
                  text-align: center;

                  &.select__option--is-focused {
                    background-color: #d4d6d8;
                    color: #000;
                  }

                  &.select__option--is-selected {
                    background-color: #d4d6d8;
                    color: $color-Black;
                  }
                }
              }

              .select__menu-list,
              .react-select__menu-list {
                .select__group {
                  .select__group-heading {
                    margin-bottom: 0.5rem;
                    color: green;
                    font-weight: bolder;
                    font-size: inherit;
                  }
                }
              }
            }

            .select__menu-portal {
              z-index: 99;
            }
          }

          @media only screen and (max-width: 479px) {
            grid-column-start: 1;
            grid-column-end: 4;
          }
        }

        .score-share-sec {
          display: flex;
          justify-content: space-between;
          align-items: end;
          width: 100%;
          margin-top: 16px;

          @media (max-width: 620px) {
            display: block;
          }

          .score-sec {
            width: 100%;

            .round-score-sec {
              display: flex;
              align-items: center;
              column-gap: 18px;

              @media (max-width: 799px) {
                flex-direction: column;
                row-gap: 4px;
              }

              .round-wrap {
                display: flex;
                width: calc(100% - 318px);
                column-gap: 8px;

                @media (max-width: 799px) {
                  width: 100%;
                  justify-content: space-between;
                }

                .round-score {
                  column-gap: 7px;
                  max-width: 269px;
                  width: 100%;
                  padding: 9px 18px;
                  border: 1px solid $color-Light-grey;
                  border-radius: 8px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  @media (max-width: 799px) {
                    padding: 9px;
                    column-gap: 8px;
                    max-width: 100%;
                  }

                  .score {
                    font-weight: 400;
                    font-size: 16px;
                    color: $color-Black;
                    line-height: 21px;
                    font-family: $regulerFont;
                    text-align: center;
                  }

                  .semi-bold {
                    font-weight: 600;
                  }
                }
              }

              .tips-btn-wrap {
                width: 318px;
                display: flex;
                justify-content: space-between;

                @media (max-width: 799px) {
                  width: 100%;
                  justify-content: space-between;
                  column-gap: 8px;
                }

                .tips-btn {
                  padding: 9px 35px;
                  color: $color-White;
                  background-color: $color-Accent-1;
                  text-transform: none;
                  border-radius: 8px;
                  line-height: 20px;
                  font-weight: 400;

                  @media (max-width: 799px) {
                    width: 100%;
                  }
                }
              }
            }

            .tips-extra-info-wrap {
              padding-top: 9px;
              display: flex;
              justify-content: space-between;

              @media (max-width: 799px) {
                flex-direction: column;
                row-gap: 9px;
              }

              .extra-info-left {
                display: flex;
                align-items: center;
                column-gap: 4px;

                @media (max-width: 479px) {
                  align-items: flex-start;
                }

                .subs-badge {
                  height: 30px;
                  width: 30px;
                }

                .tips-type-round {
                  display: flex;
                  align-items: center;
                  column-gap: 6px;
                  font-size: 16px;
                  line-height: 20px;
                  font-family: $regulerFont;

                  @media (max-width: 799px) {
                    font-size: 12px;
                    line-height: 15px;
                  }

                  .info-icon {
                    height: 18px;
                    width: 18px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                  }
                }

                .bottom-line-sec {
                  display: flex;
                  align-items: center;
                  column-gap: 10px;

                  @media (max-width: 930px) {
                    display: block;
                  }

                  .final-txt {
                    margin-top: 6px;
                    display: flex;
                    align-items: center;
                    column-gap: 5px;

                    font-weight: 400;
                    font-size: 14px;
                    color: $color-gray-dark;
                    line-height: 17px;
                    font-family: $regulerFont;

                    span {
                      color: $color-Accent-1;
                    }

                    @media (max-width: 580px) {
                      display: block;
                    }

                    @media (max-width: 799px) {
                      font-size: 12px;
                      line-height: 15px;
                    }

                    // .comp-header-details {
                    // .day-time-details {
                    //   font-weight: 400;
                    //   font-family: $regulerFont;
                    //   color: $color-Black;
                    //   font-size: 16px;
                    //   line-height: 21px;

                    //     //   @media (max-width: 900px) {
                    //     //     font-size: 14px;
                    //     //   }
                    //     //   @media (max-width: 799px) {
                    //     //     font-size: 16px;
                    //     //   }

                    //     //   @media (max-width: 650px) {
                    //     //     font-size: 15px;
                    //     //   }
                    //   }
                    // }
                  }

                  .joker-switch-box {
                    margin-top: 8px;

                    @media (max-width: 930px) {
                      margin-top: 10px;
                    }

                    // margin-right: 30px;

                    .MuiFormControlLabel-root {
                      max-width: 132px;

                      @media (max-width: 930px) {
                        margin-left: 0px;
                      }

                      .MuiSwitch-root {
                        padding: 0px;
                        width: 39px;
                        height: 20px;
                        border-radius: 10px;
                        margin-left: 9px;

                        .MuiButtonBase-root {
                          padding: 0px;
                        }

                        .Mui-checked {
                          color: $color-Accent-1;
                        }

                        .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
                          background-color: #d6d9f3;
                        }
                      }

                      .MuiTypography-root {
                        font-size: 16px;
                        line-height: 19px;
                        font-weight: 600;
                        font-family: $regulerFont;
                      }
                    }
                  }
                }
              }

              .tips-switch {
                .switch-box {
                  display: flex;

                  .MuiFormControlLabel-root {
                    margin-left: 0px;
                    margin-right: 0px;
                    max-width: max-content;

                    .MuiSwitch-root {
                      padding: 0px;
                      width: 39px;
                      height: 20px;
                      border-radius: 10px;
                      margin-right: 9px;

                      .MuiButtonBase-root {
                        padding: 0px;
                      }

                      .Mui-checked {
                        color: $color-Accent-1;
                      }

                      .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
                        background-color: $color-Lavender;
                      }

                      .MuiSwitch-track {
                        background-color: $color-grey;
                        opacity: 0.8;
                      }
                    }

                    .MuiTypography-root {
                      font-size: 16px;
                      line-height: 19px;
                      font-weight: 600;
                      font-family: $regulerFont;
                      width: max-content;
                    }
                  }

                  .switch-label {
                    font-size: 14px;
                    line-height: 17px;
                    color: $color-Black;
                    max-width: 300px;

                    @media (max-width: 799px) {
                      font-size: 12px;
                      line-height: 15px;
                      max-width: none;
                    }

                    .tournament-name {
                      color: $color-Primary;
                      font-weight: 500;
                    }
                  }
                }
              }

              .print-btn-box {
                margin-bottom: 4px;

                .print-ladder {
                  display: flex;
                  align-items: center;
                  column-gap: 5px;
                  cursor: pointer;

                  .print-text {
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 400;
                    text-decoration: underline;
                    color: $color-Accent-1;

                    @media (max-width: 799px) {
                      font-size: 12px;
                      line-height: 15px;
                    }
                  }

                  .print-icon {
                    svg {
                      path {
                        stroke: $color-Accent-1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .comp-img-share-wrap {
        position: absolute;
        top: -17px;
        right: 15px;

        .comp-img-wrap {
          width: 129px;
          height: 129px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
            background-color: $color-White;
            border: 1px solid $color-Accent-1;
          }
        }

        .comp-share-btn-wrap {
          text-align: center;
          margin-top: 13px;

          .share-btn {
            border: 1px solid $color-Accent-1;
            max-width: 113px;
            width: 100%;
            border-radius: 8px;
            text-transform: capitalize;

            .MuiButton-label {
              font-size: 16px;
              color: $color-Accent-1;
            }
          }

          .share-tooltip-icon {
            @media (max-width: 479px) {
              width: 50%;
            }
          }

          .comp-custom-tooltip {
            position: relative;

            .tooltip {
              top: 55px;
              left: -15px;
              width: max-content;
              padding: 12px !important;

              @media (max-width: 1023px) {
                left: -48px;
              }

              // @media (max-width: 799px) {
              //   left: 34px;

              //   padding: 8px !important;
              // }

              // @media (max-width: 560px) {
              //   left: 42px;
              // }

              .tooltip-arrow {
                top: -20px;
                right: 30px;
                left: auto;
                transform: rotate(180deg);

                @media (max-width: 799px) {
                  right: -19px;
                }
              }

              .share-option {
                position: relative;
                max-width: 551px;
                width: 100%;
                margin: 0 auto;
                column-gap: 20px;

                @media (max-width: 1048px) {
                  justify-content: center;
                  max-width: 296px;
                  row-gap: 11px;
                  margin-top: 12px;
                }

                .copy-link {
                  cursor: pointer;
                }

                .jf-start {
                  justify-content: start !important;
                }

                .social-box {
                  display: flex;
                  align-items: center;
                  column-gap: 10px;

                  justify-content: space-between;
                }

                .option-name {
                  font-family: $regulerFont;
                  font-size: 11.42px;
                  line-height: 15px;
                  color: #5d5d5d;

                  @media (max-width: 560px) {
                    font-size: 8px;
                  }
                }

                .react-share__ShareButton {
                  width: 32px;
                  height: 32px;

                  img {
                    height: 100%;
                    width: 100%;
                  }
                }

                .social-icon {
                  cursor: pointer;
                }
              }

              .social-option-box {
                display: grid;
                grid-template-columns: auto auto auto auto;
                column-gap: 10px;
                row-gap: 10px;

                .option {
                  text-align: center;
                }

                .option-name {
                  font-family: $regulerFont;
                  font-size: 11.42px;
                  line-height: 15px;
                  color: #5d5d5d;

                  @media (max-width: 560px) {
                    font-size: 11.42px;
                  }
                }
              }
            }
          }

          .right-sec {
            .share-btn {
              padding: 11px 24px 12px 24px;
              background-color: $color-White;
              color: $color-Accent-1;
              border: 1px solid $color-Accent-1;
              font-size: 16px;
              line-height: 21px;
              font-weight: 400;
              font-family: "Inter", sans-serif;
              border-radius: 8px;
              text-transform: capitalize;
              cursor: pointer;

              @media (max-width: 900px) {
                padding: 11px 14px 12px 14px;
              }

              @media (max-width: 799px) {
                padding: 11px 24px 12px 24px;
              }

              @media (max-width: 650px) {
                font-size: 11.45px;
                padding: 12px 12px 12px 12px;
              }
            }
          }
        }
      }
    }

    .padding-container {
      padding: 18px 0px !important;
      background-color: transparent !important;
      box-shadow: unset !important;

      @media (max-width: 1365px) {
        padding: 12px 0px !important;
      }

      @media (max-width: 1279px) {
        padding: 0px !important;
      }
    }

    .page-deatils-wrap {
      margin-top: 18px;
      // padding: 18px 33px 84px 33px;
      // padding-bottom: 9px;
      background-color: $color-White;
      box-shadow: 0px 3px 9px 0px #0000000d;

      @media (max-width: 799px) {
        // padding: 16px 13px 16px 13px;
      }

      .ladder-wrapper {
        padding: 0px 18px;

        @media (max-width: 799px) {
          padding: 0px 12px;
        }
      }

      .print-btn-box {
        padding: 5px 18px;
        text-align: end;

        .print-btn {
          max-width: 100%;
          width: 171px;
          margin-bottom: 12.6px;
          padding: 8px 24px 12px 24px;
          background-color: $color-Accent-1;
          color: $color-White;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          font-family: $regulerFont;
          border-radius: 8px;
          text-transform: capitalize;
          cursor: pointer;

          @media (max-width: 900px) {
            padding: 11px 14px 12px 14px;
          }

          @media (max-width: 799px) {
            font-size: 14px;
            padding: 11px 24px 12px 24px;
          }

          @media (max-width: 650px) {
            width: 180px;
            padding: 7px 12px 10px;
          }

          @media (max-width: 400px) {
            font-size: 11.42px;
            line-height: 14px;
            width: 148px;
          }

          .btn-txt {
            margin-top: 6px;
          }
        }
      }

      .new-ladder-sec {
        // overflow: scroll;
        border-radius: 8px 8px 0px 0px;
        box-shadow: 0px 1px 2px 0px #00000029;
        overflow-x: auto;
        position: relative;

        .new-ladder-table {
          .primary-font-color {
            color: $color-White !important;

            @media (max-width: 799px) {
              font-size: 11.42px !important;
              line-height: 14px !important;
            }
          }

          .highlight-border {
            border-bottom: 1px solid $color-Accent-1;
            color: $color-Accent-1 !important;
            font-weight: 600 !important;
          }

          .table-cell-center {
            text-align: center;
          }

          .MuiTableRow-head {
            .MuiTableCell-head {
              padding: 13px 0px 12px 12px;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0px;
              font-weight: 600;
              color: $color-White;
              background-color: $color-Primary;

              @media (max-width: 799px) {
                font-size: 15px;
                line-height: 18px;
                padding: 13px 6px 12px 6px;
              }
            }
          }

          .MuiTableBody-root {

            // .rank-name {
            //   padding-left: 8px;
            // }
            .MuiTableCell-body {
              padding: 6px 0px 5px 15px;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0px;
              font-weight: 400;
              color: $color-Black;

              @media (max-width: 799px) {
                font-size: 15px;
                line-height: 18px;
                padding: 6px 6px 5px 6px;
              }
            }

            .tipper-img-wrap {
              display: flex;
              align-items: center;
              column-gap: 9px;

              @media (max-width: 479px) {
                span {
                  width: 100px;
                  display: block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .profile-icon-wrap {
                max-width: 36px;
                min-width: 36px;
                height: 36px;
                border-radius: 50%;

                .profile-icon {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  border-radius: 50%;
                }
              }

              .icon-border {
                border: 1px solid $color-Accent-1;
              }
            }
          }

          .sorting-cell {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 5px;
            cursor: pointer;

            .sorting-arrow-wrap {
              display: flex;
              flex-direction: column;
              row-gap: 2px;
              cursor: pointer;

              .active {
                path {
                  fill: $color-Accent-1;
                }
              }

              .down-arrow {
                transform: rotate(180deg);
              }
            }
          }

          .sticky-cell-table {
            .MuiTableRow-head {
              .MuiTableCell-head {
                padding: 0px;
                border: none;
              }

              .sticky-cell-table-pos {
                width: 90px;
                text-align: center;

                @media (max-width: 799px) {
                  width: 49px;
                }
              }
            }

            .MuiTableRow-root {
              .MuiTableCell-body {
                border: none;
                padding: 0px;
              }

              .sticky-cell-table-pos {
                width: 90px;
                text-align: center;

                @media (max-width: 799px) {
                  width: 49px;
                }
              }
            }
          }

          .current-user-stickycell {
            border-bottom: 1px solid $color-Accent-1;
          }
        }
      }

      .ladder-sec {
        // padding: 12px;
        overflow: scroll;

        .table-content {
          // border: 1px solid #d4d6d8;

          .txt-center {
            text-align: center !important;
          }

          .table-title {
            padding: 15px 5px 5px 27px;
            // border-right: 1px solid #d4d6d8;
            color: $color-Black;
            font-size: 16px;
            line-height: 21px;
            font-weight: 600;
            font-family: $regulerFont;
          }

          .detail-flex {
            display: flex !important;
            align-items: center !important;
            column-gap: 9px;
          }

          .table-detail {
            // border-right: 1px solid #d4d6d8;
            padding: 15px 5px 5px 27px;
            color: $color-Black;
            font-size: 16px;
            line-height: 21px;
            font-weight: 400;
            font-family: $regulerFont;
          }

          // .txt-width {
          //   max-width: 200px;
          //   width: 100%;
          //   overflow: hidden;
          // }

          .tablecell-highlight {
            font-weight: 600;
            border-bottom: 1px solid $color-Accent-1;
            // height: 36.5px;
            color: $color-Accent-1;
          }

          .team-img-wrap {
            width: 36px;
            height: 36px;
            border-radius: 50%;

            .team-img {
              border-radius: 50%;
              width: 100%;
              object-fit: contain;
            }

            // @media (max-width: 939px) {
            //   margin: 0px auto;
            // }
          }
        }

        .learn-more-box {
          padding: 11px 33px 0px 5px;

          .learn-more {
            text-align: end;
            text-transform: capitalize;
            color: $color-Accent-1;
            border: none;
            padding: 0;
            cursor: pointer;
            outline: none;
            text-decoration: underline;
            font-size: 14px;

            &:hover {
              background-color: transparent;
            }
          }
        }

        .accordion-header {
          display: flex;
          align-items: center;
          column-gap: 33px;
          padding: 16px 16px 5px 16px;

          .header {
            color: $color-Black;
            font-size: 15px;
            line-height: 20px;
            font-weight: 600;
            font-family: $regulerFont;
          }
        }

        .left-section-details {
          .user-ranking {
            border-bottom: 1px solid $color-Accent-1;
            border-radius: 0px;

            .user-info {
              font-weight: 600;
              color: $color-Accent-1;
            }
          }
        }

        .left-section-details {
          .user-ranking {
            border-bottom: 1px solid $color-Accent-1;
            border-radius: 0px;

            .user-info {
              font-weight: 600;
            }
          }
        }

        .accordion-details {
          width: 100%;
          box-shadow: 1px 1px 5px 5px #0000000d;

          .border-bottom {
            border-bottom: 1px solid #bdbdbf !important;
          }

          .score-sec {
            padding: 14px 72px 9px 31px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .score {
              color: $color-Primary;
              font-size: 16px;
              line-height: 16px;
              font-weight: 500;
              font-family: $regulerFont;
              text-align: center;
            }
          }

          .black-txt {
            color: $color-Black !important;
            font-weight: 400 !important;
          }
        }
      }

      .tips-sec {
        margin-top: 15px;
        padding: 18px 33px;
        background-color: $color-White;
        border-radius: 8px;
        box-shadow: 0px 3px 9px 0px #0000000d;

        @media (max-width: 1365px) {
          padding: 17px 15px 18px 15px;
        }

        &:first-child {
          margin-top: 0px;
        }

        .title-date-sec {
          text-align: center;

          .title-date {
            color: $color-Black;
            font-size: 16px;
            line-height: 21px;
            font-weight: 600;
            font-family: $regulerFont;
          }
        }

        .comp-tip-data-sec {
          border-top: 1px solid $color-Primary;
          padding-top: 3px;

          .time-venue-txt {
            text-align: center;
          }

          .comp-odds-tips-sec {
            padding: 18px 26px 21px 26px;
            // width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 1365px) {
              column-gap: 2px;
              padding: 18px 0px 2px 0px;
            }

            // &:last-child {
            //   padding-bottom: 0px !important;
            // }
            .flex-rr {
              flex-direction: row-reverse !important;
            }

            .left-sec {
              display: flex;
              align-items: center;
              column-gap: 30px;

              @media (max-width: 1365px) {
                display: block;
                width: 90%;
              }

              .team-wrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 5px;
                width: 120px;

                @media (max-width: 1365px) {
                  width: initial;
                  padding-bottom: 5px;
                }

                .team-img-wrap {
                  width: 65px;
                  height: 65px;

                  border-radius: 50%;

                  .team-img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: contain;
                  }
                }

                .tipper-name {
                  @media (max-width: 1250px) {
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: calc(100% - 36px) !important;
                  }

                  // @media (max-width: 560px) {
                  //   width: 100px !important;
                  // }
                }
              }

              .odd-rr {
                @media (max-width: 1365px) {
                  flex-direction: row-reverse !important;
                }
              }

              .odds-wrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 5px;

                @media (max-width: 1365px) {
                  flex-direction: row;
                  justify-content: center;
                  column-gap: 8px;
                  margin-top: 10px;
                }

                .odds-btn {
                  display: block;
                  background: #d6d9f3;
                  border-radius: 6px;
                  width: 80px;
                  max-width: 100%;
                  text-align: center;
                  padding: 8px;

                  @media (max-width: 560px) {
                    width: 45px;
                  }
                }

                .odd-img {
                  max-width: 33px;
                  border-radius: 6px;
                  cursor: pointer;
                  height: 33px;
                  object-fit: contain;
                }

                .odds-icon-anchor {
                  display: flex;
                  align-items: center;
                }
              }

              .blue-score {
                background-color: $color-Primary;
                width: 63px;
                height: 58px;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: 1365px) {
                  width: 100%;
                  margin-top: 12.1px;
                  height: 36px;
                }

                .score {
                  font-family: $regulerFont;
                  font-size: 16px;
                  line-height: 21px;
                  font-weight: 400;
                  color: $color-White;
                  text-align: center;
                }
              }

              .color-transparent {
                background-color: transparent;
              }

              .check-box-sec {
                text-align: center;

                @media (max-width: 1365px) {
                  margin-top: 14px;
                }

                .MuiCheckbox-root {
                  height: 58px;
                  width: 58px;

                  @media (max-width: 1365px) {
                    height: 34px;
                    width: 34px;
                  }
                }
              }

              .comp-name-tip-wrap {
                display: flex;
                align-items: center;
                height: 40px;

                .team-name {
                  width: 120px;
                  text-align: center;
                  font-family: $primaryFont;
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 400;
                  color: $color-Black;

                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;

                  // @media (max-width: 1110px) {
                  //   width: max-content;
                  // }

                  @media only screen and (max-width: 599px) {
                    min-width: 100px;
                    width: fit-content;
                  }
                }

                .comp-smartbook-tooltip {
                  width: 185px;
                  transform: translateX(-118%);
                  left: 122px;
                  top: 36px;

                  @media only screen and (max-width: 599px) {
                    left: 110px;
                  }

                  .comp-smartbook-tooltip-arrow {
                    left: initial;
                    right: 50px;
                    transform: rotate(540deg);
                    top: -9px;

                    @media only screen and (max-width: 599px) {
                      right: 37px;
                    }
                  }
                }
              }
            }

            .vs {
              text-align: center;
              font-family: $primaryFont;
              font-size: 16px;
              line-height: 20px;
              font-weight: 400;
              color: $color-Black;

              @media (max-width: 1365px) {
                display: none;
              }
            }

            // .right-sec {}
          }

          .no-result {
            justify-content: space-around;
          }

          .indefinite-result {
            background-color: $color-Light-grey;
            border-radius: 8px;
          }

          .margin-sec {
            text-align: center;

            .margin-txt {
              color: $color-Black;
              font-size: 16px;
              line-height: 21px;
              font-weight: 500;
              font-family: $regulerFont;
            }
          }

          .counter-score-sec {
            padding-top: 15px;
            padding-bottom: 18px;
            max-width: 183px;
            width: 100%;
            margin: 0 auto;

            .counter-input {
              // margin-top: 15px;
              display: flex;
              //   border: 1px solid #c9c9c9;
              border-radius: 8px;
              max-height: 36px;

              // column-gap: 3px;
              border: 1px solid #c9c9c9;
              overflow: hidden;

              .counter-input__button {
                padding: 10px 10px;
                cursor: pointer;
                min-width: 36px;
                color: $color-Black;
                border-left: 0px;
                border-radius: 0px;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                text-transform: capitalize;
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 400;
                // background-color: #e8eaec;
                //   background-color: #00000042;

                .MuiButton-root {
                  min-width: 36px !important;
                }

                @media (max-width: 540px) {
                  font-size: 12px;
                }
              }

              .plus-btn {
                border-top-right-radius: 8px !important;
                border-bottom-right-radius: 8px !important;
                border-top-left-radius: 0px !important;
                border-bottom-left-radius: 0px !important;
              }

              .counter-input__content {
                border-left: 1px solid #c9c9c9;
                border-right: 1px solid #c9c9c9;
                font-family: $regulerFont;
                font-size: 16px;
                line-height: 20px;
                font-weight: 600;
                padding: 8px 26px;

                color: $color-Black;
                width: 100%;
                text-align: center;
              }

              .margin-textfield {
                .MuiInputBase-root {
                  height: 36px;

                  input {
                    padding: 9px 10px;
                    text-align: center;
                    border-left: 1px solid #c9c9c9;
                    border-right: 1px solid#c9c9c9;
                  }

                  fieldset {
                    border: none;
                  }
                }
              }

              @media only screen and (max-width: 479px) {
                grid-column-start: 1;
                grid-column-end: 4;
              }
            }
          }
        }
      }

      .btn-flex {
        margin-top: 20px;
        display: flex;
        align-items: center;
        column-gap: 33px;
        justify-content: center;
        // max-width: 904px;

        // @media (max-width:1279px) {
        //   max-width: 100%;
        // }

        @media (max-width: 799px) {
          margin-top: 20px;
          column-gap: 12px;
        }
      }

      .share-btn {
        border: 1px solid $color-Accent-1;
        width: 145px;
        border-radius: 8px;

        // max-width: 164px;
        padding: 11px 13px;
        font-size: 16px;
        color: $color-Accent-1;
        line-height: 20px;
        font-family: $regulerFont;

        .MuiButton-label {
          text-transform: initial;
        }

        @media (max-width: 799px) {
          width: 100%;
        }

        // @media (max-width: 599px) {
        //   max-width: unset;
        //   width: 100%;
        // }
      }

      .submit-tips-btn-sec {
        // margin-top: 31px;
        // width: 100%;
        text-align: center;

        @media (max-width: 799px) {
          width: 100%;
        }

        .submit-tips-btn {
          // max-width: 164px;
          // width: 100%;
          width: 145px;
          padding: 12px 13px;
          border-radius: 8px;
          font-size: 16px;
          color: $color-White;
          line-height: 20px;
          font-family: $regulerFont;
          background-color: $color-Accent-1;

          .MuiButton-label {
            text-transform: initial;
          }

          @media (max-width: 799px) {
            max-width: unset;
            width: 100%;
          }
        }
      }

      .joker-box {
        // max-width: 904px;
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        box-shadow: 0px 1px 9px 0px #00000030;
        overflow: hidden;

        // @media (max-width:1279px) {
        //   max-width: 100%;
        // }

        .top-sec {
          text-align: center;
          background-color: #e8eaec;
          padding: 12px 0px 11px;
          border-radius: 8px 8px 0px 0px;
          overflow: hidden;

          .joker-txt {
            color: $color-Black;
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            letter-spacing: 0px;
            font-family: $regulerFont;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 7px;
          }
        }

        .bottom-sec {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          padding: 21px 0px 24px 0px;
          max-width: 494px;
          width: 100%;
          column-gap: 13px;
          margin: 0px auto;

          @media (max-width: 999px) {
            max-width: 283px;
            padding: 13px 0px 12px 0px;
          }

          .bottom-check-box {
            .MuiButtonBase-root {
              padding: 0px;

              .MuiIconButton-label {
                width: 40px;
                height: 40px;

                @media (max-width: 999px) {
                  width: 28px;
                  height: 28px;
                }

                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .select-txt {
            color: $color-Primary;
            font-size: 16px;
            line-height: 19px;
            font-weight: 500;
            font-family: $regulerFont;

            @media (max-width: 999px) {
              font-size: 14px;
              line-height: 16px;
            }
          }

          .detail-txt {
            margin-top: 7px;
            color: $color-gray-dark;
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;

            @media (max-width: 999px) {
              font-size: 11.42px;
              line-height: 14px;
            }
          }
        }
      }

      .margin-error {
        margin-top: 5px;
        text-align: center;
        // max-width: 904px;

        // @media (max-width:1279px) {
        //   max-width: 100%;
        // }

        .error {
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
          font-family: $regulerFont;
          color: $color-Negative;
        }
      }

      .clear-all-box {
        margin-top: 9px;
        // max-width: 904px;

        // @media (max-width:1279px) {
        //   max-width: 100%;
        // }

        .clear-all {
          text-align: center;
          text-transform: capitalize;
          color: $color-Accent-1;
          border: none;
          padding: 0;
          cursor: pointer;
          outline: none;
          text-decoration: underline;
          font-size: 14px;

          &:hover {
            background-color: transparent;
          }
        }
      }

      .paid-btn {
        padding: 10px 9px 9px;
        border-radius: 8px;
        background-color: $color-Affirmative;
        color: $color-White;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        letter-spacing: 0px;
        font-family: $regulerFont;
        letter-spacing: 0px;
        text-transform: capitalize;
        margin-top: 12px;

        @media (max-width: 799px) {
          font-size: 14px;
          line-height: 16px;
        }
      }

      .comp-details-sec {
        width: 100%;
        margin-top: 30px;

        @media (max-width: 1120px) {
          margin-top: 57px;
        }

        @media (max-width: 799px) {
          width: 100%;
          margin-top: 0px;
        }

        .details-sec-header {
          // margin: 0px 220px 0px 50px;

          // @media (max-width: 1250px) {
          //   margin: 0px 50px 0px 50px;
          // }
          // @media (max-width: 630px) {
          //   margin: 33px 12px 0px 12px;
          // }

          .details-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $color-Light-grey;
            padding: 9px 43px 9px 33px;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;

            @media (max-width: 640px) {
              padding: 9px;
              display: block;
            }

            @media (max-width: 460px) {}

            .joined-txt {
              // margin-top: 4px;
              color: $color-Black;
              font-size: 22.4px;
              line-height: 31.36px;
              font-weight: 400;
              font-family: $primaryFont;

              @media (max-width: 440px) {
                font-size: 16px;
              }
            }

            .leave-btn {
              padding: 0px;
              color: $color-Negative;
              font-size: 16px;
              line-height: 20px;
              font-weight: 600;
              font-family: $regulerFont;
              text-transform: none;
              text-decoration: underline;

              .MuiButton-label {
                .MuiButton-endIcon {
                  transform: rotate(90deg);
                }
              }

              @media (max-width: 440px) {
                font-size: 11.42px;
                line-height: 14px;
              }

              // .right-arrow {

              // }
            }
          }

          .details-sec {
            // margin: 0px 50px 0px 50px;
            padding: 18px 0px 16px;
            box-shadow: 10px 10px 5px 0px #0000000f;
            -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
            -moz-box-shadow: 10px 10px 5px 0px #0000000f;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;

            // @media (max-width: 640px) {
            //   padding: 9px;
            // }

            .details {
              display: flex;
              justify-content: space-between;
              align-items: center;
              // margin-top: 17px;
              padding: 9px 33px;

              @media (max-width: 640px) {
                padding: 9px;
              }

              &:first-child {
                margin-top: 0px;
              }

              .select__control {
                box-shadow: none;

                padding: 5px 0px;
                margin: 0px;
                border-radius: 8px;

                @media (max-width: 490px) {
                  margin-top: 5px;
                }
              }

              .select__control,
              .react-select__control {
                // &.select__control--is-disabled {

                //   // border-color: $border-color;
                //   .select__indicator-separator {
                //     //   background-color: $border-color;
                //   }
                // }

                // &.select__control--is-focused,
                // &.react-select__control--is-focused {

                // }

                &.select__control--menu-is-open {
                  box-shadow: none;
                  // border: none;
                }

                .select__indicator svg {
                  cursor: pointer;
                }

                .select__indicator-separator {
                  display: none;
                }

                .select__single-value {
                  // border: none;
                  font-size: 16px;
                  line-height: 20px;
                  padding-left: 10px;
                  outline: none;
                  cursor: pointer;
                  margin: 0px;
                  font-family: "Inter", sans-serif;
                }

                .select__value-container {
                  // border: none;
                  padding: 0px;
                  margin: 0px;

                  .select__input-container {
                    font-size: 16px;
                    font-family: "Inter", sans-serif;
                    margin-left: 10px;
                  }
                }

                .select__placeholder {
                  font-size: 16px;
                  line-height: 20px;
                  padding-left: 10px;
                  cursor: pointer;
                  font-family: $regulerFont;
                }
              }

              .select__menu {
                margin: 0px;
                border-radius: 0px;
                padding: 0px;
                z-index: 999;
              }

              .select__menu-list {
                padding: 0px;
              }

              // Select Menu
              .select__menu,
              .react-select__menu {

                .select__menu-list,
                .react-select__menu-list {

                  .select__option,
                  .react-select__option {
                    cursor: pointer;
                    font-size: 16px;
                    color: $color-Black;
                    font-family: $regulerFont;
                    line-height: 19px;
                    padding: 11px;

                    &.select__option--is-focused {
                      background-color: $color-grey;
                      color: #000;
                    }

                    &.select__option--is-selected {
                      background-color: $color-grey;
                      color: $color-Black;
                    }
                  }
                }

                .select__menu-list,
                .react-select__menu-list {
                  .select__group {
                    .select__group-heading {
                      margin-bottom: 0.5rem;
                      color: green;
                      font-weight: bolder;
                      font-size: inherit;
                    }
                  }
                }
              }
            }

            .comp-field-container {
              // margin-top: 21px;
              max-width: 100%;
              // max-width: 835px;
              width: 100%;

              .comp-image-name-wrap {
                display: flex;
                align-items: center;
                column-gap: 9px;
                // margin-bottom: 50px;
                cursor: pointer;
                padding: 9px 33px;

                @media (max-width: 640px) {
                  padding: 9px;
                }

                .comp-image-wrap {
                  height: 70px;
                  width: 70px;
                  border-radius: 50%;
                  position: relative;

                  .comp-image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 50%;
                  }

                  .add-button {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                  }
                }

                .comp-image-text-wrap {
                  margin-top: 28px;

                  .image-text {
                    font-size: 16px;
                    line-height: 20px;
                    color: $color-Accent-1;
                    font-weight: 600;
                    text-decoration: underline;
                  }
                }
              }

              .danger {
                color: $color-Negative;
              }

              .jc-start {
                justify-content: start !important;
              }

              // .mt-17 {
              //   margin-top: 17px;
              // }

              .comp-details {
                display: flex;
                align-items: center;
                justify-content: space-between;
                // margin-bottom: 18px;
                padding: 9px 33px;

                @media (max-width: 640px) {
                  padding: 9px;
                }

                @media (max-width: 490px) {
                  display: block;
                }

                .textfield-text {
                  // font-size: 16px;
                  // font-weight: 400;
                  // font-family: $regulerFont;
                  max-width: 221px;
                  width: 100%;
                }

                .field-container {
                  max-width: 490px;
                  width: 100%;
                  // .sort-select {
                  //   // max-width: 282px;
                  //   // max-width: 100%;
                  //   // width: 835px;
                  //   // width: 748px;

                  //   // @media only screen and (max-width: 1023px) {
                  //   //   margin-right: 0px;
                  //   //   max-width: none;
                  //   // }

                  //   // @media (max-width: 799px) {
                  //   //   max-width: none;
                  //   // }

                  //   // @media only screen and (max-width: 479px) {
                  //   //   grid-column-start: 1;
                  //   //   grid-column-end: 4;
                  //   // }
                  // }

                  .comp-textfield {
                    max-width: 614px;
                    width: 100%;
                    background-color: $color-White;
                    border-radius: 8px;

                    @media (max-width: 490px) {
                      //   max-width: none;
                      margin-top: 5px;
                    }

                    .MuiOutlinedInput-root {
                      border-radius: 8px;
                      font-family: "Inter", sans-serif;

                      .MuiOutlinedInput-input {
                        padding: 13px 11px;
                      }
                    }

                    .Mui-focused {
                      .MuiOutlinedInput-notchedOutline {
                        border-color: $color-Accent-1;
                        border-width: 1;
                      }
                    }

                    svg {
                      cursor: pointer;
                    }

                    input[type="number"]::-webkit-inner-spin-button,
                    input[type="number"]::-webkit-outer-spin-button {
                      display: none;
                    }
                  }

                  .details-textarea-field {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    font-family: "Inter", sans-serif;
                    padding: 13px 11px;
                    border-radius: 8px;
                    border-color: $color-grey;
                    outline: none;
                    width: calc(100% - 22px);

                    &:focus {
                      border-color: $color-Accent-1;
                    }
                  }
                }

                .inner-field-container {
                  margin-top: 17px;

                  .comp-textfield {
                    .MuiInputBase-root {
                      background-color: $color-White;
                    }
                  }
                }

                .finals-point-container {
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  row-gap: 12px;
                  column-gap: 27px;

                  @media (max-width: 799px) {
                    grid-template-columns: repeat(2, 1fr);
                  }

                  label {
                    font-size: 16px;
                    line-height: 19px;
                    font-family: $regulerFont;
                    font-weight: 400;
                    color: $color-Drak-grey;
                  }

                  .comp-textfield {
                    margin-top: 10px;
                  }
                }

                .title-select-wrap {
                  display: flex;
                  flex-direction: column;
                }

                .select-title {
                  max-width: 134px;
                  width: 100%;

                  @media (max-width: 490px) {
                    max-width: none;
                  }

                  .css-1s2u09g-control {
                    padding: 5px 0;
                    border-radius: 8px;
                    background-color: $color-off-White;
                  }

                  .css-1okebmr-indicatorSeparator {
                    width: 0px;
                  }
                }

                .select-country-state {
                  max-width: 269px;
                  width: 100%;

                  @media (max-width: 490px) {
                    max-width: none;
                  }

                  .css-1s2u09g-control {
                    padding: 5px 0;
                    border-radius: 8px;
                    background-color: $color-off-White;
                  }

                  .css-1okebmr-indicatorSeparator {
                    width: 0px;
                  }
                }

                .select__control {
                  box-shadow: none;

                  padding: 5px 0px;
                  margin: 0px;
                  border-radius: 8px;

                  @media (max-width: 490px) {
                    margin-top: 5px;
                  }
                }

                .select__control,
                .react-select__control {
                  // &.select__control--is-disabled {

                  //   // border-color: $border-color;
                  //   .select__indicator-separator {
                  //     //   background-color: $border-color;
                  //   }
                  // }

                  // &.select__control--is-focused,
                  // &.react-select__control--is-focused {

                  // }

                  &.select__control--menu-is-open {
                    box-shadow: none;
                    // border: none;
                  }

                  .select__indicator svg {
                    cursor: pointer;
                  }

                  .select__indicator-separator {
                    display: none;
                  }

                  .select__single-value {
                    // border: none;
                    font-size: 16px;
                    line-height: 20px;
                    padding-left: 10px;
                    outline: none;
                    cursor: pointer;
                    margin: 0px;
                    font-family: "Inter", sans-serif;
                  }

                  .select__value-container {
                    // border: none;
                    padding: 0px;
                    margin: 0px;

                    .select__input-container {
                      font-size: 16px;
                      font-family: "Inter", sans-serif;
                      margin-left: 10px;
                    }
                  }

                  .select__placeholder {
                    font-size: 16px;
                    line-height: 20px;
                    padding-left: 10px;
                    cursor: pointer;
                    font-family: $regulerFont;
                  }
                }

                .select__menu {
                  margin: 0px;
                  border-radius: 0px;
                  padding: 0px;
                  z-index: 999;
                }

                .select__menu-list {
                  padding: 0px;
                }

                // Select Menu
                .select__menu,
                .react-select__menu {

                  .select__menu-list,
                  .react-select__menu-list {

                    .select__option,
                    .react-select__option {
                      cursor: pointer;
                      font-size: 16px;
                      color: $color-Black;
                      font-family: $regulerFont;
                      line-height: 19px;
                      padding: 11px;

                      &.select__option--is-focused {
                        background-color: $color-grey;
                        color: #000;
                      }

                      &.select__option--is-selected {
                        background-color: $color-grey;
                        color: $color-Black;
                      }
                    }
                  }

                  .select__menu-list,
                  .react-select__menu-list {
                    .select__group {
                      .select__group-heading {
                        margin-bottom: 0.5rem;
                        color: green;
                        font-weight: bolder;
                        font-size: inherit;
                      }
                    }
                  }
                }

                .Filteritemlist-wrap {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: end;
                  margin-top: 9px;

                  @media (max-width: 560px) {
                    flex-direction: column;
                    align-items: flex-start;
                  }

                  .filter-list-racing {
                    display: flex;
                    align-items: center;
                  }

                  .Filteritemlist-racing {
                    display: flex;
                    list-style-type: none;
                    padding: 0px;
                    margin: 0px;
                    column-gap: 49px;

                    li {
                      margin-right: 14px;

                      label {
                        color: $color-Black;
                        font-size: 16px;
                        font-weight: 400;
                        font-family: $regulerFont;
                        line-height: 21px;
                        display: flex;
                        column-gap: 12px;
                        width: auto;
                      }

                      .MuiButtonBase-root {
                        padding: 0px;
                      }
                    }
                  }

                  .show-tipsters-tipping {
                    @media (max-width: 560px) {
                      flex-direction: column;
                      row-gap: 9px;
                    }
                  }

                  // .Filteritemlist-datepicker {
                  //   display: contents;

                  //   .details-search-picker {
                  //     @media (max-width: 560px) {
                  //       min-width: 365px;
                  //     }
                  //   }

                  //   .MuiFormControl-marginNormal {
                  //     margin: 0px;

                  //     @media (max-width: 560px) {
                  //       margin-top: 9px;
                  //     }
                  //   }

                  //   .MuiOutlinedInput-input {
                  //     padding: 10.5px 14px;

                  //     @media (max-width: 560px) {
                  //       padding: 13px 14px;
                  //     }
                  //   }
                  // }
                }
              }

              .entry-fee-section {
                align-items: flex-start;

                .entry-flex {
                  .setting-btn {
                    margin-top: 8px;
                  }
                }

                .Filteritemlist-wrap {
                  margin-top: 0px;
                }
              }

              .about-comp-title {
                align-items: flex-start;
              }

              .tipster-number {
                align-items: baseline;

                .tipper-number {
                  border-bottom: 1px solid $color-Light-grey;
                  padding: 6px;
                }
              }

              .day-time-section {
                display: flex;
                justify-content: end;
                align-items: center;
                width: 100%;
                margin-bottom: 18px;

                @media (max-width: 799px) {
                  justify-content: flex-start;
                }

                .day-time-box {
                  max-width: 490px;

                  width: 100%;

                  .cutoff-txt {
                    color: #999999;
                    font-size: 16px;
                    font-weight: 400;
                    font-family: $regulerFont;
                    line-height: 21px;
                  }

                  .day-time {
                    display: flex;
                    align-items: flex-start;
                    column-gap: 18px;
                    margin-top: 6px;
                    // .time-picker-class {
                    //
                    // }

                    .border-gray {
                      .MuiInputBase-root {
                        border: 1px solid #cccccc !important;
                      }
                    }

                    .mw-236 {
                      max-width: 236px;

                      @media (max-width: 1160px) {
                        max-width: none;
                      }
                    }

                    .comp-date-selected-wrap {
                      width: 100%;
                      max-width: 236px;

                      @media (max-width: 1160px) {
                        max-width: none;
                      }

                      .MuiFormControl-root {
                        margin: 0px;
                        width: 100%;
                        background-color: white;
                        border-radius: 8px;

                        @media (max-width: 1160px) {
                          width: 100%;
                        }
                      }

                      .MuiInputBase-root {
                        max-height: 40px;
                        padding-right: 0px;
                        border-radius: 5px;
                        font-size: 14px;
                        line-height: 16px;
                        font-family: $regulerFont;
                        font-weight: 400;
                        // color: #999999;
                        border-radius: 8px;
                        border-color: hsl(0, 0%, 80%) !important;

                        .MuiInputBase-input {
                          padding: 11px 0px 10px 8px;
                        }

                        .MuiOutlinedInput-notchedOutline {
                          border-color: hsl(0, 0%, 80%);
                          border-width: 1px;
                        }

                        &:hover {
                          border-color: #c9c9c9;
                        }
                      }

                      .Mui-focused {
                        .MuiOutlinedInput-notchedOutline {
                          border-width: 2px;
                        }
                      }

                      .MuiInput-underline:before {
                        border-bottom: none !important;
                      }

                      .MuiInput-underline:after {
                        border-bottom: none !important;
                      }

                      .field-container {
                        max-width: 614px;
                        width: 100%;

                        // .sort-select {
                        //   // max-width: 282px;
                        //   // max-width: 100%;
                        //   // width: 835px;
                        //   // width: 748px;

                        //   // @media only screen and (max-width: 1023px) {
                        //   //   margin-right: 0px;
                        //   //   max-width: none;
                        //   // }

                        //   // @media (max-width: 799px) {
                        //   //   max-width: none;
                        //   // }

                        //   // @media only screen and (max-width: 479px) {
                        //   //   grid-column-start: 1;
                        //   //   grid-column-end: 4;
                        //   // }
                        // }

                        .comp-textfield {
                          max-width: 614px;
                          width: 100%;

                          @media (max-width: 490px) {
                            //   max-width: none;
                            margin-top: 5px;
                          }

                          .MuiOutlinedInput-root {
                            border-radius: 8px;
                            font-family: "Inter", sans-serif;

                            .MuiOutlinedInput-input {
                              padding: 13px 11px;
                            }
                          }

                          .Mui-focused {
                            .MuiOutlinedInput-notchedOutline {
                              border-color: $color-Accent-1;
                              border-width: 1;
                            }
                          }

                          svg {
                            cursor: pointer;
                          }

                          input[type="number"]::-webkit-inner-spin-button,
                          input[type="number"]::-webkit-outer-spin-button {
                            display: none;
                          }
                        }
                      }

                      .title-select-wrap {
                        display: flex;
                        flex-direction: column;
                      }

                      .select-title {
                        max-width: 134px;
                        width: 100%;

                        @media (max-width: 490px) {
                          max-width: none;
                        }

                        .css-1s2u09g-control {
                          padding: 5px 0;
                          border-radius: 8px;
                          background-color: $color-off-White;
                        }

                        .css-1okebmr-indicatorSeparator {
                          width: 0px;
                        }
                      }

                      .select-country-state {
                        max-width: 269px;
                        width: 100%;

                        @media (max-width: 490px) {
                          max-width: none;
                        }

                        .css-1s2u09g-control {
                          padding: 5px 0;
                          border-radius: 8px;
                          background-color: $color-off-White;
                        }

                        .css-1okebmr-indicatorSeparator {
                          width: 0px;
                        }
                      }

                      .select__control {
                        box-shadow: none;

                        padding: 5px 0px;
                        margin: 0px;
                        border-radius: 8px;

                        @media (max-width: 490px) {
                          margin-top: 5px;
                        }
                      }

                      .select__control,
                      .react-select__control {
                        // &.select__control--is-disabled {

                        //   // border-color: $border-color;
                        //   .select__indicator-separator {
                        //     //   background-color: $border-color;
                        //   }
                        // }

                        // &.select__control--is-focused,
                        // &.react-select__control--is-focused {

                        // }

                        &.select__control--menu-is-open {
                          box-shadow: none;
                          // border: none;
                        }

                        .select__indicator svg {
                          cursor: pointer;
                        }

                        .select__indicator-separator {
                          display: none;
                        }

                        .select__single-value {
                          // border: none;
                          font-size: 16px;
                          line-height: 20px;
                          padding-left: 10px;
                          outline: none;
                          cursor: pointer;
                          margin: 0px;
                          font-family: "Inter", sans-serif;
                        }

                        .select__value-container {
                          // border: none;
                          padding: 0px;
                          margin: 0px;

                          .select__input-container {
                            font-size: 16px;
                            font-family: "Inter", sans-serif;
                            margin-left: 10px;
                          }
                        }

                        .select__placeholder {
                          font-size: 16px;
                          line-height: 20px;
                          padding-left: 10px;
                          cursor: pointer;
                          font-family: $regulerFont;
                        }
                      }

                      .select__menu {
                        margin: 0px;
                        border-radius: 0px;
                        padding: 0px;
                        z-index: 999;
                      }

                      .select__menu-list {
                        padding: 0px;
                      }

                      // Select Menu
                      .select__menu,
                      .react-select__menu {

                        .select__menu-list,
                        .react-select__menu-list {

                          .select__option,
                          .react-select__option {
                            cursor: pointer;
                            font-size: 16px;
                            color: $color-Black;
                            font-family: $regulerFont;
                            line-height: 19px;
                            padding: 11px;

                            &.select__option--is-focused {
                              background-color: $color-grey;
                              color: #000;
                            }

                            &.select__option--is-selected {
                              background-color: $color-grey;
                              color: $color-Black;
                            }
                          }
                        }

                        .select__menu-list,
                        .react-select__menu-list {
                          .select__group {
                            .select__group-heading {
                              margin-bottom: 0.5rem;
                              color: green;
                              font-weight: bolder;
                              font-size: inherit;
                            }
                          }
                        }
                      }
                    }

                    .timePickerRoot {
                      border: none !important;
                    }

                    .details-search-picker {
                      margin: 0;

                      @media only screen and (max-width: 479px) {
                        grid-column-start: 1;
                        grid-column-end: 3;
                      }

                      .MuiOutlinedInput-root {
                        background: $color-Light-grey;
                        border-radius: 8px;
                        padding: 0;
                        max-width: 180px;
                        margin-right: 4px;

                        @media only screen and (max-width: 1023px) {
                          max-width: initial;
                          width: 100%;
                          margin-right: 0px;
                        }

                        input {
                          padding: 9px 8px;
                          font-family: $regulerFont !important;
                          font-size: 16px;
                          line-height: 22.4px;
                          font-weight: 600;
                          color: $color-Black;
                        }

                        fieldset {
                          border: none;
                        }

                        .MuiIconButton-root {
                          padding: 0px 8px 0px 0px;

                          &:hover {
                            background-color: transparent;
                          }

                          .MuiTouchRipple-root {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }

                .yes-no-box-wrap {
                  background: $color-White;
                  padding: 19px;
                  max-width: calc(490px - 38px) !important;
                  border-radius: 8px;
                  border: 1px solid $color-grey;
                }
              }

              .prize-info-container {
                background-color: $color-White;
                border: 1px solid $color-grey;
                border-radius: 8px;
                padding: 18px;
                margin-top: 7px;

                @media (max-width: 799px) {
                  padding: 12px;
                }

                .prize-header-section {
                  display: flex;
                  align-items: center;
                }

                .cutoff-txt {
                  font-size: 16px;
                  line-height: 19px;
                  font-weight: 600;
                  font-family: $regulerFont;
                  letter-spacing: 0px;
                  color: $color-Black !important;
                  margin-bottom: 17px;

                  @media (max-width: 799px) {
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 13px;
                  }
                }

                .prize-position {
                  font-size: 16px;
                  line-height: 19px;
                  font-weight: 400;
                  font-family: $regulerFont;
                  letter-spacing: 0px;
                  color: $color-Black !important;
                  height: 49px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .w-80 {
                  min-width: 80px;

                  @media (max-width: 799px) {
                    min-width: 55px;
                  }
                }

                .prize-info-item {
                  display: flex;
                  align-items: flex-start;

                  .prize-field-container {
                    .comp-textfield {
                      &:not(:last-child) {
                        margin-bottom: 12px;
                      }
                    }
                  }

                  .delete-icon {
                    padding: 0px;

                    @media (max-width: 799px) {
                      svg {
                        width: 32px;
                        height: 32px;
                      }
                    }
                  }
                }

                .add-position-btn {
                  border-radius: 8px;
                  background-color: $color-Affirmative;
                  font-size: 16px;
                  line-height: 19px;
                  font-weight: 400;
                  font-family: $regulerFont;
                  letter-spacing: 0px;
                  color: $color-White;
                  text-transform: capitalize;
                  padding: 9px;
                }
              }

              .comp-button {
                position: fixed;
                bottom: 0px;
                background: #e7e9ec;
                width: 100%;
                max-width: 1350px;
                padding: 16px 0px;
                text-align: center;

                .comp-btn {
                  display: flex;
                  width: 100%;

                  @media (max-width: 600px) {
                    display: block;
                    width: 100%;
                  }

                  .btn-save {
                    // max-width: 835px;
                    width: 100%;
                    background-color: $color-Accent-1;
                    color: $color-White;
                    border-radius: 8px;
                    padding: 12px 0px;
                    max-height: 45px;
                    text-transform: capitalize;
                    font-size: 18px;

                    @media (max-width: 600px) {
                      font-size: 16px;
                    }
                  }
                }
              }
            }

            .comp-field-container .detail-child:nth-child(odd) {
              background-color: #fafafa;
            }

            .comp-field-container .detail-child:nth-child(even) {
              background-color: $color-Light-grey;
            }
          }

          .details-sec .details:nth-child(odd) {
            background-color: #fafafa;
          }

          .details-sec .details:nth-child(even) {
            background-color: $color-Light-grey;
          }
        }
      }
    }

    .left {
      max-width: 221px;
      width: 100%;
      color: $color-Black;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 600;
      font-family: $regulerFont;

      @media (max-width: 400px) {
        font-size: 15px;
        // width: 60%;
      }
    }

    .right {
      max-width: 490px;
      width: 100%;
      color: $color-Black;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 400;
      font-family: $regulerFont;

      @media (max-width: 400px) {
        font-size: 15px;
        // width: 40%;
      }
    }

    .detail-flex {
      display: flex;
      align-items: center;
      column-gap: 9px;
    }
  }

  .comp-right-sec {
    max-width: 401px;
    padding: 36px 0px 0px;

    @media (max-width: 1279px) {
      display: none;
    }

    .tipping-right-details {
      background-color: $color-Lavender-1;
      padding: 18px;

      .ads-container {
        padding: 5px 5px 27px 5px;

        .ads-wrap {
          width: 355px;
          max-height: 330px;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .rightbar-tab-detail {
        //   margin-top: 28px;

        .MuiTabs-scroller {
          .MuiTabs-indicator {
            display: none;
          }

          .active {
            border-bottom: 3px solid $color-Primary;
          }
        }

        @media (max-width: 799px) {
          margin-top: 6px;
        }

        .MuiButtonBase-root.active {
          color: $color-Accent-1;
        }

        .MuiTab-textColorInherit {
          opacity: 1;
        }

        .MuiButtonBase-root {
          font-size: 16px;
          font-family: VeneerClean-Soft !important;
          color: $color-Black;
          line-height: 21px;
        }

        .MuiTab-root {
          min-width: 120px;
        }

        .MuiTabs-indicator {
          height: 3px;
          color: $color-Primary;
        }

        .MuiTouchRipple-root {
          display: none;
        }
      }

      .teams-details-wrap {
        .round-stats-header {
          font-family: $primaryFont;
          font-size: 22.4px;
          line-height: 28px;
          padding-bottom: 9px;
          border-bottom: 1px solid $color-Accent-1;
        }

        .team-stats {
          padding: 9px 0px;

          .team-stats-card {
            padding: 9px 0px;
            border-bottom: 1px solid $color-grey;

            .teams-details {
              display: flex;
              justify-content: space-between;
              padding-bottom: 5px;

              .teams-wrap {
                .flag-wrap {
                  width: 32px;
                  height: 32px;

                  img {
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                  }
                }

                .team-name {
                  font-size: 12px;
                  line-height: 15px;
                  font-weight: 600;
                  font-family: $regulerFont;
                }
              }

              .away-team-wrap {
                .flag-wrap {
                  margin-left: auto;
                }
              }
            }

            .stats-progress-bar {
              width: 100%;
              height: 8px;
              border-radius: 4px;
              background-color: $color-grey;
              display: flex;
              align-items: center;
              overflow: hidden;

              .progress-bar {
                height: 8px;
              }
            }

            .progress-count-wrap {
              display: flex;
              justify-content: space-between;
              padding-top: 5px;

              .progress-count {
                color: $color-gray-dark;
              }
            }
          }
        }
      }
    }
  }
}

.odds-team-section {
  text-align: center;

  .team-wrap {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin: 0px auto;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .spread-line {
    span {
      font-size: 14px;
      line-height: 16px;
      font-family: $regulerFont;
      font-weight: 400;
      letter-spacing: 0px;
      color: $color-Black;
    }
  }

  .odds-text {
    width: 50px;
    text-align: center;
    padding: 4px 0px;
    background-color: #e7e9ec;
    border-radius: 5px;
    box-shadow: 0px 1px 1px 0px #000000b3;
    margin: 0px auto;

    span {
      font-size: 14px;
      line-height: 16px;
      font-family: $regulerFont;
      font-weight: 400;
      letter-spacing: 0px;
      color: $color-Black;
    }
  }
}

.view-tips-comp-table {
  .MuiTableContainer-root {
    margin-bottom: 0px !important;
  }

  .view-tips-first-row {
    .MuiTableCell-body {
      border-bottom: 1px solid $color-Accent-1 !important;
    }

    .team-name {
      color: $color-Accent-1;
      font-weight: 700 !important;
    }
  }

  .team-name {
    font-weight: 400 !important;
  }

  .show-more-cell {
    background-color: $color-Light-grey;

    .show-more-text {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 4px;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      font-family: $regulerFont;
      color: $color-Accent-1;
      letter-spacing: 0px;
    }
  }
}

.tipsters-table {
  .paid-and-notPaid-wrap {
    .paid-notPaid-btn {
      padding: 7px 12px 6px;
      border-radius: 8px;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      font-family: $regulerFont;
      letter-spacing: 0px;
      text-transform: capitalize;

      &:hover {
        box-shadow: none;
      }
    }

    .paid {
      background-color: $color-Affirmative;
      color: $color-White;
    }

    .notPaid {
      background-color: transparent;
      color: $color-attention;
      border: 1px solid $color-attention;
    }
  }
}

.edit-comp-details {
  .text-16 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: 0px;
    font-family: $regulerFont;
    color: $color-Black;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .create-comp-accordion {
    margin-bottom: 18px;
    border-radius: 8px;
    box-shadow: 0px 3px 9px 0px #00000029;

    .MuiAccordion-heading {
      .MuiAccordionSummary-root {
        background: linear-gradient(90deg,
            rgba(68, 85, 199, 1) 0%,
            rgba(34, 70, 149, 1) 50%,
            rgba(0, 55, 100, 1) 98%);
        border-radius: 8px 8px 0px 0px;
        min-height: 45px;

        @media (max-width: 799px) {
          min-height: 43px;
        }

        .MuiAccordionSummary-content {
          margin: 0px;

          .accordion-header-text {
            font-size: 22.4px;
            line-height: 28px;
            color: $color-White;
            font-weight: 400;
            font-family: $primaryFont;
            letter-spacing: 0px;
          }
        }

        .MuiAccordionSummary-expandIconWrapper {
          svg {
            path {
              fill: $color-White;
            }
          }
        }
      }
    }

    .MuiAccordionDetails-root {
      padding: 0px;

      .details-sec {
        padding: 0px !important;

        .details-flex-start {
          align-items: flex-start !important;

          @media (max-width: 659px) {
            display: block !important;
          }
        }

        .dummy-text-box {
          padding: 18px;
          background-color: $color-White;
          border-radius: 8px;
          border: 1px solid $color-grey;

          .dummy-text {
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;
            letter-spacing: 0px;
            margin-bottom: 14px;

            @media (max-width: 799px) {
              font-size: 14px;
              line-height: 16px;
            }
          }

          .dummy-input {
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;
            letter-spacing: 0px;
            border-bottom: 1px solid $color-grey;
            padding-bottom: 6px;

            @media (max-width: 799px) {
              font-size: 14px;
              line-height: 16px;
            }
          }

          .week-dummy-input {
            border-bottom: none;
          }

          .final-dummy-input {
            width: calc(100% - 80px);
          }

          .final-points-grid {
            display: grid;
            grid-template-columns: auto auto;
            row-gap: 12px;
            column-gap: 30px;

            .final-points {
              display: flex;
              align-items: center;
              column-gap: 16px;
            }
          }
        }

        .prize-flex-bottom {
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }

        .prize-flex {
          display: flex;
          align-items: center;
          column-gap: 70px;

          .position-text {
            width: 70px;
            text-align: center;
          }
        }

        .prize-flex-header {
          margin-bottom: 26px;
        }

        .prize-dummy-input {
          padding-top: 5px;
          width: calc(100% - 140px);
        }
      }

      .pay-entry-fee {
        margin-top: 10px;
      }

      .setting-btn {
        padding: 10px 9px 9px;
        border-radius: 8px;
        background-color: $color-attention;
        color: $color-White;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        letter-spacing: 0px;
        font-family: $regulerFont;
        letter-spacing: 0px;
        text-transform: capitalize;

        @media (max-width: 799px) {
          font-size: 14px;
          line-height: 16px;
        }

        .MuiButton-endIcon {
          transform: rotate(90deg);

          svg {
            path {
              stroke: $color-White;
            }

            line {
              stroke: $color-White;
            }
          }
        }
      }

      .delete-setting-btn {
        svg {
          path {
            stroke: $color-White;
          }
        }
      }
    }
  }

  .edit-comp-button {
    // position: fixed;
    // bottom: 0px;
    // background: #E7E9EC;
    // width: 100%;
    // max-width: 1350px;
    // padding: 16px 0px;
    text-align: center;

    .comp-btn {
      .btn-save {
        font-size: 16px;
        line-height: 19px;
        font-family: $regulerFont;
        letter-spacing: 0px;
        font-weight: 400;
        background-color: $color-Accent-1;
        color: $color-White;
        border-radius: 8px;
        padding: 12px 45px;
        max-height: 45px;
        text-transform: capitalize;

        @media (max-width: 600px) {
          font-size: 16px;
        }
      }
    }
  }
}

.img-blur {
  filter: blur(3px);
}