@import "../../../assets/scss/variable.scss";

.ranking-comp-wrap {
  background-color: $color-off-White;

  .ranking-comp-header {
    padding: 46px 18px 3px;
    margin-top: -5px;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }
  }

  .tab-search-section {
    padding: 18px 18px 19px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    // margin-bottom: 18px;
    @media only screen and (max-width: 799px) {
      padding: 12px;
      margin: 0px -12px;
    }

    .filter-section {
      max-width: none;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 12px;
      padding-bottom: 18px;
      border-bottom: 1px solid $color-Light-grey;

      @media only screen and (max-width: 1023px) {
        display: grid;
        grid-template-columns: auto;
        column-gap: 12px;
        row-gap: 12px;
      }

      @media only screen and (max-width: 479px) {
        grid-template-columns: auto auto;
      }

      .sort-select {
        max-width: 282px;
        width: 100%;
        margin-right: 4px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      .Period-select {
        max-width: 202px;
        margin-right: 4px;

        @media only screen and (max-width: 1023px) {
          margin-right: 0px;
          max-width: none;
        }

        @media (max-width: 799px) {
          max-width: none;
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      .mw-none {
        width: 100%;
      }

      .select__control {
        background-color: $color-Light-grey;
        border-radius: 8px;
        max-height: 36px;

        .select__single-value {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__placeholder {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__input-container {
          font-family: $regulerFont !important;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          color: $color-Black;
          padding: 0px 8px;
        }

        .select__indicator-separator {
          width: 0px;
        }

        .select__dropdown-indicator {
          padding: 10px;
        }
      }

      .select__menu-portal {
        z-index: 99;
      }

      .select__menu,
      .react-select__menu {
        z-index: 99;

        .select__menu-list,
        .react-select__menu-list {

          // z-index: 99;
          .select__option,
          .react-select__option {
            cursor: pointer;
            font-size: 16px;
            color: $color-Black;
            font-family: $regulerFont;
            line-height: 19px;
            padding: 11px;

            &.select__option--is-focused {
              background-color: #d4d6d8;
              color: #000;
            }

            &.select__option--is-selected {
              background-color: #d4d6d8;
              color: $color-Black;
            }
          }
        }

        .select__menu-list,
        .react-select__menu-list {
          .select__group {
            .select__group-heading {
              margin-bottom: 0.5rem;
              color: green;
              font-weight: bolder;
              font-size: inherit;
            }
          }
        }
      }

      .filter-list-racing {
        display: flex;
        align-items: center;
        margin-left: 18px;

        @media (max-width: 1023px) {
          margin-left: 0px;
        }

        .Filteritemlist-racing {
          display: flex;
          list-style-type: none;
          padding: 0px;
          margin: 0px;
          column-gap: 56px;

          @media (max-width: 1079px) {
            column-gap: 10px;
          }

          li {
            // margin-right: 14px;

            label {
              color: $color-Black;
              font-size: 16px;
              font-weight: 400;
              font-family: $regulerFont;
              line-height: 21px;
              display: flex;
              column-gap: 12px;
              width: max-content;

              @media (max-width: 799px) {
                font-size: 14px;
                line-height: 17px;
                column-gap: 6px;
              }
            }

            .MuiButtonBase-root {
              padding: 0px;
            }
          }
        }
      }
    }

    .teamsport-tab-detail {
      margin-top: 18px;
      //   margin-top: 28px;
      border-bottom: 2px solid $color-Accent-1;

      .MuiTabs-scrollable {
        .MuiTabs-indicator {
          display: none;
        }

        .active {
          border-bottom: 3px solid $color-Primary;
        }
      }

      @media (max-width: 799px) {
        margin-top: 6px;
      }

      .active {
        .MuiTab-wrapper {
          color: $color-Accent-1;
        }
      }

      .MuiTab-textColorInherit {
        opacity: 1;
      }

      .MuiTab-wrapper {
        font-size: 16px;
        font-family: $primaryFont;
        color: $color-Black;
        line-height: 21px;
      }

      .MuiTab-root {
        min-width: 120px;
      }

      .MuiTabs-indicator {
        height: 3px;
        color: $color-Primary;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }

    .stepper-score-sec {
      padding-top: 15px;
      padding-bottom: 18px;

      .stepper-input {
        // margin-top: 15px;
        display: flex;
        //   border: 1px solid $color-gray-dark-1;
        border-radius: 8px;
        max-height: 44px;
        column-gap: 3px;

        .stepper-input__button {
          padding: 10px 30px;
          min-width: 35px;
          cursor: pointer;
          color: $color-Black;
          border-left: 0px;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          text-transform: capitalize;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 400;
          background-color: #e8eaec;
          //   background-color: #00000042;

          @media (max-width: 540px) {
            font-size: 12px;
          }
        }

        .Next-btn {
          border-top-right-radius: 8px !important;
          border-bottom-right-radius: 8px !important;
          border-top-left-radius: 0px !important;
          border-bottom-left-radius: 0px !important;
        }

        .stepper-input__content {
          border: 1px solid $color-gray-dark-1;
          font-family: $regulerFont;
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          padding: 8px 26px;

          color: $color-Black;
          width: 100%;
          text-align: center;
        }

        .margin-textfield {
          width: 100%;

          .MuiInputBase-root {
            height: 42px;

            input {
              padding: 9px 10px;
              text-align: center;
              // border-left: 1px solid $color-gray-dark-1;
              // border-right: 1px solid $color-gray-dark-1;
              border: 1px solid $color-gray-dark-1;
            }

            fieldset {
              border: none;
            }
          }
        }

        @media only screen and (max-width: 479px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }

        .tipping-select {
          width: 100%;

          .select__control {
            border-radius: 0px;
            max-height: 44px;
            max-width: 100%;
            border-color: hsl(0, 0%, 80%);
            box-shadow: none;

            .select__single-value {
              font-family: $regulerFont !important;
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 600;
              color: $color-Black;
              padding: 0px 8px;
              text-align: center;
            }

            .select__placeholder {
              font-family: $regulerFont !important;
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 600;
              color: $color-Black;
              padding: 0px 8px;
            }

            .select__input-container {
              font-family: $regulerFont !important;
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 600;
              color: $color-Black;
              padding: 0px 8px;
            }

            .select__indicator-separator {
              width: 0px;
            }

            .select__dropdown-indicator {
              padding: 10px;
            }
          }

          .select__menu {
            margin: 0px;
            border-radius: 0px;
            padding: 0px;
            z-index: 999;
          }

          .select__menu-list {
            padding: 0px;
          }

          // Select Menu
          .select__menu,
          .react-select__menu {

            .select__menu-list,
            .react-select__menu-list {

              .select__option,
              .react-select__option {
                cursor: pointer;
                font-size: 16px;
                color: $color-Black;
                font-family: $regulerFont;
                line-height: 19px;
                padding: 11px 40px 11px 11px;
                text-align: center;

                &.select__option--is-focused {
                  background-color: #d4d6d8;
                  color: #000;
                }

                &.select__option--is-selected {
                  background-color: #d4d6d8;
                  color: $color-Black;
                }
              }
            }

            .select__menu-list,
            .react-select__menu-list {
              .select__group {
                .select__group-heading {
                  margin-bottom: 0.5rem;
                  color: green;
                  font-weight: bolder;
                  font-size: inherit;
                }
              }
            }
          }

          .select__menu-portal {
            z-index: 99;
          }
        }
      }

      .score-share-sec {
        display: flex;
        justify-content: space-between;
        align-items: end;
        width: 100%;
        margin-top: 16px;

        @media (max-width: 620px) {
          display: block;
        }

        .score-sec {
          width: 80%;

          @media (max-width: 620px) {
            width: 100%;
          }

          .round-score-sec {
            max-width: 460px;
            width: 100%;
            display: flex;
            align-items: center;
            column-gap: 18px;

            @media (max-width: 620px) {
              justify-content: space-between;
              max-width: unset;
            }

            .round-score {
              column-gap: 25px;
              max-width: 193px;
              width: 100%;
              padding: 11px 30px 12px 18px;
              border: 1px solid $color-Light-grey;
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;

              @media (max-width: 620px) {
                padding: 11px 22px 12px 9px;
                column-gap: 18px;
              }

              .score {
                font-weight: 400;
                font-size: 16px;
                color: $color-Black;
                line-height: 21px;
                font-family: $regulerFont;
              }
            }
          }

          .bottom-line-sec {
            .color-danger {
              color: $color-Negative !important;
            }

            .final-txt {
              margin-top: 8px;
              display: flex;
              align-items: center;
              column-gap: 5px;

              font-weight: 400;
              font-size: 16px;
              color: $color-Accent-1;
              line-height: 21px;
              font-family: $regulerFont;

              @media (max-width: 580px) {
                display: block;
              }

              @media (max-width: 900px) {
                font-size: 14px;
              }

              @media (max-width: 799px) {
                font-size: 16px;
              }

              @media (max-width: 650px) {
                font-size: 15px;
              }

              @media (max-width: 460px) {
                font-size: 12px;
              }

              // .comp-header-details {
              //   .day-time-details {
              //     //   font-weight: 400;
              //     //   font-family: $regulerFont;
              //     //   color: $color-Black;
              //     //   font-size: 16px;
              //     //   line-height: 21px;

              //     //   @media (max-width: 900px) {
              //     //     font-size: 14px;
              //     //   }
              //     //   @media (max-width: 799px) {
              //     //     font-size: 16px;
              //     //   }

              //     //   @media (max-width: 650px) {
              //     //     font-size: 15px;
              //     //   }
              //   }
              // }
            }
          }
        }
      }
    }
  }

  .page-deatils-wrap {
    margin-top: 18px;
    // padding: 18px 33px 84px 33px;
    padding-top: 5px;
    padding-bottom: 9px;
    background-color: $color-White;
    box-shadow: 0px 3px 9px 0px #0000000d;

    @media (max-width: 1150px) {
      // overflow-x: scroll;
      // position: relative;
      // padding: 16px 13px 16px 13px;
    }

    // padding: 12px;
    .table-content {
      // border: 1px solid #d4d6d8;

      .fix-header {
        padding: 16px 0px 5px 0px !important;
        max-width: 200px;
        width: 100%;
        left: 0;
      }

      .fix-header2 {
        padding: 16px 0px 5px 0px !important;
        max-width: 210px;
        width: 100%;
        left: 200px;
      }

      .fix-cell {
        padding: 16px 0px 5px 0px !important;
        max-width: 200px;
        width: 100%;
        left: 0;
      }

      .fix-cell2 {
        padding: 16px 0px 5px 0px !important;
        max-width: 210px;
        width: 100%;
        left: 200px;
      }

      .txt-center {
        text-align: center !important;
      }

      .title-col-1 {
        position: sticky;
        left: 0;
        z-index: 2;
        background: $color-White;
        width: 100px;
        align-self: center;
      }

      .title-col-2 {
        position: sticky;
        left: 100px;
        z-index: 2;
        background: $color-White;
        width: 200px;
      }

      .table-title {
        // padding: 16px 0px 5px 0px;
        padding: 15px 5px 5px 27px;
        // border-right: 1px solid #d4d6d8;
        // border-bottom: 0 !important;
        color: $color-Black;
        font-size: 16px;
        line-height: 21px;
        font-weight: 600;
        font-family: $regulerFont;

        @media (max-width: 560px) {
          padding: 15px 5px 5px 10px;
        }
      }

      .table-position {
        @media (max-width: 799px) {
          width: calc(100% - 145px);
        }
      }

      .table-title-left {
        border-bottom: 0 !important;
        // @media (max-width: 560px) {
        //   width: max-content !important;
        // }
      }

      .detail-flex {
        display: flex !important;
        align-items: center !important;
        column-gap: 9px;
      }

      .table-detail {
        // border-right: 1px solid #d4d6d8;
        padding: 15px 5px 5px 27px;
        color: $color-Black;
        font-size: 16px;
        line-height: 21px;
        font-weight: 400;
        font-family: $regulerFont;

        @media (max-width: 560px) {
          padding: 15px 5px 5px 10px;
        }
      }

      .tipper-name {
        @media (max-width: 1250px) {
          overflow: hidden !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: calc(100% - 36px) !important;
        }

        // @media (max-width: 560px) {
        //   width: 100px !important;
        // }
      }

      // .row-height {
      //   height: 50px;
      // }

      .border {
        border-bottom: 1px solid $color-Primary;
        font-weight: 600;
        height: 57.5px;

        @media (max-width: 1251px) {
          height: 60px;
        }

        @media (max-width: 960px) {
          height: 53px;
        }

        @media (max-width: 799px) {
          height: 53.5px;
        }

        @media (max-width: 608px) {
          height: 53.5px;
        }
      }

      .tablecell-highlight {
        font-weight: 600;
        border-bottom: 1px solid $color-Primary;
        // height: 36.5px;
        height: 33px;
        color: $color-Accent-1;

        // @media (max-width: 799px) {
        //   height: 33px !important;
        // }
      }

      // .txt-width {
      //   max-width: 200px;
      //   width: 100%;
      //   overflow: hidden;
      // }

      .team-img-wrap {
        width: 36px;
        height: 36px;
        border-radius: 50%;

        .team-img {
          border-radius: 50%;
          width: 100%;
          object-fit: contain;
        }

        // @media (max-width: 939px) {
        //   margin: 0px auto;
        // }
      }
    }

    .view-more-btn {
      @media (max-width: 1150px) {
        position: sticky;
        left: 1px;
      }
    }

    // New table design

    .new-ladder-sec {
      // overflow: scroll;
      overflow-x: auto;
      position: relative;

      .new-ladder-table {
        .primary-font-color {
          color: $color-White !important;

          @media (max-width: 799px) {
            font-size: 11.42px !important;
            line-height: 14px !important;
          }
        }

        .highlight-border {
          border-bottom: 1px solid $color-Accent-1;
          color: $color-Accent-1 !important;
          font-weight: 600 !important;
        }

        .table-cell-center {
          text-align: center;
        }

        .MuiTableRow-head {
          .MuiTableCell-head {
            padding: 13px 0px 12px 12px;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0px;
            font-weight: 600;
            color: $color-White;
            background-color: $color-Primary;

            @media (max-width: 799px) {
              font-size: 11.42px;
              line-height: 14px;
              padding: 13px 6px 12px 6px;
            }
          }
        }

        .MuiTableBody-root {
          .rank-name {
            padding-left: 8px;
          }

          .MuiTableCell-body {
            padding: 6px 0px 5px 15px;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0px;
            font-weight: 400;
            color: $color-Black;

            @media (max-width: 799px) {
              font-size: 15px;
              line-height: 18px;
              padding: 6px 6px 5px 6px;
            }
          }

          .tipper-img-wrap {
            display: flex;
            align-items: center;
            column-gap: 9px;

            .profile-icon-wrap {
              width: 36px;
              height: 36px;
              border-radius: 50%;

              .profile-icon {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 50%;
              }
            }

            .icon-border {
              border: 1px solid $color-Accent-1;
            }
          }
        }

        .sorting-cell {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 5px;
          cursor: pointer;

          .sorting-arrow-wrap {
            display: flex;
            flex-direction: column;
            row-gap: 2px;
            cursor: pointer;

            .active {
              path {
                fill: $color-Accent-1;
              }
            }

            .down-arrow {
              transform: rotate(180deg);
            }
          }
        }

        .sticky-cell-table {
          .MuiTableRow-head {
            .MuiTableCell-head {
              padding: 0px;
              border: none;
            }

            .sticky-cell-table-pos {
              width: 56px;
            }
          }

          .MuiTableRow-root {
            .MuiTableCell-body {
              border: none;
              padding: 0px;
            }

            .sticky-cell-table-pos {
              width: 56px;
            }
          }
        }

        .current-user-stickycell {
          border-bottom: 1px solid $color-Accent-1;
        }
      }
    }
  }

  .pagination-section {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .MuiPagination-root {
      .MuiPagination-ul {
        .MuiButtonBase-root {
          width: 36px;
          height: 36px;

          svg {
            width: 24px;
            height: 24px;
          }

          .MuiTouchRipple-root {
            display: none;
          }
        }

        .MuiPaginationItem-root {
          font-size: 18px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          border-radius: 50%;
        }
      }
    }
  }

  .txt-start {
    text-align: start !important;
  }

  .learn-more-box {
    padding: 11px 33px 0px 5px;

    @media (max-width: 1150px) {
      box-shadow: 0px 3px 9px 0px #0000000d;
      background-color: $color-White;
      padding: 11px 33px 5px 5px;
    }

    .learn-more {
      text-align: end;
      text-transform: capitalize;
      color: $color-Accent-1;
      border: none;
      padding: 0;
      cursor: pointer;
      outline: none;
      text-decoration: underline;
      font-size: 14px;

      &:hover {
        background-color: transparent;
      }

      // @media (max-width: 940px) {
      //   text-align: start;
      // }
    }
  }
}

.table-title-cell {
  min-width: 150px;

  @media (max-width: 560px) {
    min-width: 106px;
  }
}

.table-detail-cell {
  min-width: 150px;

  @media (max-width: 560px) {
    min-width: 106px;
  }
}