@import "../../../assets/scss/variable.scss";

.soc-signup-section-wrap {
  margin: 0px -12px;

  // common CSS

  .soc-sign-up-button {
    background-color: $color-Secondary;
    color: $color-White;
    font-size: 22.4px;
    line-height: 27px;
    font-weight: 400;
    font-family: $regulerFont;
    border-radius: 8px;
    border: 1.5px solid $color-White;
    box-shadow: 0px 1px 6px 0px #ffffff9c;
    padding: 12px 24px;
    text-transform: none;

    @media (max-width: 1023px) {
      font-size: 14px;
      line-height: 16px;
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }

  .color-white {
    color: $color-White !important;
  }

  .soc-signup-section-header {
    font-size: 43.9px;
    line-height: 52px;
    font-weight: 600;
    font-family: $regulerFont;
    letter-spacing: 0px;
    text-align: center;
    color: $color-Black-2;
    margin-bottom: 16px;

    @media (max-width: 1023px) {
      font-size: 22.4px;
      line-height: 31.36px;
      margin-bottom: 9px;
    }
  }

  .highlight-text {
    color: $color-Secondary;
  }

  .soc-signup-header-details {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    font-family: $regulerFont;
    letter-spacing: 0px;
    text-align: center;
    color: $color-Black;

    @media (max-width: 1023px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .prize-top {
    margin-top: 10px;
  }

  // SOC sign up header page

  .soc-signup-header-wrap {
    background-image: url("../../../assets/images/socSignupPage/socSignupPageBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 45px 0px 277px;
    background-color: #070a22;

    @media (max-width: 1023px) {
      background-image: url("../../../assets/images/socSignupPage/socSignupPageMobileBanner.png");
      padding: 23px 0px 395px;
    }

    .header-signup-container {
      display: flex;
      align-items: flex-start;
      max-width: 1444px;
      margin: 0px auto;
      column-gap: 40px;
      padding: 0px 12px;

      @media (max-width: 1023px) {
        flex-direction: column;
      }

      .left-header-wrap {
        padding-top: 42px;
        width: 50%;

        @media (max-width: 1023px) {
          width: 100%;
        }

        .header-text {
          font-size: 61.47px;
          line-height: 86.05px;
          font-weight: 600;
          font-family: $regulerFont;
          letter-spacing: 0px;
          color: $color-White;

          @media (max-width: 1023px) {
            font-size: 31.36px;
            line-height: 33px;
          }
        }

        .header-text-bottom {
          margin-bottom: 29px;

          @media (max-width: 1023px) {
            margin-bottom: 16px;
          }
        }

        .header-details {
          font-size: 22.4px;
          line-height: 31.36px;
          font-weight: 400;
          font-family: $regulerFont;
          letter-spacing: 0px;
          color: $color-White;
          margin-bottom: 45px;

          @media (max-width: 1023px) {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 25px;
          }
        }
      }

      .right-header-wrap {
        width: 50%;

        @media (max-width: 1023px) {
          text-align: center;
          width: 100%;
        }

        img {
          width: 100%;
          height: 100%;

          @media (max-width: 1023px) {
            width: auto;
            height: auto;
          }

          @media (max-width: 767px) {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  // soc features page

  .soc-features-wrap {
    max-width: 1602px;
    margin: -200px auto 61px;

    @media (max-width: 1023px) {
      margin: -352px auto 33px;
    }

    .soc-features-inner-wrap {
      // background-color: #F1F1F1;
      background-image: url("../../../assets/images/socSignupPage/socBKGround.png");
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 26px;
      margin: 0px 12px;

      .features-inner-wrap-details {
        padding: 46px 46px 60px 96px;

        @media (max-width: 1440px) {
          padding: 46px 46px 60px;
        }

        @media (max-width: 1260px) {
          padding: 46px 12px 60px;
        }

        @media (max-width: 1023px) {
          padding: 18px 12px;
        }

        .feature-details-wrap {
          display: flex;
          align-items: flex-start;
          column-gap: 40px;
          margin-top: 56px;

          .feature-details-left {
            width: 50%;

            .feature-card-wrap {
              display: grid;
              grid-template-columns: 50% 50%;
              column-gap: 9px;
              row-gap: 15px;
              cursor: pointer;

              .feature-card {
                padding: 9px;
              }
            }

            .active {
              background-color: #00376426;
              border-radius: 8px;
            }
          }

          .feature-details-right {
            width: 50%;

            img {
              width: 100%;
              height: auto;
            }
          }
        }

        .feature-title-details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 11px;

          @media (max-width: 1023px) {
            margin-bottom: 6px;
            justify-content: center;
          }

          .feature-title {
            font-size: 18px;
            line-height: 21px;
            font-weight: 700;
            font-family: $regulerFont;
            letter-spacing: 0px;
            color: $color-Black;

            @media (max-width: 1260px) {
              font-size: 14px;
              line-height: 22.4px;
            }

            @media (max-width: 1023px) {
              color: $color-Primary;
            }
          }
        }

        .feature-description {
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 400;
          font-family: $regulerFont;
          letter-spacing: 0px;
          color: $color-Black;
          max-width: 280px;

          @media (max-width: 1260px) {
            font-size: 14px;
            line-height: 18px;
          }

          @media (max-width: 1023px) {
            max-width: none;
            text-align: center;
          }
        }

        .feature-btn-wrap {
          margin-top: 36px;

          @media (max-width: 1023px) {
            text-align: center;
          }
        }

        .feature-slider {
          margin: 28px 0px 18px;

          .slider-card-details {
            .feature-img-wrap {
              max-width: 390px;
              height: 275px;
              margin: 0px auto;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          .slick-dots {
            bottom: -18px;

            li {
              width: 10px;
              height: 10px;
              margin: 0px 10px;

              button {
                padding: 0px;
                width: 10px;
                height: 10px;

                &::before {
                  width: 10px;
                  height: 10px;
                  color: #4455c77d;
                  opacity: 1;
                }
              }
            }

            .slick-active {
              button {
                &::before {
                  color: #4455c7;
                }
              }
            }
          }
        }
      }
    }
  }

  // how it work page css

  .how-it-work-wrap {
    margin-bottom: 75px;

    @media (max-width: 1023px) {
      padding: 0px 12px;
      margin-bottom: 30px;
    }

    .how-it-work-btn-wrap {
      text-align: center;
      margin: 27px 0px 45px;
    }

    .how-it-work-slider {
      .slick-active.slick-current {
        .slider-video-wrap {
          transform: scale(1);
          transition: all 2s;
          opacity: 1;
        }
      }

      .slick-active {
        .slider-video-wrap {
          transform: scale(0.8);
          opacity: 0.7;
          transition: all 2s;
        }
      }

      .slider-video-wrap {
        iframe {
          width: 100%;

          // @media (max-height:1023px) {
          //     height: 175px;
          // }
        }
      }

      .slick-dots {
        bottom: -140px;

        @media (max-width: 1023px) {
          bottom: -65px;
        }

        li {
          width: 20px;
          height: 20px;

          @media (max-width: 1023px) {
            width: 10px;
            height: 10px;
            margin: 0px 10px;
          }

          button {
            padding: 0px;
            width: 20px;
            height: 20px;

            @media (max-width: 1023px) {
              width: 10px;
              height: 10px;
            }

            &::before {
              width: 20px;
              height: 20px;
              color: #4455c77d;
              opacity: 1;
              font-size: 10px;

              @media (max-width: 1023px) {
                width: 10px;
                height: 10px;
                font-size: 8px;
              }
            }
          }
        }

        .slick-active {
          button {
            &::before {
              color: #4455c7;
            }
          }
        }
      }
    }

    .how-it-bottom-section {
      margin: 27px auto 0px;

      @media (max-width: 1023px) {
        width: 100% !important;
        margin: 12px auto 0px;
      }

      .text-details-wrap {
        .text-details {
          font-size: 18px;
          line-height: 22px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          letter-spacing: 0px;

          @media (max-width: 1023px) {
            font-size: 11.42px;
            line-height: 14px;
            text-align: center;
          }
        }
      }

      .arrow-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 29px;

        @media (max-width: 1023px) {
          margin-top: 15px;
        }

        .slide-button {
          width: 51px;
          height: 51px;
          border-radius: 50%;
          background-color: #003764;
          padding: 0px;
          min-width: auto;

          @media (max-width: 1023px) {
            width: 21px;
            height: 21px;
          }
        }

        .prev-slide-btn {
          svg {
            margin-right: 5px;

            @media (max-width: 1023px) {
              width: 12px;
              height: 12px;
              margin-right: 3px;
            }
          }
        }

        .next-slide-btn {
          svg {
            margin-left: 5px;

            @media (max-width: 1023px) {
              width: 12px;
              height: 12px;
              margin-left: 3px;
            }
          }
        }
      }
    }
  }

  // Odds Comparisons page css

  .odds-comparsion-wrap-section {
    background-image: url("../../../assets/images/socSignupPage/socBKGround.png");
    background-repeat: no-repeat;
    background-size: cover;

    .odds-comparsion-container {
      max-width: 1350px;
      margin: 0px auto;

      .odds-comparsion-wrap {
        padding: 68px 93px 67px;

        @media (max-width: 1260px) {
          padding: 68px 12px 67px;
        }

        @media (max-width: 1023px) {
          padding: 36px 12px 33px;
        }

        .signup-odds-comparsion-container {
          border-radius: 15px;
          background-color: $color-White;
          margin-top: 45px;

          @media (max-width: 1023px) {
            margin-top: 24px;
          }

          .signup-odds-comparsion-table {
            .MuiTableRow-head {
              .MuiTableCell-head {
                background-color: $color-Primary;
                color: $color-White;
                font-size: 22.4px;
                line-height: 27px;
                font-weight: 600;
                font-family: $regulerFont;
                letter-spacing: 0px;
                padding: 11px 23px 16px;

                &:not(:first-child) {
                  box-shadow: inset 6px 0px 6px -2px #0000002e;
                  text-align: center;
                }

                @media (max-width: 1023px) {
                  font-size: 14px;
                  line-height: 18px;
                  padding: 7px 12px 7px;
                }
              }
            }

            .MuiTableRow-root {
              .MuiTableCell-body {
                color: $color-Black;
                font-size: 18px;
                line-height: 21px;
                font-weight: 600;
                font-family: $regulerFont;
                letter-spacing: 0px;
                padding: 12px 23px 11px;
                border-bottom: none;

                @media (max-width: 1023px) {
                  font-size: 14px;
                  line-height: 18px;
                  padding: 10px 12px 9px;
                }

                &:not(:first-child) {
                  box-shadow: inset 6px 0px 6px -2px #0000002e;
                  text-align: center;
                }

                .odds-comparsion-btn {
                  padding: 10px 24px 9px;
                  border-radius: 8px;
                  font-size: 16px;
                  line-height: 22px;
                  font-weight: 600;
                  font-family: $regulerFont;
                  letter-spacing: 0px;
                  text-transform: none;
                  box-shadow: none;
                  width: max-content;

                  @media (max-width: 1023px) {
                    font-size: 14px;
                    line-height: 16px;
                    padding: 5px 9px 4px;
                  }
                }
              }
            }

            .even-odds-rows {
              &:nth-of-type(odd) {
                background-color: $color-White;
              }

              &:nth-of-type(even) {
                background-color: #e7e9ec59;
              }
            }
          }
        }

        .odds-comparsion-btn-wrap {
          text-align: center;
          margin-top: 36px;
        }
      }
    }
  }

  //  SOC Bronze page css

  .soc-bronze-wrap-section {
    background-image: url("../../../assets/images/socSignupPage/socBronze.png");
    background-repeat: no-repeat;
    background-size: cover;

    .soc-bronze-container {
      max-width: 863px;
      margin: 0px auto;

      .soc-bronze-wrap {
        padding: 75px 0px;

        @media (max-width: 1023px) {
          padding: 39px 12px;
        }

        .bronze-prize-section {
          text-align: center;
          margin-top: 80px;
          position: relative;

          @media (max-width: 1023px) {
            margin-top: 54px;
          }

          .prize-btn {
            font-size: 61.47px;
            line-height: 73px;
            font-weight: 700;
            font-family: $regulerFont;
            color: $color-Primary;
            letter-spacing: 0px;
            background-color: $color-White;
            border: 2px solid $color-Primary;
            box-shadow: none;
            border-radius: 11px;
            padding: 13px 41px;
            text-transform: none;
            align-items: baseline;

            @media (max-width: 1023px) {
              font-size: 43.9px;
              line-height: 61.47px;
              padding: 13px 29px;
            }

            span {
              font-size: 16px;
              line-height: 22.4px;
              color: #707070;
            }
          }

          .free-trial {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 13px 12px 12px;
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-White;
            letter-spacing: 0px;
            border-radius: 11px;
            background-color: $color-Primary;

            @media (max-width: 1023px) {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }

        .bronze-prize-btn-wrap {
          margin-top: 40px;
          text-align: center;

          @media (max-width: 1023px) {
            margin-top: 25px;
          }
        }
      }
    }
  }

  // soc Benefits page css

  .soc-benefits-wrap-section {
    background-color: $color-Black;

    .soc-benefits-container {
      max-width: 1374px;
      margin: 0px auto;

      .soc-benefits-wrap {
        padding: 71px 12px 250px;

        .soc-benefits-grid-details {
          display: grid;
          grid-template-columns: auto auto auto auto;
          column-gap: 30px;
          row-gap: 31px;
          margin-top: 50px;

          @media (max-width: 1023px) {
            grid-template-columns: auto auto;
            column-gap: 9px;
            row-gap: 15px;
            margin-top: 19px;
          }

          .soc-benefits-grid-item {
            background-color: #ffffffe6;
            border-radius: 11px;
            padding: 18px;

            @media (max-width: 1023px) {
              padding: 9px;
            }

            .soc-benefits-icon-wrap {
              width: 54px;
              height: 54px;
              margin-bottom: 19px;

              @media (max-width: 1023px) {
                width: 36px;
                height: 36px;
                margin-bottom: 10px;
              }

              img {
                width: 100%;
                height: auto;
              }
            }

            .soc-benefits-details {
              .name {
                font-size: 18px;
                line-height: 21px;
                font-weight: 700;
                font-family: $regulerFont;
                color: $color-Black;
                letter-spacing: 0px;
                margin-bottom: 16px;

                @media (max-width: 1023px) {
                  font-size: 14px;
                  line-height: 16px;
                  margin-bottom: 6px;
                }
              }

              .details {
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 400;
                font-family: $regulerFont;
                color: $color-Black;
                letter-spacing: 0px;

                @media (max-width: 1023px) {
                  font-size: 12px;
                  line-height: 17px;
                }
              }
            }
          }
        }

        .much-more-section {
          margin-top: 46px;
          text-align: center;

          @media (max-width: 1023px) {
            margin-top: 23px;
          }

          .much-more-header {
            font-size: 43.9px;
            line-height: 52px;
            font-weight: 700;
            font-family: $regulerFont;
            color: $color-White;
            letter-spacing: 0px;
            margin-bottom: 37px;

            @media (max-width: 1023px) {
              font-size: 22.4px;
              line-height: 27px;
              margin-bottom: 28px;
            }
          }
        }
      }
    }
  }

  // soc Download the SmartB app now page css

  .smartB-wrap-app-section {
    .smartB-wrap-app-container {
      max-width: 1374px;
      margin: -160px auto 0px;

      @media (max-width: 1023px) {
        margin: -205px auto 0px;
      }

      .container-wrap {
        background-color: $color-White;
        border-radius: 26px;
        background-image: url("../../../assets/images/socSignupPage/smartBAPP.png");
        background-repeat: no-repeat;
        background-position: right;
        margin: 0px 12px;
        z-index: 9;
        position: relative;
      }

      .container-wrap-mobile {
        background-color: $color-White;
        border-radius: 26px;
        margin: 0px 12px;
        z-index: 9;
        position: relative;
      }

      .smartB-wrap {
        padding: 65.5px 0px 59.5px 67px;

        @media (max-width: 1360px) {
          padding: 65.5px 0px 65.5px 30px;
        }

        @media (max-width: 1023px) {
          padding: 26px 0px 0px;
        }

        .download-app-text {
          font-size: 43.9px;
          line-height: 53px;
          font-weight: 600;
          font-family: $regulerFont;
          letter-spacing: 0px;
          max-width: 670px;
          margin-bottom: 28px;

          @media (max-width: 1360px) {
            font-size: 33.9px;
            line-height: 43px;
            max-width: 515px;
          }

          @media (max-width: 1023px) {
            font-size: 22.4px;
            line-height: 27px;
            max-width: none;
            margin-bottom: 9px;
            text-align: center;
          }
        }

        .download-details {
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          font-family: $regulerFont;
          letter-spacing: 0px;
          max-width: 610px;
          margin-bottom: 36px;

          @media (max-width: 1360px) {
            font-size: 16px;
            line-height: 22px;
            max-width: 445px;
          }

          @media (max-width: 1023px) {
            font-size: 14px;
            line-height: 18px;
            max-width: none;
            margin-bottom: 28px;
            text-align: center;
          }
        }

        .playstore-wrap {
          display: flex;
          align-items: center;
          column-gap: 24.4px;

          @media (max-width: 1023px) {
            justify-content: center;
          }

          .link-wrap {
            max-width: 180px;
            height: 55px;

            @media (max-width: 1023px) {
              max-width: 158px;
              height: 45px;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .smartb-app-bg {
          margin-top: 27px;
          border-radius: 26px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 26px;
            margin-bottom: -4px;
          }
        }
      }
    }
  }

  // soc testimonial page css

  .soc-testimonial-wrap-section {
    // background-image: url("../../../assets/images/socSignupPage/testimonialsBG.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -215px;

    @media (max-width: 1023px) {
      margin-top: -265px;
    }

    .soc-testimonial-container {
      max-width: 1374px;
      margin: 0px auto;

      .soc-testimonial-wrap {
        padding: 305px 12px 90px;
        margin: 0px 12px;

        @media (max-width: 1023px) {
          padding: 285px 0px 45px;
        }

        .testimonial-header {
          font-size: 43.9px;
          line-height: 52px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Black;
          letter-spacing: 0px;
          margin-bottom: 12px;
          text-align: center;

          @media (max-width: 1023px) {
            font-size: 22.4px;
            line-height: 27px;
            margin-bottom: 9px;
          }
        }

        .testimonial-details {
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
          letter-spacing: 0px;
          margin-bottom: 44px;
          text-align: center;

          @media (max-width: 1023px) {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 28px;
          }
        }

        .testimonial-slider-wrap {
          .slick-dots {
            bottom: -50px;

            @media (max-width: 1023px) {
              bottom: -25px;
            }

            li {
              width: 20px;
              height: 20px;

              @media (max-width: 1023px) {
                width: 10px;
                height: 10px;
                margin: 0px 10px;
              }

              button {
                padding: 0px;
                width: 20px;
                height: 20px;

                @media (max-width: 1023px) {
                  width: 10px;
                  height: 10px;
                }

                &::before {
                  width: 20px;
                  height: 20px;
                  color: #4455c77d;
                  opacity: 1;
                  font-size: 10px;

                  @media (max-width: 1023px) {
                    width: 10px;
                    height: 10px;
                    font-size: 8px;
                  }
                }
              }
            }

            .slick-active {
              button {
                &::before {
                  color: #4455c7;
                }
              }
            }
          }

          .testimonial-details-data {
            // max-width: 415px;
            height: 345px;
            border-radius: 18px;
            background-color: $color-Light-grey;
            margin: 0px 13px;

            @media (max-width: 1023px) {
              margin: 0px 4px;
              height: 267px;
            }

            .testimonial-details-wrap {
              padding: 30px 18px 0px;

              @media (max-width: 1023px) {
                padding: 15px;
              }


            }

            .inverted-comma {
              width: 68px;
              height: 68px;
              margin-bottom: 26px;

              @media (max-width: 1023px) {
                width: 56px;
                height: 56px;
                margin-bottom: 23px;
              }

              img {
                width: 100%;
                height: 100%;
              }
            }

            .testimonial-descriptions {
              font-size: 18px;
              line-height: 23.4px;
              font-weight: 400;
              font-family: $regulerFont;
              letter-spacing: 0px;
              color: $color-Black;
              margin-bottom: 36px;
              text-align: left;
              min-height: 115px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5;

              @media (max-width: 1023px) {
                font-size: 14px;
                line-height: 16px;
                min-height: 85px;
                margin-bottom: 10px;
              }
            }

            .testimonial-user-details {
              display: flex;
              align-items: center;
              column-gap: 15px;
              position: relative;
              width: 70%;
              padding: 15px 18px 10px;
              gap: 18px;
              background-color: white;

              @media (max-width: 1023px) {
                padding: 10px 12px 8px;
              }

              .white-curve-1 {
                position: absolute;
                top: -18px;
                left: 0;
                width: 18px;
                height: 18px;
                rotate: 180deg;
              }

              .user-img {
                width: 45px;
                height: 45px;
                border-radius: 50%;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  border-radius: 50%;
                }
              }

              .user-details-wrap {
                text-align: left;

                .user-name {
                  font-size: 16px;
                  line-height: 19px;
                  font-weight: 600;
                  font-family: $regulerFont;
                  color: $color-Black;
                  letter-spacing: 0px;
                  margin-bottom: 7px;

                  @media (max-width: 1023px) {
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 4px;
                  }
                }

                .user-designation {
                  font-size: 12px;
                  line-height: 15px;
                  font-weight: 400;
                  font-family: $regulerFont;
                  color: $color-Black;
                  letter-spacing: 0px;
                }
              }


            }

            .blank-box {
              position: relative;
              width: 30%;

              .curve {
                position: absolute;
                top: 0;
                left: -18px;
                width: 18px;
                height: 18px;
              }

              .white-curve-2 {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 18px;
                height: 18px;
                rotate: -180deg;
              }
            }
          }

          .slick-arrow {
            z-index: 9;

            &::before {
              contain: "";
              display: none;
            }
          }

          .slick-prev {
            left: 15px;
            top: auto;
            bottom: -40px;
          }

          .slick-next {
            right: 45px;
            bottom: -40px;
            top: auto;

            @media (max-width: 1023px) {
              right: 10px;
            }
          }

          .slick-disabled {
            opacity: 0.7;
          }

          .slide-button {
            width: 51px;
            height: 51px;
            border-radius: 50%;
            background-color: #003764;
            padding: 0px;
            min-width: auto;
            z-index: 9;

            @media (max-width: 1023px) {
              width: 21px;
              height: 21px;
            }
          }

          .prev-slide-btn {
            svg {
              margin-right: 5px;

              @media (max-width: 1023px) {
                width: 12px;
                height: 12px;
                margin-right: 3px;
              }
            }
          }

          .next-slide-btn {
            svg {
              margin-left: 5px;

              @media (max-width: 1023px) {
                width: 12px;
                height: 12px;
                margin-left: 3px;
              }
            }
          }

          .testimonial-arrow-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 29px;

            @media (max-width: 1023px) {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }

  // our partner page css

  .ourpartner-container {
    margin-top: 0px;

    @media (max-width: 799px) {
      margin: 30px 0px 0px;
    }
  }
}

.SOC-member-modal {
  .MuiDialog-paper {
    max-width: 1168px;
    width: 100%;
    margin: 12px;
    border-radius: 20px;
    overflow-y: visible;

    .close-icon {
      position: absolute;
      right: 0px;
      top: -40px;

      svg {
        path {
          fill: $color-White;
        }
      }
    }

    .SOC-member-modal-details {
      padding: 0px;

      .member-details-wrap {
        display: flex;
        align-items: flex-start;
        background-image: url("../../../assets/images/memberBG.png");
        background-size: cover;
        background-repeat: no-repeat;

        @media (max-width: 799px) {
          flex-direction: column;
        }

        .plan-details-wrap {
          width: 50%;

          @media (max-width: 799px) {
            width: 100%;
          }

          .membership-card-data {
            border-radius: 0px;
            background-image: none;
          }
        }

        .membership-from-details-wrap {
          width: 50%;

          @media (max-width: 799px) {
            width: 100%;
          }

          .your-details-wrap {
            box-shadow: none;
            border-radius: 0px;
            margin-bottom: 0px;
            width: calc(100% - 38px);

            @media (max-width: 799px) {
              width: calc(100% - 20px);
            }
          }

          .member-other-details-wrap {
            .your-selection-wrap {
              box-shadow: none;
              border-radius: 0px;
              margin-bottom: 0px;
            }
          }
        }

        .membership-payment-details {
          border-radius: 0px;
          margin: 0px;
          box-shadow: none;
        }

        .membership-button-wrap {
          position: absolute;
          bottom: 0px;
          left: 0px;
          padding: 23px;
          width: calc(50% - 52px);
          background-color: #314caa;

          @media (max-width: 799px) {
            position: initial;
            background-color: $color-White;
            width: calc(100% - 24px);
            padding: 18px;
          }

          .membership-get-btn {
            background-color: $color-White;
            color: $color-Accent-1;

            @media (max-width: 799px) {
              background-color: $color-Accent-1;
              color: $color-White;
            }
          }

          .subscription-warning {
            color: $color-White;

            @media (max-width: 799px) {
              color: #989898;
            }
          }
        }
      }
    }
  }
}