@import "../../../assets/scss/variable.scss";

.public-comp-tips-wrap {
  background-color: $color-off-White;

  .comp-tips-header {
    padding: 46px 18px 3px;
    margin-top: -5px;

    @media (max-width: 799px) {
      padding: 18px 12px 4px;
    }

    h1 {
      color: $color-Black;
      line-height: 56px;
    }

    .bredcrumn-deatils {
      overflow-x: scroll;

      .bredcrumn-wrap {
        margin-bottom: 3px;
        min-width: max-content;

        li.MuiBreadcrumbs-separator {
          color: $color-Black;
        }

        li {
          font-size: 11.42px;
          line-height: 14px;

          p {
            font-size: 11.42px;
            line-height: 14px;
            color: $color-Accent-1;
            text-transform: uppercase;
          }

          a {
            color: $color-Black;
            font-size: 11.42px;
            line-height: 14px;
            text-transform: uppercase;
          }
        }
      }
    }

    .bredcrumn-deatils::-webkit-scrollbar {
      height: 0px;
    }

    .bredcrumn-deatils::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  .comp-detail-header {
    margin-top: -5px;
    background-image: url("../../../assets/images/comp-info-banner.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    padding: 15px 46px 21px 46px;
    position: relative;
    display: flex;
    background-color: $color-dark-Blue;
    column-gap: 45px;
    align-items: start;

    @media (max-width: 1056px) {
      display: block;
    }

    @media (max-width: 700px) {
      padding: 12px;
    }

    .comp-info-left {
      width: 50%;

      @media (max-width: 1056px) {
        width: 100%;
      }

      //   $arialFont
      .title {
        font-family: $arialFont !important;
        color: $color-White;
        font-size: 31.36px;
        line-height: 38px;
        font-weight: 400;

        @media (max-width: 1170px) {
          font-size: 24px;
        }
      }

      .about-comp {
        font-family: $arialFont !important;
        color: $color-White;
        font-size: 16px;
        margin-top: 6px;
        line-height: 22.4px;
        word-wrap: break-word;
        max-width: 515px;

        @media (max-width: 1170px) {
          font-size: 14px;
        }
      }
    }

    .comp-info-right {
      display: flex;
      align-items: center;
      width: 50%;
      column-gap: 20px;
      //   word-wrap: break-word;
      //   max-width: 300px !important;

      @media (max-width: 1056px) {
        width: 100%;
        justify-content: center;
        margin-top: 20px;
      }

      .info-text {
        font-family: $arialFont !important;
        color: $color-White;
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;

        @media (max-width: 1170px) {
          font-size: 12px;
        }
      }

      .fw-600 {
        font-weight: 600;
      }

      .info-left {
        width: 38%;
      }

      .info-right {
        width: 62%;
      }
    }

    .comp-image {
      position: absolute;
      left: 46px;
      bottom: -81px;
      text-transform: capitalize;
      height: 160px;
      width: 160px;
      border-radius: 50%;
      border: 3px solid $color-White;

      @media (max-width: 1056px) {
        position: unset;
        left: unset;
        bottom: unset;
      }

      @media (max-width: 600px) {
        height: 140px;
        width: 140px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        object-position: center;
      }
    }
  }

  .tip-share-option {
    // text-align: center;
    padding: 41px 20px 21.5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 799px) {
      padding: 31px 12px 18px;
    }

    .share-header-box {
      margin-bottom: 10px;
      display: flex;
      justify-content: start;
      align-items: center;

      @media (max-width: 599px) {
        margin-bottom: 0px;
      }

      .text-blue {
        color: $color-Accent-1 !important;
        margin-right: 6px;
      }

      .share-header {
        font-size: 31.36px;
        line-height: 43.9px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-Black;

        @media (max-width: 599px) {
          font-size: 22.4px;
          line-height: 24px;
        }

        @media (max-width: 450px) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .time-header {
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-gray-dark;

        @media (max-width: 460px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }

      // @media (max-width: 600px) {
      //   display: block;
      // }
    }

    .comp-share-btn-wrap {
      text-align: center;
      // margin-top: 13px;

      .share-btn {
        border: 1px solid $color-Accent-1;
        max-width: 177px;
        width: 100%;
        border-radius: 8px;
        text-transform: capitalize;

        .MuiButton-label {
          font-size: 16px;
          color: $color-Accent-1;
        }
      }

      .share-tooltip-icon {
        // @media (max-width: 479px) {
        //   width: 50%;
        // }
      }

      .share-icon-box {
        padding: 3px 6px 0px 6px;
        border: 1px solid $color-Accent-1;
        border-radius: 8px;

        @media (max-width: 560px) {
          padding: 2px 5px 0px 5px;
        }

        .share-icon {
          height: 29px;
          width: 29px;
        }
      }

      .comp-custom-tooltip {
        position: relative;

        .tooltip {
          top: 55px;
          left: -60px;
          // left: -15px;
          width: max-content;
          padding: 12px !important;

          @media (max-width: 1023px) {
            left: -100px;
          }

          @media (max-width: 799px) {
            left: 110px;
          }

          @media (max-width: 560px) {
            left: 88px;
          }

          // @media (max-width: 799px) {
          //   left: 34px;

          //   padding: 8px !important;
          // }

          // @media (max-width: 560px) {
          //   left: 42px;
          // }

          .tooltip-arrow {
            top: -20px;
            right: 30px;
            left: auto;
            transform: rotate(180deg);

            @media (max-width: 799px) {
              right: 0px;
            }
          }

          .share-option {
            position: relative;
            max-width: 551px;
            width: 100%;
            margin: 0 auto;
            column-gap: 20px;

            @media (max-width: 1048px) {
              justify-content: center;
              max-width: 296px;
              row-gap: 11px;
              margin-top: 12px;
            }

            .copy-link {
              cursor: pointer;
            }

            .jf-start {
              justify-content: start !important;
            }

            .social-box {
              display: flex;
              align-items: center;
              column-gap: 10px;

              justify-content: space-between;
            }

            .option-name {
              font-family: $regulerFont;
              font-size: 11.42px;
              line-height: 15px;
              color: #5d5d5d;

              @media (max-width: 560px) {
                font-size: 8px;
              }
            }

            .react-share__ShareButton {
              width: 32px;
              height: 32px;

              img {
                height: 100%;
                width: 100%;
              }
            }

            .social-icon {
              cursor: pointer;
            }
          }

          .social-option-box {
            display: grid;
            grid-template-columns: auto auto auto auto;
            column-gap: 10px;
            row-gap: 10px;

            .option {
              text-align: center;
            }

            .option-name {
              font-family: $regulerFont;
              font-size: 11.42px;
              line-height: 15px;
              color: #5d5d5d;

              @media (max-width: 560px) {
                font-size: 11.42px;
              }
            }
          }
        }
      }

      .right-sec {
        .share-btn {
          padding: 11px 24px 12px 24px;
          background-color: $color-White;
          color: $color-Accent-1;
          border: 1px solid $color-Accent-1;
          font-size: 16px;
          line-height: 21px;
          font-weight: 400;
          font-family: "Inter", sans-serif;
          border-radius: 8px;
          text-transform: capitalize;
          cursor: pointer;

          @media (max-width: 900px) {
            padding: 11px 14px 12px 14px;
          }

          @media (max-width: 799px) {
            padding: 11px 24px 12px 24px;
          }

          @media (max-width: 650px) {
            font-size: 11.45px;
            padding: 12px 12px 12px 12px;
          }
        }
      }
    }

    .round-info {
      margin-top: 9px;
      font-family: $regulerFont !important;
      color: #5d5d5d;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 400;
      text-align: center;

      @media (max-width: 1056px) {
        font-size: 14px;
      }

      @media (max-width: 500px) {
        font-size: 12px;
      }
    }

    .share-option {
      margin-top: 27px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      max-width: 551px;
      width: 100%;
      margin: 0 auto;
      column-gap: 20px;
      margin-bottom: 15px;

      @media (max-width: 1048px) {
        justify-content: center;
        max-width: 296px;
        row-gap: 11px;
        margin-top: 12px;
      }

      @media (max-width: 799px) {
        margin-top: 18px !important;
        margin-bottom: 0px;
      }

      .copy-link {
        cursor: pointer;
      }

      .option-name {
        font-family: $regulerFont;
        font-size: 11.42px;
        line-height: 15px;
        color: #5d5d5d;
      }

      .react-share__ShareButton {
        width: 32px;
        height: 32px;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .social-icon {
        cursor: pointer;
      }
    }
  }

  .padding-container {
    padding: 18px 33px;
    margin-top: 20px;
    // background-color: transparent !important;
    // box-shadow: unset !important;

    @media (max-width: 1250px) {
      padding: 18px 12px;
    }

    @media (max-width: 500px) {
      padding: unset;
    }
  }

  .page-deatils-wrap {
    // margin-top: 20px;
    // padding: 18px 33px 84px 33px;
    // padding-bottom: 9px;
    background-color: #e7e9ec;
    // background-color: $color-White;
    // box-shadow: 0px 3px 9px 0px #0000000d;

    // @media (max-width: 940px) {
    //   overflow: scroll;
    //   // padding: 16px 13px 16px 13px;
    // }

    @media (max-width: 1056px) {
      margin-top: 12px;
      background-color: transparent;
    }

    .tips-sec {
      margin-top: 15px;
      padding: 18px 33px;
      background-color: $color-White;
      border-radius: 8px;
      box-shadow: 0px 3px 9px 0px #0000000d;

      @media (max-width: 1110px) {
        padding: 17px 15px 18px 15px;
      }

      @media (max-width: 1110px) {
        padding: 4px 5px 6px 5px;
      }

      @media (max-width: 500px) {
        border-radius: 0px;
      }

      &:first-child {
        margin-top: 0px;
      }

      .title-date-sec {
        text-align: center;
        margin-bottom: 5px;

        .title-date {
          color: $color-Black;
          font-size: 16px;
          line-height: 21px;
          font-weight: 500;
          font-family: $regulerFont;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 900px) {
            font-size: 14px;
          }

          @media (max-width: 600px) {
            font-size: 11.42px;
            line-height: 15px;
            font-weight: 400;
          }

          .market-box {
            display: flex;
            align-items: center;

            .market {
              border: 1px solid #c9c9c9;
              padding: 2px 6px;
              border-radius: 5px;
              margin-left: 4px;

              @media (max-width: 600px) {
                padding: 0px 6px;
              }
            }
          }
        }
      }

      .comp-tip-data-sec {
        border-top: 1px solid $color-Primary;
        padding-top: 3px;

        .time-venue-txt {
          text-align: center;

          @media (max-width: 600px) {
            font-size: 11.42px;
          }
        }

        .comp-odds-tips-sec {
          padding: 18px 86px 21px 86px;
          // width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 1110px) {
            column-gap: 2px;
            padding: 18px 0px 2px 0px;
          }

          @media (max-width: 600px) {
            column-gap: 2px;
            padding: 4px 0px 2px 0px;
          }

          // &:last-child {
          //   padding-bottom: 0px !important;
          // }
          .vs-txt {
            font-family: $primaryFont;
            font-size: 16px;
            line-height: 23px;
            font-weight: 400;
            color: $color-Black;
          }

          .flex-rr {
            flex-direction: row-reverse !important;
          }

          .jc-start {
            @media (max-width: 799px) {
              justify-content: start;
            }
          }

          .jc-end {
            @media (max-width: 799px) {
              justify-content: end;
            }
          }

          .left-sec {
            display: flex;
            align-items: center;
            column-gap: 30px;
            width: 45%;

            // @media (max-width: 1110px) {
            // display: block;
            // width: 90%;
            // }
            @media (max-width: 900px) {
              column-gap: 10px;
            }

            @media (max-width: 600px) {
              column-gap: 5px;
            }

            .team-wrap {
              display: flex;
              // flex-direction: column;
              align-items: center;
              row-gap: 5px;

              @media (max-width: 700px) {
                column-gap: 5px;
              }

              .team-img-wrap {
                width: 65px;
                height: 65px;

                border-radius: 50%;

                @media (max-width: 1250px) {
                  width: 55px;
                  height: 55px;
                }

                @media (max-width: 900px) {
                  width: 45px;
                  height: 45px;
                }

                @media (max-width: 500px) {
                  width: 32px;
                  height: 32px;
                  row-gap: unset;
                }

                .team-img {
                  // width: 100%;
                  // height: 100%;
                  width: 65px;
                  height: 65px;
                  border-radius: 50%;
                  object-fit: contain;

                  @media (max-width: 1250px) {
                    width: 55px;
                    height: 55px;
                  }

                  @media (max-width: 900px) {
                    width: 45px;
                    height: 45px;
                  }

                  @media (max-width: 500px) {
                    width: 32px;
                    height: 32px;
                    row-gap: unset;
                  }
                }
              }
            }

            .odd-rr {
              @media (max-width: 1150px) {
                flex-direction: row-reverse !important;
              }
            }

            .odds-wrap {
              display: flex;
              flex-direction: column;
              align-items: center;
              row-gap: 5px;

              @media (max-width: 1150px) {
                flex-direction: row;
                justify-content: center;
                column-gap: 8px;
                margin-top: 10px;
              }

              .odds-btn {
                display: block;
                background: #d6d9f3;
                border-radius: 6px;
                width: 80px;
                max-width: 100%;
                text-align: center;
                padding: 8px;

                @media (max-width: 560px) {
                  width: 45px;
                }
              }

              .odd-img {
                max-width: 33px;
                border-radius: 6px;
                cursor: pointer;
                height: 33px;
                object-fit: contain;
              }
            }

            .blue-score {
              background-color: #003764;
              width: 63px;
              height: 58px;
              display: flex;
              align-items: center;
              justify-content: center;

              @media (max-width: 1110px) {
                width: 100%;
                margin-top: 12.1px;
                height: 36px;
              }

              .score {
                font-family: $regulerFont;
                font-size: 16px;
                line-height: 21px;
                font-weight: 400;
                color: $color-White;
                text-align: center;
              }
            }

            .check-box-sec {
              text-align: center;

              .MuiCheckbox-root {
                height: 58px;
                width: 58px;

                @media (max-width: 1150px) {
                  height: 34px;
                  width: 34px;
                }

                @media (max-width: 700px) {
                  height: 28px;
                  width: 28px;
                }
              }
            }

            .odds-wrap {
              display: flex;
              flex-direction: column;
              align-items: center;
              row-gap: 5px;

              @media (max-width: 1150px) {
                flex-direction: row;
                justify-content: center;
                column-gap: 8px;
                margin-top: 10px;
              }

              .odds-btn {
                display: block;
                background: #d6d9f3;
                border-radius: 6px;
                width: 80px;
                max-width: 100%;
                text-align: center;
                padding: 8px;
              }

              .odd-img {
                max-width: 33px;
                border-radius: 6px;
                cursor: pointer;
                height: 33px;
                object-fit: contain;
              }
            }

            .comp-name-tip-wrap {
              display: flex;
              align-items: center;
              height: 40px;

              .team-name {
                width: 120px;
                text-align: center;
                font-family: $primaryFont;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                color: $color-Black;

                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                // @media (max-width: 1110px) {
                //   width: max-content;
                // }

                @media (max-width: 900px) {
                  font-size: 15px;
                }

                @media only screen and (max-width: 599px) {
                  min-width: 80px;
                  width: fit-content;
                }

                @media only screen and (max-width: 400px) {
                  min-width: 60px;
                  width: fit-content;
                }
              }

              .comp-smartbook-tooltip {
                width: 185px;
                transform: translateX(-118%);
                left: 122px;
                top: 36px;

                .comp-smartbook-tooltip-arrow {
                  left: initial;
                  right: 50px;
                  transform: rotate(540deg);
                  top: -9px;
                }
              }
            }
          }

          // .right-sec {}
        }

        .no-result {
          justify-content: space-around;
        }

        .margin-sec {
          text-align: center;

          .margin-txt {
            color: $color-Black;
            font-size: 16px;
            line-height: 21px;
            font-weight: 500;
            font-family: $regulerFont;
          }
        }

        .counter-score-sec {
          padding-top: 15px;
          padding-bottom: 18px;
          max-width: 183px;
          width: 100%;
          margin: 0 auto;

          .counter-input {
            // margin-top: 15px;
            display: flex;
            //   border: 1px solid #c9c9c9;
            border-radius: 8px;
            max-height: 36px;

            // column-gap: 3px;
            border: 1px solid #c9c9c9;
            overflow: hidden;

            .counter-input__button {
              padding: 10px 10px;
              cursor: pointer;
              min-width: 36px;
              color: #191919;
              border-left: 0px;
              border-radius: 0px;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              text-transform: capitalize;
              font-size: 16px;
              line-height: 22.4px;
              font-weight: 400;
              // background-color: #e8eaec;
              //   background-color: #00000042;

              .MuiButton-startIcon {
                margin-left: 0px !important;
                margin-right: 0px !important;
              }

              .MuiButton-root {
                min-width: 36px !important;
              }

              @media (max-width: 540px) {
                font-size: 12px;
              }
            }

            .plus-btn {
              border-top-right-radius: 8px !important;
              border-bottom-right-radius: 8px !important;
              border-top-left-radius: 0px !important;
              border-bottom-left-radius: 0px !important;
            }

            .counter-input__content {
              border-left: 1px solid #c9c9c9;
              border-right: 1px solid #c9c9c9;
              font-family: $regulerFont;
              font-size: 16px;
              line-height: 20px;
              font-weight: 600;
              padding: 8px 26px;

              color: $color-Black;
              width: 100%;
              text-align: center;
            }

            .margin-textfield {
              .MuiInputBase-root {
                height: 36px;

                input {
                  padding: 9px 10px;
                  text-align: center;
                  border-left: 1px solid #c9c9c9;
                  border-right: 1px solid#c9c9c9;
                }

                fieldset {
                  border: none;
                }
              }
            }

            @media only screen and (max-width: 479px) {
              grid-column-start: 1;
              grid-column-end: 4;
            }
          }
        }
      }
    }

    .update-tips-btn-sec {
      margin-top: 31px;
      width: 100%;
      text-align: center;

      .submit-tips-btn {
        max-width: 170px;
        width: 100%;
        padding: 12px 24px;
        border-radius: 8px;
        font-size: 16px;
        color: $color-White;
        line-height: 20px;
        font-family: $regulerFont;
        background-color: $color-Accent-1;

        .MuiButton-label {
          text-transform: initial;
        }

        @media (max-width: 599px) {
          // max-width: unset;
          width: 100%;
        }
      }
    }

    .joker-box {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      box-shadow: 10px 10px 5px 0px #0000000f;
      -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
      -moz-box-shadow: 10px 10px 5px 0px #0000000f;

      .top-sec {
        text-align: center;
        background-color: #e8eaec;
        padding: 20px 0px 5px 0px;

        .joker-txt {
          color: $color-Black;
          font-size: 16px;
          line-height: 18px;
          font-weight: 600;
          font-family: $arialFont;
        }
      }

      .bottom-sec {
        background-color: $color-White;
        display: flex;
        align-items: center;
        padding: 21px 0px 24px 0px;
        max-width: 520px;
        width: 100%;
        margin: 0 auto;
        column-gap: 13px;

        .select-txt {
          color: $color-Primary;
          font-size: 16px;
          line-height: 18px;
          font-weight: 400;
          font-family: $arialFont;
        }

        .detail-txt {
          margin-top: 9px;
          color: $color-gray-dark;
          font-size: 16px;
          line-height: 18px;
          font-weight: 400;
          font-family: $arialFont;
        }
      }
    }

    .margin-error {
      margin-top: 5px;
      text-align: center;

      .error {
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        font-family: $regulerFont;
        color: $color-Negative;
      }
    }

    .clear-all-box {
      // padding: 11px 33px 0px 5px;
      margin-top: 9px;

      .clear-all {
        text-align: center;
        text-transform: capitalize;
        color: #4455c7;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: none;
        text-decoration: underline;
        font-size: 14px;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.img-blur {
  filter: blur(3px);
}