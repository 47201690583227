@import "../../../../assets/scss/variable.scss";

.news-landing-page-wrap {
  .news-team-select-wrap {
    @media (max-width:799px) {
      width: 100%;
    }

    .tournament-team {

      @media (max-width:799px) {
        row-gap: 12px;
      }

      .series-select {
        margin-top: 0px;

        .select__control {
          @media (max-width:799px) {
            max-width: none;
          }
        }
      }
    }
  }
}