@import "../../../../../src/assets/scss/variable.scss";

.payment-card-details {
  .details-textfield {
    min-width: 269px;
    width: 100%;

    @media (max-width: 490px) {
      min-width: none;
      width: 100%;
      margin-top: 5px;
    }

    .MuiOutlinedInput-root {
      border-radius: 8px;
      font-family: "Inter", sans-serif;

      .MuiOutlinedInput-input {
        padding: 13px 11px;
      }
    }

    .MuiInputLabel-outlined {
      transform: translate(14px, 14px) scale(1);
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
      // color: $color-Accent-1;
    }

    .MuiInputBase-root {
      background-color: $color-White !important;
      border-radius: 8px;
    }

    .MuiInputLabel-shrink.Mui-focused {
      color: $color-Accent-1;
    }

    .Mui-focused {
      // color: $color-Accent-1;
      .MuiOutlinedInput-notchedOutline {
        border-color: #4455c7;
        border-width: 1;
      }
    }

    svg {
      cursor: pointer;
    }
  }
}
