@import "../../../../../../assets/scss/variable.scss";

.landing-news-section {
  margin-bottom: 50px;

  @media (max-width: 1080px) {
    margin-bottom: 30px;
  }

  .landing-news-img {
    min-width: 197px !important;
    max-width: 197px !important;
    height: 103px !important;
  }

  .individual-single-news {
    margin-bottom: 0px;
  }
}
