@import "../../../../../assets/scss/variable.scss";

.odds-status-modal {

    .MuiDialog-paper {
        max-width: 816px !important;


        .odds-status-modal-details {
            .tote-table-wrap {

                .tote-details-table {
                    height: 705px;
                    overflow-y: auto;

                    .tote-table {
                        border: 1px solid #D4D6D9;
                        border-collapse: collapse;

                        .MuiTableHead-root {

                            .MuiTableCell-head {
                                padding: 1px 0px;
                                font-size: 10px;
                                line-height: 12px;
                                font-weight: 600;
                                font-family: $regulerFont;
                                color: $color-Black;
                                letter-spacing: 0px;
                                text-align: center;
                                border-bottom: 1px solid #D4D6D9;
                                background-color: #E8EAEC;
                            }

                            .MuiTableCell-stickyHeader {
                                top: -1px;
                            }

                            .sorting-cell {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                column-gap: 5px;
                                cursor: pointer;

                                .sorting-arrow-wrap {
                                    display: flex;
                                    flex-direction: column;
                                    row-gap: 2px;
                                    cursor: pointer;

                                    svg {
                                        width: 5.93px;
                                        height: 3.49px;

                                    }

                                    .active {
                                        path {
                                            fill: $color-Accent-1;
                                        }
                                    }

                                    .down-arrow {
                                        transform: rotate(180deg);
                                    }
                                }
                            }

                            .sorting-cell-bookmakers {
                                justify-content: flex-start;
                                padding: 1px 0px 1px 12px;
                            }
                        }

                        .MuiTableBody-root {

                            .MuiTableCell-body {
                                padding: 1px 8px;
                                font-size: 12px;
                                line-height: 15px;
                                font-weight: 400;
                                font-family: $regulerFont;
                                color: $color-Black;
                                letter-spacing: 0px;
                                text-align: center;
                                border-bottom: 1px solid #D4D6D9;
                            }

                            .bookkeeper-wrap {
                                display: flex;
                                align-items: center;
                                column-gap: 9px;
                            }

                            .bookkeeper-icon {
                                width: 31px;
                                height: 31px;
                                min-width: 31px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .bookkeeper-name {
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: 400;
                                font-family: $regulerFont;
                                letter-spacing: 0px;
                                color: $color-Black;

                                @media (max-width:799px) {
                                    font-size: 11.42px;
                                    line-height: 15px;
                                }
                            }

                            .status-code {
                                padding: 3px 9px;
                                border-radius: 8px;
                                width: fit-content;
                                text-align: center;
                                margin: 0px auto;

                                span {
                                    font-size: 12px;
                                    line-height: 15px;
                                    font-weight: 500;
                                    letter-spacing: 0px;
                                    font-family: $regulerFont;
                                    text-transform: none;
                                }
                            }

                            .available-status-code {
                                background-color: #79c3a826;

                                .available {
                                    color: #78C2A7;
                                }
                            }

                            .not-status-code {
                                background-color: #ff000026;

                                .not-available {
                                    color: #FF0000;
                                }
                            }

                            .mobile-bookkeeper-list {
                                @media (max-width:799px) {
                                    display: flex;
                                    align-items: flex-start;
                                    flex-direction: column;
                                }

                                .bookkeeper-updatedAt {
                                    font-size: 11.42px;
                                    line-height: 15px;
                                    font-weight: 400;
                                    font-family: $regulerFont;
                                    color: $color-gray-dark;
                                    letter-spacing: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}