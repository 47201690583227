.dynamic-news-content {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;

    p {
        margin-bottom: 1rem;
        line-height: 1.6;
        font-size: 16px;
    }

    // .tweet-embed-container {
    //     margin: 1.5rem auto;
    //     display: flex;
    //     justify-content: center;
    //     max-width: 550px;
    // }

    .spotify-embed-container {
        margin: 1.5rem 0;
        width: 100%;
        max-width: 100%;

        iframe {
            max-width: 100%;
            margin: 0 auto;
            display: block;
        }
    }

    .iframe-container {
        margin: 1.5rem 0;
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 aspect ratio */
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute !important;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .image-container {
        margin: 1.5rem 0;
        text-align: center;

        img {
            max-width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
        }
    }

    blockquote {
        margin: 1.5rem 0;
        padding: 1rem;
        border-left: 4px solid #e0e0e0;
        background-color: #f5f5f5;
    }

    em {
        font-style: italic;
        font-weight: 500;
    }
}