@import "../../../../../../assets/scss/variable.scss";

.landing-home-tipping-comp {
  margin-bottom: 50px;

  @media (max-width: 1080px) {
    margin-bottom: 30px;
  }

  .landing-home-tipping-comp-header {
    margin-bottom: 12px;
  }
}
