@import "../../../../../../assets/scss/variable.scss";

.landing-expert-tips-slider-wrap {
  margin-bottom: 50px;

  // @media (max-width: 1080px) {
  //   margin-bottom: 30px;
  // }

  .hander-text {
    font-size: 31.36px;
    line-height: 40px;
    font-weight: 400;
    font-family: $primaryFont;
    color: $color-Primary;
    letter-spacing: 0px;

    @media (max-width: 1080px) {
      font-size: 22.4px;
      line-height: 28px;
    }
  }

  .experts-tips-slider-details {
    width: 269px !important;

    .slider-img {
      height: 150px !important;
    }
  }
}
