@import "../../../../assets/scss/variable.scss";


.homepage-landing-page-wrap {
    @media (max-width:1023px) {
        margin-top: 12px;
    }

    .homepage-landing-header {
        display: flex;
        align-items: center;
        column-gap: 9px;
        padding-bottom: 15px;
        border-bottom: 1px solid $color-grey;


        @media (max-width: 559px) {
            flex-direction: column-reverse;
            row-gap: 8px;
        }

        .team-select-wrap {
            max-width: 257px;
            width: 100%;

            @media (max-width:559px) {
                max-width: 100%;
            }
        }
    }

    .new-sport-header {
        justify-content: space-between;

        @media (max-width:1440px) {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 18px;
        }
    }

    .left-section {
        display: flex;
        align-items: center;
        width: calc(100% - 870px);
        column-gap: 9px;

        @media (max-width:1440px) {
            width: 100%;
        }
    }

    .fanstasy-section {
        background: linear-gradient(180deg, rgba(0, 55, 100, 1) 0%, rgba(9, 11, 13, 1) 100%);
        border-radius: 8px;
        padding: 20px 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        column-gap: 18px;


        @media (max-width:799px) {
            width: calc(100% - 24px);
            padding: 15px 12px;
            column-gap: 5px;
        }

        .fanstasy-left-section {
            display: flex;
            align-items: center;
            column-gap: 32px;

            @media (max-width:799px) {
                flex-direction: column;
                align-items: flex-start;
                row-gap: 12px;
            }

            .h-line {
                width: 1px;
                height: 87px;
                background-color: #4C7292;

                @media (max-width:799px) {
                    display: none;
                }
            }

            .text-wrap {
                max-width: 348px;

                .fanstasy-text {
                    font-size: 16px;
                    line-height: 22.4px;
                    font-weight: 400;
                    font-family: $regulerFont;
                    color: $color-White;

                    @media (max-width:799px) {
                        font-size: 11.42px;
                        line-height: 14px;
                    }
                }
            }
        }

        .fanstasy-right-section {
            width: 116px;
            // padding: 7px 16px;
            // background-color: $color-Secondary;
            // border-radius: 8px;

            img {
                width: 100%;
                height: 100%;
            }

            // p {
            //     font-size: 27px;
            //     line-height: 23px;
            //     color: $color-White;
            //     font-family: $GarageGothicBold;
            //     font-weight: 700;
            //     letter-spacing: 0px;
            // }
        }
    }

    .homepage-landing-content {
        margin-top: 36px;
        display: flex;
        align-items: flex-start;
        column-gap: 23px;

        .homepage-left-side {
            width: calc(100% - 418px);
            /* 418px rightside + column gap :- 23px */

            @media (max-width:1080px) {
                width: 100%;
            }

        }

        .homepage-right-side {
            width: 395px;
        }
    }

}

.bottom-sheet-wrap {
    position: fixed;
    bottom: 0px;
    z-index: 99;
    right: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer;
}

.homepage-bottom-sheet {

    .MuiDrawer-paper {
        height: calc(100vh - 100px);
        border-radius: 16px 16px 0px 0px;


        .bottom-sheet-content-wrap {

            .bottom-sheet-top-content {
                background-color: $color-Primary;
                border-radius: 16px 16px 0px 0px;
                text-align: center;

                .team-sports-tab {
                    padding: 5px 12px 10px;

                    .teamsport-tab-detail {
                        border-bottom: 2px solid #4455c7;

                        .MuiTabs-scroller {
                            .MuiTabs-indicator {
                                display: none;
                            }

                            .active {
                                border-bottom: 3px solid $color-White;
                            }
                        }

                        @media (max-width: 799px) {
                            margin-top: 6px;
                        }



                        .MuiTab-textColorInherit {
                            opacity: 1;
                        }

                        .tab-label {
                            display: flex;
                            align-items: center;

                            .label-name {
                                font-size: 16px;
                                font-family: $primaryFont;
                                color: #C2CDD6;
                                line-height: 21px;
                                font-weight: 400;
                            }
                        }

                        .active {
                            .label-name {
                                color: $color-White;
                                font-weight: 500;
                            }
                        }

                        .MuiBox-root {
                            min-width: max-content;
                        }

                        .MuiTab-root {
                            min-width: max-content;
                            border-bottom: 3px solid transparent;
                        }

                        .MuiTabs-indicator {
                            height: 3px;
                            color: $color-White;
                        }

                        .MuiTouchRipple-root {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}