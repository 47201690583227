@import "../../../../assets/scss/variable.scss";

.verify-your-identity-wrap {

  .verify-useredit-details {
    display: flex;
    align-items: center;
    column-gap: 30px;
    margin-bottom: 23px;

    h4 {
      margin-bottom: 0px;
    }
  }

  .verified-btn {
    border-radius: 8px;
    padding: 10px 12px 9px;
    background-color: $color-Affirmative;
    color: $color-White;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    font-family: $regulerFont;
    letter-spacing: 0px;
    text-transform: capitalize;

    &:hover {
      background-color: $color-Affirmative;
      box-shadow: none;
    }

    @media (max-width:767px) {
      margin-bottom: 16px;
    }
  }

  .process-btn {
    border-radius: 8px;
    padding: 10px 12px 9px;
    background-color: $color-Accent-3;
    color: $color-White;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    font-family: $regulerFont;
    letter-spacing: 0px;
    text-transform: capitalize;

    &:hover {
      background-color: $color-Accent-3;
      box-shadow: none;
    }

    @media (max-width:767px) {
      margin-bottom: 16px;
    }
  }

  .text-success-100 {
    color: $color-Affirmative;
  }

  .text-black-100 {
    color: $color-Black;
  }

  .fw-600 {
    font-weight: 600;
  }

  .verification-title-wrapper {
    display: flex;
    align-items: flex-start;
    column-gap: 9px;

    .left-verification-section {

      .text-header {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        font-family: $regulerFont;
        color: $color-Black;
        letter-spacing: 0px;
        margin-bottom: 13px;

        @media (max-width:799px) {
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 6px;
        }
      }

      .text-sub {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-Black;
        letter-spacing: 0px;

        @media (max-width:799px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }

      .mb-18 {
        margin-bottom: 18px;

        @media (max-width:799px) {
          margin-bottom: 9px;
        }
      }
    }
  }

  .verification-score-container {
    background-color: #F7F7F8;
    padding: 18px;
    border-radius: 8px;
    margin: 21px 0px 18px;

    .verification-score-inner-container {
      display: flex;
      justify-content: space-between;

      .verification-text {
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;
        letter-spacing: 0px;

        @media (max-width:799px) {
          font-size: 14px;
          line-height: 16px;
        }
      }

      .text-xs {
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-attention;
      }

      .flex {
        display: flex;
        align-items: center;
      }

      .vt-mb-10 {
        margin-bottom: 10px;
      }

      .vt-mb-24 {
        margin-bottom: 24px;
      }

      .vt-mb-9 {
        margin-bottom: 9px;
      }

      .vt-mb-6 {
        margin-bottom: 6px;
      }
    }
  }

  .verification-table {
    box-shadow: 0px 1px 3px 0px #00000029;
    border-radius: 8px;
    overflow: hidden;

    @media (max-width:767px) {
      border-radius: 0px;
    }

    .MuiTableHead-root {

      .MuiTableCell-head {
        background-color: $color-Primary;
        color: $color-White;
        padding: 13px 27px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        font-family: $regulerFont;
        letter-spacing: 0px;

        @media (max-width:799px) {
          padding: 10px 12px;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }

    .MuiTableBody-root {

      .MuiTableRow-root {

        .MuiTableCell-body {
          padding: 16px 27px;
          font-size: 16px;
          line-height: 19px;
          font-family: $regulerFont;
          font-weight: 400;
          color: $color-Black;
          letter-spacing: 0px;

          @media (max-width:799px) {
            padding: 11px 12px;
            font-size: 14px;
            line-height: 16px;
          }

          p {
            font-size: 16px;
            line-height: 19px;
            font-family: $regulerFont;
            font-weight: 400;
            color: $color-Black;
            letter-spacing: 0px;

            @media (max-width:799px) {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }

      }
    }

    .choose-btn {
      background-color: $color-Accent-1;
      color: $color-White;
      padding: 7px 12px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      font-family: $regulerFont;
      letter-spacing: 0px;
      border-radius: 8px;
      text-transform: capitalize;
      margin-top: 10px;
    }

    .verfiy-doc-container {
      display: flex;
      align-items: center;
      column-gap: 6px;
      margin-top: 8px;

      .file-name {
        padding: 0px;
        font-size: 14px;
        line-height: 16px;
        font-family: $regulerFont;
        font-weight: 400;
        color: $color-Affirmative;
        text-transform: lowercase;

        &:hover {
          background-color: transparent;
          box-shadow: none;
        }
      }
    }

    .no-border {
      @media (max-width:799px) {
        .MuiTableCell-body {
          border: none;
        }
      }
    }
  }

  .mobile-file-section {
    &:not(:last-child) {
      margin-bottom: 9px;
    }

    .mobile-verfiy-doc-container {
      display: flex;
      align-items: center;
      justify-content: space-between;



      .first-section {
        display: flex;
        align-items: center;
        column-gap: 6px;
      }

      .file-name {
        padding: 0px;
        font-size: 14px;
        line-height: 16px;
        font-family: $regulerFont;
        font-weight: 400;
        color: $color-Affirmative;
        text-transform: lowercase;

        &:hover {
          background-color: transparent;
          box-shadow: none;
        }
      }
    }
  }

  .submit-btn-container {
    margin-top: 18px;

    .submit-btn {
      background-color: $color-Accent-1;
      color: $color-White;
      border-radius: 8px;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      font-family: $regulerFont;
      letter-spacing: 0px;
      text-transform: capitalize;
      justify-content: center;
      padding: 13px 45px 12px;
    }
  }
}

.delete-doc-modal {

  .delete-dialogbox {
    .delete-title {
      font-size: 22.4px;
      line-height: 27px;
      font-weight: 500;
      font-family: $regulerFont;
      color: $color-Black;
      margin-bottom: 18px;
      letter-spacing: 0px;
    }

    .sub-title {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      font-family: $regulerFont;
      color: $color-Black;
      margin-bottom: 34px;
      letter-spacing: 0px;
    }

    .delete-btn-wrap {

      .delete-btn {
        width: 100%;
        background-color: $color-Accent-1;
        color: $color-White;
        border-radius: 8px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;
        letter-spacing: 0px;
        text-transform: capitalize;
        justify-content: center;
        padding: 13px 0px 12px;
        margin-bottom: 18px;
      }


    }

    .cancel-btn-wrap {

      .cancel-btn {
        width: 100%;
        padding: 13px 0px 12px;
        border: 1px solid $color-Accent-1;
        border-radius: 8px;
        color: $color-Accent-1;
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;
        letter-spacing: 0px;
        text-transform: capitalize;
      }
    }
  }
}