@import "../../../../../assets/scss/variable.scss";

.contact-us-modal {
  .MuiDialog-paper {
    max-width: 650px;
    width: 100%;
    margin: 12px;
    border-radius: 8px;
    .modal-title .MuiButtonBase-root svg path {
      fill: $color-White;
    }

    .contact-us-title {
      background-image: url(../../../../../assets/images/popUp-title-bg.png);
      padding: 15px 18px;
      .title {
        color: $color-White !important;
        display: flex;
        column-gap: 6px;
        align-items: center;
      }
    }

    .contact-us-details {
      padding: 18px;
      .contact-us-modal-detail-container {
        .form-container {
          .stepper-main-wrap {
            padding: 0px;

            .textfield-text {
              text-align: left;
              line-height: 22.4px;
              color: $color-Black;
              font-size: 16px;
              font-weight: 500;
            }

            .step1-container {
              .MuiInputBase-input {
                max-width: none;
                // width: 100%;
              }

              .MuiInput-underline:before {
                border-bottom: none;
              }

              .MuiOutlinedInput-root {
                border-radius: 8px;
                max-width: 100%;
                width: 100%;
                min-height: 45px;
                background-color: #ffff !important;
              }

              .MuiOutlinedInput-adornedEnd {
                padding-right: 14px;
              }

              .submit-btn {
                font-size: 16px;
                font-weight: normal;
                line-height: 19px;
                border: 1px solid $color-Accent-1;
                border-radius: 8px;
                letter-spacing: 0px;
                background: $color-Accent-1;
                color: $color-White;
                width: 100%;
                margin-top: 27px;
                padding: 12px 0;
                text-transform: none;

                @media (max-width: 599px) {
                  padding: 11px 0;
                }
              }
            }
            .textfield-sec {
              text-align: left;
              padding-top: 18px;

              .text-field {
                width: 100%;

                .MuiInputBase-input {
                  padding: 10px 10px;

                  width: 100%;
                  min-height: 25px;
                  font-family: $regulerFont;
                  font-size: 16px;
                  line-height: 20px;
                }

                .MuiInputLabel-outlined {
                  transform: translate(14px, 11px) scale(1);
                }

                .MuiInputLabel-outlined.MuiInputLabel-shrink {
                  transform: translate(14px, -8px) scale(0.75);
                }

                .Mui-focused.MuiInputLabel-outlined.MuiInputLabel-shrink {
                  color: $color-Accent-1;
                }

                .MuiInputBase-root {
                  background-color: $color-White !important;
                  border-radius: 8px;
                  justify-content: space-between;
                }

                input[type="number"]::-webkit-inner-spin-button,
                input[type="number"]::-webkit-outer-spin-button {
                  display: none;
                }

                .Mui-focused {
                  .MuiOutlinedInput-notchedOutline {
                    border-color: #4455c7;
                    border-width: 1;
                    color: $color-Accent-1;
                  }
                }

                .MuiOutlinedInput-adornedEnd {
                  justify-content: space-between;
                }

                .MuiInputBase-multiline {
                  padding: 0px;
                }
              }

              .email-text-field {
                .MuiInputBase-input {
                  max-width: none;
                }
              }

              .textfield-text {
                font-family: $regulerFont;
                font-size: 16px;
                line-height: 21px;
                color: $color-Black;
                text-align: left;
                font-weight: 600;
              }

              svg {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
