@import "src/assets/scss/variable.scss";
.tipping-payment-details {
  // padding: 18px 18px 21px;
  background-color: $color-White;
  // box-shadow: 0px 1px 9px 0px #0000002e;

  @media (max-width: 799px) {
    padding: 10px 12px;
  }

  .payment-details {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    font-family: $regulerFont;
    color: $color-Black;
    letter-spacing: 0px;
  }

  .left-card-details {
    display: flex;
    align-items: center;
    column-gap: 11px;
    margin-bottom: 10px;
  }

  .credit-card-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .card-text {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      font-family: $regulerFont;
      letter-spacing: 0px;
      color: $color-Black;
    }

    .card-section {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    .right-card-details {
      display: flex;
      align-items: center;
      column-gap: 5px;
      cursor: pointer;

      svg {
        path {
          fill: $color-Accent-1;
        }
      }

      .add-new-card-text {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        font-family: $regulerFont;
        color: $color-Accent-1;
        text-decoration: underline;
      }
    }
  }

  .payment-select {
    margin-bottom: 28px;

    .payment-radioGroup {
      // flex-direction: row;
      // justify-content: space-between;

      .payment-card-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      .MuiFormControlLabel-root {
        &:not(:last-child) {
          margin-bottom: 19px;
        }
      }

      .MuiButtonBase-root {
        padding: 0px 9px 0px 12px;
      }

      .payment-label {
        display: flex;
        align-items: center;

        .label {
          font-size: 16px;
          line-height: 19px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Black;
          margin-right: 8px;
        }

        .card-section {
          display: flex;
          column-gap: 8px;
        }
      }

      .save-card {
        align-items: center;
        width: 100%;

        .MuiTypography-root {
          width: 100%;
        }

        .save-payment-card-label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 15px;
          background-color: #e7e9ec;
          border-radius: 8px;

          p {
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;
            color: $color-Black;
          }

          .expiry-date {
            font-size: 11.42px;
            letter-spacing: 14px;
            font-weight: 400;
            font-family: $regulerFont;
            letter-spacing: 0px;
            color: $color-Black;
            display: flex;
            align-items: center;
            column-gap: 9px;

            .delete-underline {
              text-decoration: underline;
            }
          }

          .bold {
            font-weight: 600;
          }

          .disabled {
            opacity: 0.8;
            cursor: default;
          }
        }
      }
    }
  }

  // .card-details {
  //   margin-top: 28px;
  // }

  .mb-19 {
    margin-bottom: 19px;
  }

  .date-cvv-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 18px;

    @media (max-width: 799px) {
      flex-direction: column;
      row-gap: 18px;

      .card-info {
        width: 100%;
      }
    }
  }

  .card-info {
    .textfield-text {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      font-family: $regulerFont;
      margin-bottom: 4px;
      color: $color-Black;

      @media (max-width: 799px) {
        font-size: 11.42px;
        line-height: 14px;
      }

      .star {
        color: #d84727;
      }
    }

    .cvv-text {
      display: flex;
      align-items: center;
      column-gap: 5px;
    }

    .details-textfield {
      width: 100%;

      .MuiInputLabel-outlined {
        transform: translate(14px, 14px) scale(1);
      }

      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -8px) scale(0.75);
        // color: $color-Accent-1;
      }

      .MuiInputBase-root {
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-Black;
        border-radius: 8px;

        .MuiInputBase-input {
          padding: 13px 14px;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: #d4d6d8;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          display: none;
        }
      }

      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: $color-Accent-1;
          border-width: 1px;
        }
      }
    }
  }

  .date-card-info {
    width: 50%;
  }

  .cvv-card-info {
    width: 50%;
  }

  .checkBox-wrap {
    .documentsRead-check {
      .MuiButtonBase-root {
        margin-right: 12px;
        padding: 0px 0px 0px 10px;

        &:hover {
          background-color: transparent;
        }

        .MuiSvgIcon-root {
          path {
            fill: #d4d6d8;
          }
        }

        .MuiTouchRipple-root {
          display: none;
        }
      }

      .Mui-checked {
        .MuiSvgIcon-root {
          path {
            fill: $color-Primary;
          }
        }
      }

      .MuiTypography-root {
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        font-family: $regulerFont;
        letter-spacing: 0px;
        color: #989898;
      }
    }
  }

  .total-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px;
    border-bottom: 1px solid #d4d6d8;
    margin-top: 7px;

    p {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      font-family: $regulerFont;
      color: $color-Black;
    }

    .bold {
      font-weight: 600;
    }
  }

  .subscribe-wrap-btn {
    text-align: center;
    margin-top: 19px;
    margin-bottom: 8px;

    .subscribe-btn {
      padding: 13px 24px 12px;
      background-color: $color-Accent-1;
      color: $color-White;
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      font-family: $regulerFont;
      border-radius: 8px;
      text-transform: capitalize;
      box-shadow: none;

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .subscribe-note {
    text-align: center;

    p {
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      font-family: $regulerFont;
      color: #989898;

      @media (max-width: 799px) {
        font-size: 11.42px;
        line-height: 14px;
      }
    }
  }
}

.edit-comp-details {
  .css-456u3q-MuiButtonBase-root-MuiTab-root.Mui-selected {
    background-color: $color-Primary;
    color: white;
    margin: 10px;
    margin-bottom: 0px;
    border-radius: 10px;
  }

  .css-jvythz-MuiTabs-indicator {
    background-color: transparent;
  }
  .css-456u3q-MuiButtonBase-root-MuiTab-root {
    margin: 10px;
    margin-bottom: 0px;
  }

  .css-456u3q-MuiButtonBase-root-MuiTab-root {
    min-height: auto;
    padding: 8px 16px;
  }
  .css-1gsv261 {
    border-bottom: none;
  }

  @media screen and (max-width: 799px) {
    .css-19kzrtu {
      padding: 0px;
    }
  }

  .font-bold {
    font-weight: 600;
  }
}

.edit-comp-details {
  .tab-container {
    padding: 9px;

    .tab-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $color-Light-grey;
      border-radius: 8px;
      padding: 4px;

      .tab-details {
        width: 100%;
        text-align: center;
        padding: 6px 0px;
        cursor: pointer;

        .tab-text {
          margin: 0px;
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 600;
          font-family: $regulerFont;
          letter-spacing: 0px;
          color: $color-Black;
        }
      }

      .tab-actives {
        // background: linear-gradient(
        //   180deg,
        //   rgba(68, 85, 199, 1) 0%,
        //   rgba(63, 72, 146, 1) 100%
        // );
        background-color: $color-Primary !important;
        border-radius: 8px;

        .tab-text {
          color: $color-White;
        }
      }
    }

    .tab-wrap-bottom {
      margin-bottom: 6px;
    }
  }
}

.css-1yado2t {
  margin-bottom: 0px !important; /* Default for small screens */
}

@media (min-width: 768px) {
  /* Adjust breakpoint as needed */
  .css-1yado2t {
    margin-bottom: 80px !important;
  }
}

.member-other-details-wraps {
  display: flex;
  justify-content: space-between;
  column-gap: 26px;

  @media (max-width: 999px) {
    flex-direction: column;
    row-gap: 21px;
  }

  .left-your-selection {
    width: 100%;

    .your-selection-wrap {
      width: calc(100% - 36px);
      background-color: $color-White;
      box-shadow: 0px 1px 9px 0px #0000002e;
      border-radius: 8px;
      padding: 16px 18px;

      &:first-child {
        margin-bottom: 18px;
      }

      @media (max-width: 799px) {
        padding: 10px 9px;
        width: calc(100% - 18px);
      }

      .selection-header {
        margin-bottom: 12px;

        .header {
          font-size: 16px;
          line-height: 19px;
          font-weight: 600;
          font-family: $regulerFont;
          color: $color-Black;
          letter-spacing: 0px;

          @media (max-width: 799px) {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }

      .subscription-type-details {
        .subscription-text {
          font-size: 16px;
          line-height: 19px;
          font-weight: 400;
          font-family: $regulerFont;
          color: $color-Black;
        }

        .subscription-type {
          font-weight: 500;
          margin-bottom: 11px;
        }

        .plan-deatils {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .plan-deatils-mb-9 {
          margin-bottom: 9px;
        }

        .plan-deatils-mb-19 {
          margin-bottom: 19px;
        }

        .total-details-wrap {
          border-top: 1px solid $color-grey;
        }

        .coupon-code-details-wrap {
          padding-bottom: 15px;

          .coupon-code-details {
            padding: 9px 12px;
            background-color: $color-Light-grey;
            margin-top: 9px;
            display: flex;
            align-items: center;
            column-gap: 34px;

            .details-code {
              width: 70%;

              .amount-off {
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
                letter-spacing: 0px;
                font-family: $regulerFont;
                margin-bottom: 7px;
                color: $color-Secondary;
              }

              .card-text {
                font-size: 11.42px;
                line-height: 14px;
                font-weight: 400;
                letter-spacing: 0px;
                font-family: $regulerFont;
                color: $color-Black;
              }

              .mb-7 {
                margin-bottom: 7px;
              }
            }

            .coupon-number {
              width: 30%;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .coupon-card {
              padding: 16.9px 12.8px;
              background-color: $color-White;
              font-size: 16px;
              line-height: 19px;
              font-weight: 400;
              font-family: $regulerFont;
              color: $color-Black;
              max-width: 100px;
              word-wrap: break-word;
            }

            .border-element {
              position: relative;
            }

            .border-element:before {
              content: "";
              position: absolute;
              width: 12px;
              height: 12px;
              background-color: #e7e9ec;
              border-radius: 50%;
              top: -6px;
              left: 75%;
              transform: translateX(-50%);
            }

            .border-element .circle-bottom {
              content: "";
              position: absolute;
              width: 12px;
              height: 12px;
              background-color: #e7e9ec;
              border-radius: 50%;
              bottom: -6px;
              left: 75%;
              transform: translateX(-50%);
            }

            .border-element:after {
              content: "";
              position: absolute;
              height: 100%;
              border-right: 7px dotted #e7e9ec;
            }

            .border-element:after {
              right: -2px;
              top: 0px;
            }
          }
        }

        .total-details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 19px;

          .total-name {
            font-weight: 600;
          }

          .total-price {
            font-weight: 600;
          }

          .discount-amount {
            display: flex;
            align-items: center;
            color: $color-attention;

            span {
              width: 18px;
              height: 18px;
            }
          }
        }

        .coupon-code-wrap {
          display: flex;
          column-gap: 10px;

          .details-textfield {
            width: 75%;

            @media (max-width: 659px) {
              max-width: none;
            }

            .MuiInputBase-root {
              font-size: 16px;
              line-height: 19px;
              font-weight: 400;
              font-family: $regulerFont;
              color: $color-Black;
              border-radius: 8px;

              .MuiInputBase-input {
                padding: 13px 14px;
              }

              .MuiOutlinedInput-notchedOutline {
                border-color: #d4d6d8;
              }

              input[type="number"]::-webkit-inner-spin-button,
              input[type="number"]::-webkit-outer-spin-button {
                display: none;
              }
            }

            .MuiInputLabel-outlined {
              transform: translate(14px, 15px) scale(1);
            }

            .MuiInputLabel-outlined.MuiInputLabel-shrink {
              transform: translate(14px, -6px) scale(0.75);
              color: $color-Accent-1;
            }

            .Mui-focused {
              .MuiOutlinedInput-notchedOutline {
                border-color: $color-Accent-1;
                border-width: 1px;
                color: $color-Accent-1;
              }
            }
          }

          .apply-btn {
            width: 25%;
            padding: 14px 0px 12px;
            background-color: $color-Accent-1;
            color: $color-White;
            font-size: 16px;
            line-height: 19px;
            font-weight: 400;
            font-family: $regulerFont;
            border-radius: 8px;
            text-transform: capitalize;
            box-shadow: none;

            .MuiTouchRipple-root {
              display: none;
            }
          }
        }
      }

      .fw-400 {
        font-weight: 400 !important;
      }

      .fw-500 {
        font-weight: 500 !important;
      }

      .fw-600 {
        font-weight: 600 !important;
      }

      .fw-700 {
        font-weight: 700 !important;
      }
    }
  }
}
