@import "../../../../assets/scss/variable.scss";

.new-team-sports-layout {
  .layout-header {
    padding: 0px 0px 3px;

    @media only screen and (max-width: 599px) {
      padding: 0px 0px 6px;
    }

    .bredcrumn-wrap {
      margin-bottom: 3px;

      @media only screen and (max-width: 599px) {
        margin-bottom: 5px;
      }

      li.MuiBreadcrumbs-separator {
        color: $color-Black;
      }

      li {
        font-size: 11.42px;
        line-height: 14px;

        p {
          font-size: 11.42px;
          line-height: 14px;
          color: $color-Accent-1;
          text-transform: uppercase;
        }

        a {
          color: $color-Black;
          font-size: 11.42px;
          line-height: 14px;
          text-transform: uppercase;
        }
      }
    }

    .title-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      // @media only screen and (max-width: 799px) {
      //     display: block;
      // }

      h1 {
        line-height: 56px;
        color: $color-Black;

        @media only screen and (max-width: 599px) {
          font-size: 31.36px;
          line-height: 40px;
        }
      }

      .exprt-tips-btn {
        max-width: 100%;
        width: 212px;
        margin-bottom: 12.6px;
        padding: 8px 24px 12px 24px;
        background-color: $color-Accent-1;
        color: $color-White;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        font-family: $regulerFont;
        border-radius: 8px;
        text-transform: capitalize;
        cursor: pointer;

        @media (max-width: 1023px) {
          margin-bottom: 0px;
        }

        @media (max-width: 900px) {
          padding: 11px 14px 12px 14px;
        }

        @media (max-width: 799px) {
          font-size: 14px;
          padding: 11px 24px 12px 24px;
        }

        @media (max-width: 650px) {
          width: 180px;
          padding: 7px 12px 10px;
        }

        @media (max-width: 400px) {
          font-size: 11.42px;
          line-height: 14px;
          width: 148px;
        }

        // .btn-txt {
        //     margin-top: 6px;
        // }
      }
    }
  }

  .team-sports-tab {
    margin-bottom: 11px;

    @media (max-width: 599px) {
      margin-bottom: 18px;
    }

    .select-and-expert-wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: 12px;
      margin-top: 12px;
    }

    .disclaimer-text-clear {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid #e7e9ec;
      margin-bottom: 18px;
      padding: 10px 0px 15px;
    }

    .disclaimer {
      font-size: 11.42px;
      line-height: 14px;
      font-weight: 400;
      color: $color-Black;
      letter-spacing: 0px;
      font-family: $regulerFont;
      width: calc(100% - 100px);
    }

    .reset-all {
      display: flex;
      align-items: center;
      justify-content: end;
      width: 100px;

      .reset-all-text {
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        color: $color-Accent-1;
        font-family: $regulerFont;
        border-bottom: 1px solid $color-Accent-1;
      }
    }

    .search_input_15 {
      margin-top: 15px;
    }

    .search_input {
      width: 100%;

      .MuiInputBase-root {
        max-height: 45px;

        svg {
          height: 18px;
          width: 18px;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: #e7e9ec;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $color-Accent-1;
        border-width: 1.5px;
      }

      @media (max-width: 799px) {
        margin-left: 0px !important;
        min-width: auto;
        width: 100%;
      }
    }
  }

  .mobile-filter-bottom {
    border-bottom: 1px solid $color-grey;
    margin-bottom: 19px;
  }

  .mobile-header-filter-section {
    padding: 9px;
    border-radius: 8px;
    border: 1px solid $color-grey;
    background-color: $color-Light-grey;
    margin-bottom: 9px;
    cursor: pointer;

    .filter-text {
      display: flex;
      align-items: center;
      column-gap: 6px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      font-family: $regulerFont;
      color: $color-Black;
    }
  }

  .team-sport-featured {
    padding: 8px 33px 18px;

    @media (max-width: 799px) {
      padding: 15px 0px;
    }

    .racing-slider-wrap {
      margin-bottom: 0px;
    }
  }

  .new-sport-wrap-section {
    background-color: $color-White;
    border-radius: 8px;
    padding: 10px 0px 33px;
  }

  .live-date-filter-tab-wrap {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding: 11px 33px 15px;
    column-gap: 12px;
    border-bottom: 1px solid $color-grey;

    @media (max-width: 1160px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      row-gap: 12px;
      padding: 11px 12px 15px;
    }

    .live-tab {
      // width: 100%;

      .live-tab-details {
        padding: 0px;
        .MuiTab-textColorInherit {
          border: 2px solid #c9c9c9;
          color: #989898;
          min-height: 38px;

          @media (max-width: 539px) {
            min-height: inherit;
            height: 34px;
          }
        }

        .active {
          color: $color-White;
          background: linear-gradient(
            180deg,
            rgba(68, 85, 199, 1) 0%,
            rgba(63, 72, 146, 1) 100%
          );
          border: 2px solid transparent;
          font-weight: 600;
        }

        .MuiTab-root {
          min-width: max-content;
          width: auto;
          padding: 0px 24px;

          @media (max-width: 799px) {
            width: auto;
            padding: 6px 8px;
          }
        }

        .MuiButtonBase-root {
          font-size: 14px;
          line-height: 16px;
          font-family: $regulerFont !important;
          font-weight: 400 !important;

          @media (max-width: 539px) {
            font-size: 11px;
            line-height: 14px;
          }
        }
      }
    }

    .date-selected-wrap {
      width: 100%;
      max-width: 270px;

      @media (max-width: 1160px) {
        max-width: none;
      }

      .MuiFormControl-root {
        margin: 0px;

        @media (max-width: 1160px) {
          width: 100%;
        }
      }

      .MuiInputBase-root {
        padding-right: 0px;
        border-radius: 5px;
        font-size: 14px;
        line-height: 16px;
        font-family: $regulerFont;
        font-weight: 400;
        color: #999999;
        height: 37px !important;

        .MuiInputBase-input {
          padding: 13px 0px 11px 8px;
        }

        .MuiButtonBase-root {
          margin: 0px;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: #c9c9c9;
          border-width: 2px;
        }

        &:hover {
          border-color: #c9c9c9;
        }
      }

      .Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-width: 2px;
        }
      }
    }

    #date-picker-inline::placeholder {
      color: #999999;
      opacity: 1;
    }
  }

  .state-tab-details {
    padding: 18px 0px;
    min-height: 38px;

    @media (max-width: 799px) {
      padding: 9px 0px;
      min-height: 32px;
    }

    // .MuiTabScrollButton-root {
    //   // border: 2px solid $color-Primary;
    // }

    .MuiTab-textColorInherit {
      color: $color-Black;
      border: 2px solid $color-Primary;
      margin: 0px 12px;
      border-radius: 8px;
      opacity: 1;
      min-height: 38px;
      padding: 9px 10px;

      @media (max-width: 799px) {
        margin: 0 9px;
        padding: 6px 12px;
        min-height: 32px;
      }
    }

    .active {
      color: $color-White;
      background: $color-Primary;
    }

    .MuiTabs-indicator {
      background: none;
    }

    .MuiTab-textColorInherit:first-child {
      margin-left: 0px;
    }

    .MuiTab-root {
      min-width: initial;
      width: 106px;
    }

    .MuiButtonBase-root {
      font-size: 16px;
      line-height: 20px;
      font-family: $primaryFont;
    }

    .Mui-disabled {
      opacity: 1;
    }

    .MuiTabs-scroller {
      .MuiTabs-flexContainer {
        .MuiBox-root:last-child {
          button {
            margin: 0px;
          }
        }
      }
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }

  .main-table-wrap-section {
    margin-top: 33px;

    @media (max-width: 1023px) {
      margin-top: 0px;
    }
  }

  .team-sport-table-wrap {
    // padding: 30px 33px 0px;
    // background-color: $color-White;
    // box-shadow: 0px 3px 9px 0px #0000000d;
    margin: 0px 33px 18px;
    border-radius: 8px;
    // width: max-content;

    @media (max-width: 1365px) {
      padding: 30px 12px 0px;
      margin: 0px;
    }

    @media only screen and (max-width: 599px) {
      padding: 19px 12px 2px;
    }

    .table-header-wrap {
      // border-bottom: 1px solid $color-Accent-1;
      display: flex;
      justify-content: space-between;
      height: 28px;
      align-items: center;
      padding-bottom: 2px;

      @media (max-width: 799px) {
        height: 25px;
        padding-bottom: 4px;
      }

      @media (max-width: 479px) {
        flex-wrap: wrap;
        margin-bottom: 5px;
      }

      .table-indicator-wrap {
        display: flex;
        align-items: center;

        // align-items: end;
        @media (max-width: 479px) {
          align-items: center;
        }
      }

      h1 {
        font-size: 22.4px;
        line-height: 31.36px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-Black;

        @media only screen and (max-width: 599px) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .markets {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: $color-Black;
        padding-left: 3px;
        font-family: $regulerFont;

        @media only screen and (max-width: 479px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }

      .show-update {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        font-family: $regulerFont;
        letter-spacing: 0px;
        color: $color-Black;
        cursor: pointer;

        @media only screen and (max-width: 479px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }

      .start-time-date {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        font-family: $regulerFont;

        @media only screen and (max-width: 479px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }

      .currentbest-seeall {
        display: flex;

        .teamsports-seeall {
          margin-left: 13px;

          cursor: pointer;

          h1 {
            color: $color-Accent-1;
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            text-decoration: underline;
            font-family: $regulerFont !important;

            @media (max-width: 1023px) {
              font-size: 12px;
              line-height: 15px;
            }
          }
        }
      }

      .show-update-wrap {
        display: flex;
        align-items: center;
        column-gap: 9px;

        .show-update-checkbox {
          margin: 0px;

          .MuiIconButton-root {
            padding: 0px;
          }
        }
      }
    }

    .team-sport-table {
      // border-bottom: 0.6px solid #d4d6d8;
      // min-width: 940px;
      // overflow-x: auto;
      .team-sport-table-indicator {
        display: flex;
        align-items: center;
        row-gap: 5px;

        @media (max-width: 1365px) {
          flex-direction: column;
          align-items: flex-start;
        }

        .team-time-market {
          width: 188px !important;
        }
      }

      .custom-tooltip {
        .smartboook-icon-box {
          cursor: pointer;
          background-color: #e7e9ec;
          margin-left: 9.5px;
          margin-right: 5px;
          // height: 26px;
          // width: 26px;
          height: 32px;
          width: 34px;
          border-radius: 50%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .book-icon {
            object-position: center;
          }

          .smartboook-add {
            position: absolute;
            bottom: -3px;
            right: -2px;
          }
        }

        .mr {
          margin-left: 0px;
          margin-right: 15px;
        }
      }

      .MuiTableCell-root {
        border: none;
      }

      // .MuiTableCell-body {
      //     height: 87px;
      // }

      .MuiTableCell-head {
        font-family: $regulerFont;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        color: $color-Black;
        text-align: center;
        padding: 5px 0px;
        text-transform: uppercase;

        @media (max-width: 1365px) {
          padding: 7px 0px;
        }
      }

      .table-current {
        width: 145px;
        min-width: 145px;

        @media (max-width: 1179px) {
          min-width: 120px;
        }
      }

      .featured-header {
        background-color: $color-Primary;
        text-align: center;
        margin: 0px auto;
        color: $color-Lavender;
        font-weight: 700;
      }

      .MuiTableCell-body {
        text-align: center;
        font-family: $regulerFont;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: $color-Black;
        padding: 11.5px 0px 11.5px;
      }

      .current-best-odds-wrap {
        padding: 0px 26px;
        align-items: center;

        @media (max-width: 1179px) {
          padding: 0px 15px;
        }

        flex-direction: column;

        .current-best-odds-value {
          display: block;
          background-color: $color-Lavender;
          padding: 9px 0px;
          width: 80px;
          margin: 0px auto;
          border-radius: 6px 6px 0px 0px;
          text-align: center;
        }

        .current-best-odds-icon {
          border-radius: 0px 0px 6px 6px;
          width: 80px;
          margin: 0px auto;

          .currentbest-bookmaker-thumb {
            margin: 0px auto;
            border-radius: 0px 0px 6px 6px;
            cursor: pointer;
            height: 25px;
            object-fit: cover;
            width: 100%;
            max-width: none;
          }
        }

        .active-odd-style {
          background-color: $color-Primary;

          span,
          .odds {
            color: $color-White;
          }
        }
      }

      .MuiTableRow-head :first-child {
        text-align: left;
        // width: 320px;
        min-width: 320px;

        @media (max-width: 1365px) {
          // width: 240px;
          min-width: 240px;
        }
      }

      .show-odds-th {
        width: 100% !important;
      }

      .teamsports-tablerow {
        position: relative;
      }

      .teamsport-row {
        @media (max-width: 1179px) {
          .max-w {
            .odds-flex {
              column-gap: 6px;
              padding: 0px 5px;

              .odds-box-style {
                .odds-style {
                  width: 54px;

                  .odds {
                    font-size: 14px;
                    line-height: 16px;
                  }
                }

                .odd-img {
                  width: 54px;
                }
              }
            }
          }
        }
      }

      .team-wrap {
        display: flex;
        align-items: center;
        width: 300px;

        // padding-left: 33px !important;
        @media (max-width: 1365px) {
          width: 240px;
        }

        .team-img-wrap {
          width: 45px;
          height: 45px;

          @media (max-width: 799px) {
            width: 32px;
            height: 32px;
          }

          .team-img {
            border-radius: 50%;
            width: 45px;
            height: 45px;
            object-fit: contain;

            @media (max-width: 799px) {
              width: 32px;
              height: 32px;
            }
          }
        }

        .mr-6 {
          margin-right: 6px;
        }

        .team-name {
          margin-left: 4px;
          font-family: $regulerFont;
          font-size: 16px;
          line-height: 19px;
          font-weight: 600;
          color: $color-Black;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;

          @media only screen and (max-width: 599px) {
            text-align: center;
          }
        }
      }

      .show-odds-team-wrap {
        width: calc(100% - 45px) !important;
        justify-content: space-between;
      }

      .hometeam-row {
        margin: 5.5px 0px 0px;
      }

      .awayteam-row {
        // .team-wrap {
        //   margin-top: 20px;
        // }

        .MuiTableCell-body {
          padding: 11.5px 0px 11.5px;
        }

        .current-best {
          border-bottom-right-radius: 8px;
        }
      }

      .odds-btn {
        padding: 8px 16px;
        display: block;
        background: #d6d9f3;
        border-radius: 6px;
      }

      .teamsports-tablerow:after {
        content: "";
        background-color: $color-grey;
        height: 1px;
        width: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 0 auto;
      }

      .show-teamsports-tablerow::after {
        display: none;
      }

      .hight-chart-cell {
        padding: 5px 0px !important;
        border-left: 1px solid $color-Light-grey;

        .nodata {
          width: 300px;
          margin: 0px auto;
          font-family: $regulerFont;
        }
      }
    }

    .live-section {
      background-color: #ffffff;
      box-shadow: 0px 1px 9px 0px #00000029;
      padding: 0px 0px 0px 33px;
      border-radius: 8px;

      .combat-sport-table-live {
        margin-bottom: 27px;

        .MuiTableCell-head {
          font-family: $regulerFont;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
          color: $color-Black;
          text-align: center;
          // padding: 14px 0px 5px;
          min-width: 30px;
          text-transform: uppercase;
        }

        .MuiTableCell-body {
          text-align: center;
          font-family: $regulerFont;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: $color-Black;
          padding: 15px 0px 12px;

          .odds-wrap {
            flex-direction: column;
            align-items: center;
          }
        }

        .MuiTableRow-head :first-child {
          text-align: left;
          width: auto;
        }

        .team-wrap {
          width: 70%;
        }

        .text-left {
          text-align: left;
        }

        .tooltip-box {
          margin-left: 10px;

          .custom-tooltip {
            .smartboook-icon-box {
              cursor: pointer;
              background-color: #e7e9ec;
              margin-left: 9.5px;
              margin-right: 5px;
              height: 32px;
              width: 34px;
              border-radius: 50%;
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;

              .book-icon {
                object-position: center;
              }

              .smartboook-add {
                position: absolute;
                bottom: -3px;
                right: -2px;
              }
            }

            .mr {
              margin-left: 0px;
              margin-right: 15px;
            }
          }

          .show-tooltip {
            box-shadow: 10px 10px 5px 0px #0000000f;
            -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
            -moz-box-shadow: 10px 10px 5px 0px #0000000f;
            right: 0;
            left: 150px;

            @media (max-width: 799px) {
              width: 185px;
              transform: translateX(-118%);
              // left: 35px;
              // top: -28px;
              left: 133px;
              top: 36px;
            }

            .tooltip-arrow {
              right: -10px;
              left: -12px;
              transform: rotate(90deg);

              @media (max-width: 799px) {
                left: initial;
                // right: -12px;
                // transform: rotate(270deg);

                right: 50px;
                transform: rotate(540deg);
                top: -9px;
              }
            }
          }
        }

        .combat-sport-indicator-live {
          display: flex;
          align-items: center;
          padding: 11px 0px 5px 0px;

          .team-time-market {
            // width: 188px !important;
            font-size: 12px;
            line-height: 15px;
            font-weight: 600;

            .live-indicator {
              padding: 3px 11px;
              background-color: $color-Negative;
              color: $color-White;
              font-size: 12px;
              line-height: 15px;
              font-weight: 600;
              border-radius: 3px;
              margin-right: 13px;
            }
          }

          .live-indicator-wrap {
            width: 245px !important;
          }
        }

        .live {
          padding: 3px 11px;
          background-color: #d84727;
          color: #ffffff;
          font-size: 11.42px;
          line-height: 21px;
          font-weight: 400;
          font-family: $regulerFont;
          max-width: fit-content;
          border-radius: 3px;
          margin-right: 13px;
          text-transform: uppercase;
        }

        .current-best-odds-wrap {
          padding: 0px 26px;

          .current-best-odds-value {
            display: block;
            background-color: $color-Lavender;
            padding: 9px 0px;
            width: 80px;
            margin: 0px auto;
            border-radius: 6px 6px 0px 0px;
            text-align: center;
          }

          .current-best-odds-icon {
            border-radius: 0px 0px 6px 6px;
            width: 80px;
            margin: 0px auto;

            .currentbest-bookmaker-thumb {
              margin: 0px auto;
              border-radius: 0px 0px 6px 6px;
              cursor: pointer;
              height: 25px;
              object-fit: cover;
              width: 100%;
              max-width: none;
            }
          }

          .active-odd-style {
            background-color: $color-Primary;

            span,
            .odds {
              color: $color-White;
            }
          }
        }
      }

      .win-odds-live {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-bottom: 28px;

        .odds-left {
          min-width: 220px !important;
        }
      }

      .sponsored-wrap {
        border: 0.6px solid #d4d6d8;
        box-shadow: 0px 3px 9px 0px #0000000d;
        margin-top: 27px;

        .sponsored-head-live {
          background-color: #e7e9ec;

          .head-title {
            background-color: #003764;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 48px;
            color: #d6d9f3;
            font-family: $regulerFont;
          }
        }

        .sponsored-odds-wrap {
          // padding: 18px 0px 33px;

          .sponsored-odds-live {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            position: relative;

            .sponsored-odd {
              padding: 18px 0px 15px;

              .odds-label {
                max-width: none;
              }

              .odds-left {
                min-width: 220px;
              }
            }

            .border-right-socre::after {
              content: "";
              background-color: #d4d6d8;
              height: 100%;
              width: 1px;
              position: absolute;
              right: 50%;
              bottom: 0px;
            }

            .border-bottom-score::after {
              content: "";
              background-color: #d4d6d8;
              height: 1px;
              width: 100%;
              position: absolute;
              right: 0;
              bottom: 50%;
              // margin: 0 auto;
            }
          }
        }
      }

      .cell-score-dark {
        background-color: $color-Primary;
        color: $color-White !important;
      }

      .result-header {
        background-color: #e2e4f1;
        color: $color-Primary !important;
      }

      .result-body {
        color: $color-Primary !important;
        font-weight: 600 !important;
        background-color: #fafafa;

        .team-score {
          color: $color-Primary;
          font-weight: 600;
        }

        .lose-team {
          opacity: 0.8;
        }
      }

      .score-cell {
        .icon-score-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 5px;

          .win-icon {
            width: 7px;
            height: 11px;
            display: flex;

            svg {
              width: 100%;
              height: 100%;
              transform: rotate(180deg);
            }
          }
        }
      }

      .dark-bottom-border {
        border-bottom: 1px solid $color-White !important;
      }

      .sport-score {
        width: 160px;
        border-right: 1px solid $color-White;
      }

      .table-current {
        width: 145px;
        border-top-right-radius: 8px;
      }
    }

    .main-featured-table {
      background-color: $color-White;
      box-shadow: 0px 1px 3px 0px #00000029;
      border-radius: 8px;
      outline: 1px solid $color-Light-grey-1;
      // overflow: hidden;

      // overflow: hidden;
      .fluctuation-graph-tablehead {
        text-align: center;
        font-size: 14px;
        line-height: 17px;
        font-weight: 700;
        color: $color-Accent-1;
        background-color: $color-Light-grey-4;
        border-bottom: none;
        border-left: 1px solid $color-Light-grey;
      }

      .MuiTableRow-head :first-child {
        padding: 11px 0px 5px 33px;
      }

      .MuiTableBody-root {
        .teamsport-row {
          .team-wrap {
            padding-left: 33px;
          }
        }
      }
    }

    .mobileview-teamsport-table-wrap {
      padding: 18px 0px 0px;
      background-color: #ffffff;
      box-shadow: 0px 3px 9px 0px #00000029;
      border-radius: 8px;
      overflow: hidden;

      .time-market-team-wrap {
        .mobile-temsport-indicator {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // padding: 0px 40px;
          position: relative;
          border-bottom: 1px solid $color-Light-grey;
          padding: 0px 9px 9px 9px;

          @media (max-width: 420px) {
            align-items: flex-start;
          }

          .live-indicator {
            padding: 3px 11px;
            background-color: $color-Negative;
            color: $color-White;
            font-size: 12px;
            line-height: 15px;
            font-weight: 600;
            border-radius: 3px;
            margin-right: 9px;
          }

          .des-label {
            margin-left: 0px;
          }

          .mobile-teamsport-left {
            @media (max-width: 420px) {
              width: max-content;

              .des-label {
                text-align: left;
              }
            }
          }

          .mobile-teamsport-right {
            display: flex;
            align-items: center;

            .markets {
              color: $color-Black;
              font-size: 11.42px;
              line-height: 14px;
              font-weight: 400;

              font-family: $regulerFont;
            }

            .divider {
              margin: 0px 3px;
              color: #4455c7;
            }

            .currentbest-seeall {
              h1 {
                color: #4455c7;
                font-size: 12px;
                line-height: 15px;
                font-weight: 600;
                text-decoration: underline;
                font-family: $regulerFont !important;
              }
            }
          }
        }

        .teams-details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          line-height: 20px;
          font-family: $primaryFont;
          color: $color-Black;
          padding: 19px 40px 12px;

          @media (max-width: 799px) {
            padding: 19px 12px 12px;
          }

          .team-wrap {
            display: flex;
            align-items: center;
            flex-direction: column;
            // align-self: flex-start;
            width: 70%;

            .team-img-wrap {
              .team-img {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                object-fit: contain;

                @media (max-width: 799px) {
                  width: 32px;
                  height: 32px;
                }
              }
            }

            .team-name {
              font-size: 14px;
              line-height: 17px;
              font-weight: 600;
              font-family: $regulerFont;
              color: $color-Black;

              @media (max-width: 799px) {
                font-size: 14px;
              }
            }

            .team-name {
              // padding-top: 16px;
              text-align: left;
            }

            .smartbook-title {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 12px;
              column-gap: 5px;
            }

            .custom-tooltip {
              // margin-top: 12px;

              .smartboook-icon-box {
                cursor: pointer;
                background-color: #e7e9ec;
                margin-left: 9.5px;
                margin-right: 5px;
                height: 26px;
                width: 26px;
                border-radius: 50%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                .book-icon {
                  object-position: center;
                }

                .smartboook-add {
                  position: absolute;
                  bottom: -3px;
                  right: -2px;
                }
              }

              // .tooltip {
              .smart-details {
                .tooltip-txt {
                  font-size: 11.42px !important;
                  line-height: 15px !important;
                  font-family: $regulerFont !important;
                  color: #191919 !important;
                }
              }

              // }
            }
          }
        }
      }

      .odds-detail {
        padding: 5px 40px 23px;
        border-bottom: 1px solid $color-grey !important;
        position: relative;

        .odds-body {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &::after {
            content: "";
            background-color: $color-grey;
            height: 100%;
            width: 0.6px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
          }
        }
      }

      .odds-details-best {
        background-color: #eeefff;
      }

      .odds-after {
        border-bottom: 1px solid #d4d6d8 !important;

        &::after {
          content: "";
          background-color: $color-grey;
          height: 0.6px;
          width: 100%;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0 auto;
          top: 50%;
        }
      }

      .odds-detail:last-child {
        border-bottom: none;
      }

      .mobile-chart-deatils {
        position: relative;

        .mobile-hight-chart {
          display: flex;
          align-items: center;
          justify-content: space-around;
          border: 1px solid #d4d6d8;

          &::after {
            content: "";
            background-color: $color-grey;
            height: 100%;
            width: 0.6px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
          }

          .ptb-10 {
            padding: 10px 0px;
          }

          .nodata {
            font-family: $regulerFont;
          }
        }
      }

      .mobile-fluc-details {
        .mobile-team-fluc-wrap {
          .team-fluc-wrap {
            .team-name {
              text-align: center;
              font-size: 14px;
              line-height: 17px;
              font-weight: 700;
              color: $color-Accent-1;
              background-color: $color-Light-grey-4;
              border-bottom: none;
              font-family: $regulerFont;
              padding: 5px 0px;
              text-transform: uppercase;
            }

            .mobile-chart {
              .nodata {
                text-align: center;
                min-height: 50px;
                font-family: $regulerFont;
                font-size: 16px;
                line-height: 19px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }

      .teams-details {
        padding: 9px 0px !important;

        .teams-details-wrap {
          width: 100%;

          .hometeam-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 12px 9px;
            border-bottom: 1px solid $color-Light-grey;
          }

          .awayteam-row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 9px 12px 9px;
          }

          .odds-btn {
            padding: 4px 16px;
            background: #d6d9f3;
            border-radius: 6px;
            font-size: 14px;
          }

          .mobile-table-container {
            .mobile-data-head {
              .sponsor-odds-header {
                padding: 6px 9px;
                text-transform: uppercase;
                text-align: center;
              }
            }

            .mobile-table-body {
              .mobile-table-data {
                padding: 0px;
              }
            }
          }

          .awayteam-table {
            .mobile-table-body {
              .mobile-table-data {
                border-bottom: none;
              }
            }
          }

          .team-wrap {
            flex-direction: initial;

            .team-logo-name {
              column-gap: 5px;
            }
          }

          .odds-wrap {
            margin-top: 0px;
            align-items: center;
            flex-direction: column;
          }

          .current-best-odds-wrap {
            // padding: 0px 26px;
            .current-best-odds-value {
              display: block;
              background-color: $color-Lavender;
              padding: 9px 0px;
              width: 80px;
              margin: 0px auto;
              border-radius: 6px 6px 0px 0px;
              text-align: center;
            }

            .current-best-odds-icon {
              border-radius: 0px 0px 6px 6px;
              width: 80px;
              margin: 0px auto;

              .currentbest-bookmaker-thumb {
                margin: 0px auto;
                border-radius: 0px 0px 6px 6px;
                cursor: pointer;
                height: 25px;
                object-fit: cover;
                width: 100%;
                max-width: none;
              }
            }

            .active-odd-style {
              background-color: $color-Primary;

              span,
              .odds {
                color: $color-White;
              }
            }
          }
        }
      }
    }

    .des-label {
      text-align: center;
      font-size: 12px;
      line-height: 15px;
      font-weight: 600;
      font-family: $regulerFont;
      color: $color-Black;
      margin-left: 9px;
      text-transform: uppercase;

      @media only screen and (max-width: 599px) {
        font-size: 11.42px;
        line-height: 14px;
      }
    }

    .des-head {
      background-color: #e7e9ec;
      padding: 9px 0px;
      margin-left: 0px !important;
    }

    .des-current-best {
      background-color: #4455c7;
      color: #ffffff;
    }

    .des-head-to-head {
      background-color: #003764;
      color: #ffffff;
    }

    .odds-label {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      font-family: $regulerFont;
      color: $color-Black;
      text-align: center;
      max-width: 160px;
      margin: 0px auto;

      @media only screen and (max-width: 599px) {
        font-size: 11.42px;
        line-height: 14px;
        max-width: 130px;
      }
    }

    .odds-wrap {
      display: flex;
      justify-content: center;
      margin-top: 5px;
      align-items: flex-end;

      .odds {
        display: block;
        // padding: 8px 0px;
        // background: #d6d9f3;
        border-radius: 6px;
        font-size: 16px;
        line-height: 20px;
        color: $color-Black;
        min-width: 80px;
        text-align: center;
        filter: drop-shadow(0px 2px 2px #00000029);

        @media (max-width: 1365px) {
          min-width: 65px;
        }

        // @media (max-width:1120px) {
        //   min-width: 70px;
        // }

        @media only screen and (max-width: 599px) {
          font-size: 11.42px;
          line-height: 14px;
          min-width: 45px;
        }

        .no-odds {
          cursor: pointer;
        }

        .odds-btn {
          padding: 8px 0px;
          display: block;
          background: #d6d9f3;
          border-radius: 6px;
        }
      }

      .odds-left {
        margin-left: 5px;
        margin-right: 5px;

        @media (max-width: 1120px) {
          margin-right: 7px;
        }

        @media (max-width: 1024px) {
          margin-left: 0px;
        }

        @media only screen and (max-width: 599px) {
          margin-right: 7px;
        }
      }

      .bookmaker-long {
        text-align: center;
        min-width: 80px;
        border-radius: 6px;
        max-width: 80px;
        min-height: 36px;
        max-height: 36px;
        height: 100%;

        @media only screen and (max-width: 599px) {
          max-width: 45px;
          min-width: 45px;
          max-height: 30px;
          min-height: 30px;
        }
      }
    }

    .odds-bottom {
      margin-bottom: 21px;
    }
  }

  .mr-7 {
    margin-right: 7px;
  }

  .ml-7 {
    margin-left: 7px;
  }

  .teamsports-seeall-tablewrap {
    padding: 0px 33px;
    position: relative;

    @media only screen and (max-width: 599px) {
      padding: 0px 12px;
    }

    .responsive-table-wrap {
      @media only screen and (max-width: 799px) {
        background-color: $color-White;
        box-shadow: 0px 3px 9px 0px #0000000d;
        border-radius: 8px;
        overflow: hidden;
      }
    }

    .table-header-wrap {
      // border-bottom: 1px solid $color-Accent-1;
      display: flex;
      justify-content: space-between;
      height: 28px;
      align-items: center;
      padding-bottom: 2px;

      @media (max-width: 799px) {
        height: 25px;

        padding: 7px 6px 5px 12px;
        margin-bottom: 0px;
      }

      @media (max-width: 479px) {
        flex-wrap: wrap;
        margin-bottom: 5px;
      }

      .table-indicator-wrap {
        display: flex;
        align-items: center;
      }

      h1 {
        font-size: 22.4px;
        line-height: 31.36px;
        font-weight: 400;
        font-family: $primaryFont;
        color: $color-Black;

        @media only screen and (max-width: 599px) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .markets {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: $color-Black;
        padding-left: 3px;
        font-family: $regulerFont;

        @media only screen and (max-width: 479px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }

      .show-update {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        font-family: $regulerFont;
        letter-spacing: 0px;
        color: $color-Black;
        cursor: pointer;

        @media only screen and (max-width: 479px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }

      .start-time-date {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        font-family: $regulerFont;

        @media only screen and (max-width: 479px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }

      .currentbest-seeall {
        display: flex;

        .teamsports-seeall {
          margin-left: 13px;

          cursor: pointer;

          h1 {
            color: $color-Accent-1;
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            text-decoration: underline;
            font-family: $regulerFont !important;

            @media (max-width: 1023px) {
              font-size: 12px;
              line-height: 15px;
            }
          }
        }

        .seeall-arrow-wrap {
          display: flex;
          column-gap: 4px;
          margin-left: 15px;

          .seeall-arrow {
            cursor: pointer;
            height: 17px;
            width: 17px;
          }

          .left-arrow {
            transform: rotate(180deg);
          }

          .disable {
            opacity: 0.4;
          }
        }
      }

      .show-update-wrap {
        display: flex;
        align-items: center;
        column-gap: 9px;

        .show-update-checkbox {
          margin: 0px;

          .MuiIconButton-root {
            padding: 0px;
          }
        }
      }
    }

    .table-seeall-container {
      background-color: $color-White;
      box-shadow: 0px 3px 9px 0px #0000000d;
      border-radius: 8px;

      .overlay::after {
        content: "";
        position: absolute;
        width: 65px;
        height: calc(100%);
        z-index: 9;
        right: 0;
        background: transparent
          linear-gradient(90deg, #ffffff00 0%, #ffffff 100%) 0% 0% no-repeat
          padding-box;
      }

      .see-best {
        position: absolute;
        color: $color-Accent-1;
        z-index: 99;
        right: 8px;
        top: -72px;
        text-decoration: underline;
        font-family: $primaryFont;
        font-size: 22.4px;
        line-height: 14px;
        font-weight: 400;
        cursor: pointer;

        @media only screen and (max-width: 1023px) {
          top: -10px;
        }
      }

      .individual-table {
        min-width: 700px;

        @media (max-width: 799px) {
          min-width: 500px;
        }

        .MuiTableCell-root {
          border-bottom: none;
        }

        .individual-team-head {
          .oddstype {
            .select__single-value {
              color: $color-Black;
            }

            // .select__menu{
            //   z-index: 99;
            // }
          }

          .MuiTableCell-head {
            padding: 7px 12px 4px;
            line-height: 0px;
            border: 1px solid $color-Light-grey;
            border-collapse: collapse;
            text-align: center;
            color: $color-Black;

            .current-best-odds-icon {
              .odd-img {
                width: 60px;
                height: 25px;
                margin: 0 auto;

                @media (max-width: 660px) {
                  // max-width: 54px;
                  width: 54px;
                }

                @media (max-width: 389px) {
                  width: 48px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: fill;
                  border-radius: 8px;
                }
              }
            }
          }

          .MuiTableCell-head:first-child {
            text-align: left;
            padding: 7px 12px 4px 33px;
            border-right: none;
            border-left: none;
            border-bottom: none;

            .live-indicator {
              padding: 3px 11px;
              background-color: $color-Negative;
              color: $color-White;
              font-size: 12px;
              line-height: 15px;
              font-weight: 600;
              border-radius: 3px;
              margin-right: 13px;
            }

            @media (max-width: 799px) {
              padding: 7px 12px 4px 12px;
            }
          }
        }

        .individual-team-body {
          .MuiTableCell-root {
            padding: 10px 0px;
          }

          .multiple-team-row {
            .MuiTableCell-root {
              padding: 18px 3.5px;
            }
          }

          .time-market {
            font-family: $regulerFont;
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            padding: 14px 0px 2px;
            color: $color-Black;

            @media only screen and (max-width: 599px) {
              font-size: 11px;
              line-height: 14px;
            }
          }

          .team-wrap {
            display: flex;
            align-items: center;
            padding-left: 33px;

            @media only screen and (max-width: 599px) {
              padding-left: 12px;
            }

            .team-img-wrap {
              width: 45px;
              height: 45px;

              @media (max-width: 799px) {
                width: 32px;
                height: 32px;
              }

              .team-img {
                border-radius: 50%;
                width: 45px;
                height: 45px;
                object-fit: contain;

                @media (max-width: 799px) {
                  width: 32px;
                  height: 32px;
                }
              }
            }

            .mr-6 {
              margin-right: 6px;
            }

            .team-name {
              margin-left: 4px;
              font-family: $regulerFont;
              font-size: 16px;
              line-height: 20px;
              font-weight: 600;
              color: $color-Black;
              margin-right: 6px;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;

              @media only screen and (max-width: 799px) {
                font-size: 14px;
                line-height: 17px;
              }
            }
          }

          .individual-tablecell {
            padding: 0px 3.5px;
            border: 1px solid $color-grey;
            border-collapse: collapse;

            .odds-point {
              text-align: center;
              font-size: 16px;
              line-height: 20px;
              color: $color-Black;
              padding-bottom: 5px;

              @media only screen and (max-width: 599px) {
                font-size: 11.42px;
                line-height: 14px;
              }
            }

            .odds-style {
              display: block;
              cursor: pointer;
              max-width: none;
              width: 60px;
              text-align: center;
              padding: 6.5px 0px;
              border-radius: 6px;
              margin: 0px auto;
              background-color: $color-Light-grey;
              box-shadow: 0px 2px 1px 0px #707070;

              @media (max-width: 660px) {
                padding: 6.5px 0px;
                width: 54px;
              }

              @media (max-width: 389px) {
                width: 48px;
              }

              .odds {
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                font-family: $regulerFont;
                color: $color-Black;
                letter-spacing: 0px;

                @media (max-width: 660px) {
                  font-size: 11.42px;
                  line-height: 14px;
                }
              }
            }
          }

          .odds {
            // padding: 8px 0px;
            // background: #d6d9f3;
            border-radius: 6px;
            margin: 0px 3.5px;
            font-size: 16px;
            line-height: 20px;
            color: $color-Black;
            // min-width: 80px;
            // width: 80px;
            max-width: 80px;
            margin: 0 auto;
            display: block;
            text-align: center;
            filter: drop-shadow(0px 2px 2px #00000029);
            // margin-right: 7px;

            // @media (max-width: 1090px) {
            //   width: 55px;
            //   max-height: 30px;
            //   font-size: 11.42px;
            //   line-height: 14px;
            //   padding: 8px 0px;
            // }

            @media only screen and (max-width: 599px) {
              // padding: 8px 0px;
              // min-width: 45px;
              width: 45px;
              // max-height: 30px;
              // font-size: 11.42px;
              // line-height: 14px;
            }

            .no-odds {
              cursor: pointer;
            }

            .odds-btn {
              padding: 8px 0px;
              display: block;
              background: #d6d9f3;
              border-radius: 6px;
            }

            .odds-box-style {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;

              .odds-style {
                cursor: pointer;
                max-width: none;
                width: 60px;
                text-align: center;
                padding: 6.5px 0px;
                border-radius: 6px;
                margin: 0px auto;
                background-color: $color-Light-grey;
                box-shadow: 0px 2px 1px 0px #707070;

                @media (max-width: 660px) {
                  padding: 6.5px 0px;
                  width: 54px;
                }

                @media (max-width: 389px) {
                  width: 48px;
                }

                .odds {
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 400;
                  font-family: $regulerFont;
                  color: $color-Black;
                  letter-spacing: 0px;

                  @media (max-width: 660px) {
                    font-size: 11.42px;
                    line-height: 14px;
                  }
                }
              }

              .active-odd-style {
                background-color: $color-Primary;

                span,
                .odds {
                  color: $color-White;
                }
              }

              .odd-img {
                width: 60px;
                height: 25px;
                margin: 0 auto;
                margin-top: 6px;

                @media (max-width: 660px) {
                  // max-width: 54px;
                  width: 54px;
                }

                @media (max-width: 389px) {
                  width: 48px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: fill;
                  border-radius: 8px;
                }
              }
            }
          }

          .individual-team-row {
            position: relative;

            .show-tooltip {
              box-shadow: 10px 10px 5px 0px #0000000f;
              -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
              -moz-box-shadow: 10px 10px 5px 0px #0000000f;
              right: 0;
              // left: 134px;
              left: 133px;

              @media (max-width: 799px) {
                width: 185px;
                transform: translateX(-118%);
                // left: 35px;
                // top: -28px;
                left: 133px;
                top: 36px;
              }

              .tooltip-arrow {
                right: -10px;
                left: -12px;
                transform: rotate(90deg);

                // left: -12px;
                // transform: rotate(90deg);

                @media (max-width: 799px) {
                  left: initial;
                  // right: -12px;
                  // transform: rotate(270deg);

                  right: 50px;
                  transform: rotate(540deg);
                  top: -9px;
                }
              }
            }
          }

          // .individual-team-row:not(:last-child)::after {
          //   content: "";
          //   background-color: $color-grey;
          //   height: 1px;
          //   width: 100%;
          //   position: absolute;
          //   left: 0;
          //   right: 0;
          //   bottom: 0;
          //   margin: 0 auto;
          //   z-index: 91;
          // }

          // .multiple-team-row:not(:last-child)::after {
          //   width: 100%;
          // }
        }
      }
    }

    .overflow-clip {
      overflow-x: clip;
    }
  }
}

.custom-tooltip {
  .smartboook-icon-box {
    cursor: pointer;
    background-color: #e7e9ec;
    margin-left: 9.5px;
    margin-right: 5px;
    height: 32px;
    width: 34px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .book-icon {
      object-position: center;
    }

    .smartboook-add {
      position: absolute;
      bottom: -3px;
      right: -2px;
    }
  }

  .mr {
    margin-left: 0px;
    margin-right: 15px;
  }
}

.MuiButton-root.Mui-disabled {
  color: #00000042;
  background: #00000042;
  opacity: 0.5;
}

.bookmaker-wrap {
  min-width: 80px;
  max-height: 36px;
  text-align: center;

  @media (max-width: 1365px) {
    min-width: 50px;
  }

  @media (max-width: 599px) {
    max-width: 45px;
    min-width: 45px;
    max-height: 30px;
    min-height: 30px;
  }
}

.MuiTooltip-popper {
  top: 0px !important;
}

.arrow-wrap {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 12px;
  z-index: 99;

  .arrow {
    background-color: #707070;
    width: 32px;
    height: 26px;

    .MuiSvgIcon-root {
      width: 32px;
      height: 26px;
      color: #ffffff;
    }
  }

  .disable {
    background-color: #d8d8d8;
    width: 32px;
    height: 26px;

    .MuiSvgIcon-root {
      width: 32px;
      height: 26px;
      color: #ffffff;
    }
  }
}

.sport-indicator-btn {
  font-size: 11.42px !important;
  line-height: 15px !important;
  padding: 5px 10px !important;
  font-family: $primaryFont;
  font-weight: 400;
  color: $color-White;
  background-color: $color-Accent-1;
  border-radius: 23px;
  min-width: 30px;
  text-align: center;
  margin-right: 12px;
}

.live-tournament {
  margin-left: 16px;
}

.table-head {
  background-color: #e7e9ec;
}

.table-current {
  background-color: #4455c7;
  color: #ffffff !important;
}

.table-head-after {
  position: relative;
  width: 25%;

  &::after {
    content: "";
    background-color: $color-White;
    width: 3px;
    height: 32px;
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: 1365px) {
      height: 66px;
    }
  }
}

.table-sponsored-head {
  background-color: #003764;
  // width: 100%;
}

.sponsored-head-to-head {
  // background: linear-gradient(180deg,
  //     rgba(252, 71, 20, 1) 0%,
  //     rgba(243, 70, 23, 1) 15%,
  //     rgba(142, 64, 55, 1) 40%,
  //     rgba(100, 61, 68, 1) 60%,
  //     rgba(53, 58, 83, 1) 70%,
  //     rgba(106, 62, 66, 1) 85%,
  //     rgba(0, 55, 100, 1) 100%);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  text-align: center !important;
  margin: 0px auto !important;
  // width: auto !important;
  color: #d6d9f3 !important;
  font-weight: 800;
}

.sponsored-odds-body {
  margin-top: 15px;

  // &:not(:last-child) {
  //   margin-bottom: 21px;
  // }
}

.border-right {
  border-right: 0.6px solid #d4d6d8 !important;
}

.border-left {
  border-left: 0.6px solid #d4d6d8 !important;
}

// .current-best {
//   background-color: #eeefff;
// }

.td-cell {
  background-color: rgba(212, 214, 216, 0.13);
}

// .td-width {
//   width: 40%;
// }

.individual-table-mobileview {
  background: #ffffff;
  box-shadow: 0 3px 9px 0 RGB(0 0 0/5%);
  padding: 19px 18px 11px;

  .individual-table-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #4455c7;

    .current-best-head {
      display: flex;
      align-items: center;

      .current-best-mobile {
        font-size: 16px;
        line-height: 20px;
        font-family: $primaryFont;
        color: #4455c7;
        font-weight: 400;
        text-decoration: underline;
        margin-right: 6px;
      }

      .see-best {
        color: $color-Black;
        font-family: $primaryFont;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        cursor: pointer;

        // @media only screen and (max-width: 1023px) {
        //   top: -10px;
        // }
      }
    }
  }

  .oddstype {
    .select__single-value {
      color: $color-Black;
    }

    // .select__menu{
    //   z-index: 99;
    // }
  }

  .date-markets-section {
    padding: 8px 0px 17px;
    border-bottom: 1px solid #bdbdbf;
    font-size: 11px;
    font-family: $regulerFont;
    color: #191919;
    font-weight: 600;
    line-height: 14px;
  }

  .home-team-wrap {
    padding: 28px 0px 9px;
    border-bottom: 1px solid #bdbdbf;
  }

  .away-team-wrap {
    padding: 18px 0px 11px;
  }

  .team-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    .team-img-wrap {
      width: 45px;
      height: 45px;

      @media (max-width: 799px) {
        width: 32px;
        height: 32px;
      }

      .team-img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        object-fit: contain;

        @media (max-width: 799px) {
          width: 32px;
          height: 32px;
        }
      }
    }

    .mr-6 {
      margin-right: 6px;
    }

    .team-name {
      margin-left: 15px;
      font-family: $primaryFont;
      font-size: 16px;
      line-height: 23px;
      font-weight: 400;
      color: $color-Black;
    }

    .multipleteam-name {
      margin-left: 0px;
    }
  }

  .mobile-best5odds-wrap {
    .mobile-best5odds-select {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $color-attention;
      border-radius: 8px;
      color: $color-attention;
      padding: 8px;

      button {
        color: $color-attention;
      }
    }

    .next5odds-icon {
      border-bottom: none;
      padding: 21px 0px 0px 0px;
    }

    .bookmaker-thumb {
      margin: 4px auto 10px auto;
    }
  }

  .individual-team-mobile-table {
    margin-top: 21px;
  }

  .individual-team-mobile-row {
    td {
      padding: 0px 16px;
      border-bottom: none;
      text-align: center;

      .odds-point {
        padding-bottom: 5px;

        @media only screen and (max-width: 599px) {
          font-size: 11.42px;
          line-height: 14px;
        }
      }

      .odds {
        // padding: 5px 0px;
        background: #d6d9f3;
        border-radius: 6px;
        font-size: 11.42px;
        line-height: 14px;
        color: $color-Black;
        // min-width: 45px;
        width: 45px;
        margin: 0 auto;
        display: block;
        text-align: center;
        filter: drop-shadow(0px 2px 2px #00000029);

        .no-odds {
          cursor: pointer;
        }
      }

      .odds-btn {
        padding: 5px 0px;
        display: block;
        background: #d6d9f3;
        border-radius: 6px;
      }
    }
  }

  .odds-icon-seeall {
    td {
      padding-bottom: 18px;
    }
  }
}

.team-info {
  display: flex !important;
  align-items: center;
}

.score-icon {
  width: 25px;
  height: 25px;
  display: block;
  margin-left: 10px;

  @media (max-width: 799px) {
    width: 18px;
    height: 18px;
    margin-left: 0px;
  }

  svg {
    width: 100%;
    height: 100%;

    // path {
    //   fill: $color-Primary;
    // }
  }
}

.mobile-live-table {
  .live-match-no-pending {
    padding: 0px;
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableCell-head {
    font-family: $regulerFont;
    font-size: 11px;
    font-weight: 600;
    line-height: 20px;
    background-color: $color-Primary;
    color: $color-White;
    text-align: center;
    padding: 9px 0px;
  }

  .MuiTableCell-body {
    text-align: center;
    font-family: $regulerFont;
    font-size: 11.42px;
    font-weight: 400;
    line-height: 20px;
    color: $color-Black;
    padding: 11.5px 0px 15px;
    min-width: 15px;
  }

  .cell-center {
    text-align: center;
  }

  .score-cell {
    .icon-score-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 5px;

      .win-icon {
        width: 7px;
        height: 11px;
        display: flex;

        svg {
          width: 100%;
          height: 100%;
          transform: rotate(180deg);
        }
      }
    }

    .team-score {
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
    }
  }

  .score-team {
    font-size: 11.42px;
    line-height: 14px;
    font-weight: 400;
  }

  .cell-score-dark {
    background-color: $color-Primary;
    color: $color-White;
    padding: 9px;
  }

  .result-header {
    background-color: #e2e4f1;
    color: $color-Primary;
  }

  .result-body {
    background-color: #fafafa;
    color: $color-Primary !important;
    font-weight: 600 !important;

    .team-score {
      color: $color-Primary;
      font-weight: 600;
    }

    .score-team {
      color: $color-Primary;
    }

    .lose-team {
      opacity: 0.8;
    }
  }

  .score-auto {
    width: auto;
  }

  .score-50 {
    width: 50%;
  }

  .dark-right-border {
    border-right: 2px solid $color-White;
  }

  .dark-bottom-border {
    border-bottom: 2px solid $color-White;
  }

  .table-head {
    font-size: 16px;
    text-align: left;
    color: #003764;
    font-family: $primaryFont;
    background-color: transparent;
  }

  .tooltip-box {
    margin-left: 10px;

    .custom-tooltip {
      .smartboook-icon-box {
        cursor: pointer;
        background-color: #e7e9ec;
        margin-left: 9.5px;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        .book-icon {
          object-position: center;
        }

        .smartboook-add {
          position: absolute;
          bottom: -3px;
          right: -2px;
        }
      }

      .mr {
        margin-left: 0px;
        margin-right: 15px;
      }
    }

    .show-tooltip {
      box-shadow: 10px 10px 5px 0px #0000000f;
      -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
      -moz-box-shadow: 10px 10px 5px 0px #0000000f;
      right: 0;
      left: 134px;

      @media (max-width: 799px) {
        width: 185px;
        transform: translateX(-118%);
        // left: 35px;
        // top: -28px;
        left: 133px;
        top: 36px;
      }

      @media (max-width: 480px) {
        left: 240px;
        top: -110px;
      }

      .tooltip-arrow {
        right: -10px;
        left: -12px;
        transform: rotate(90deg);

        @media (max-width: 799px) {
          left: initial;
          // right: -12px;
          // transform: rotate(270deg);

          right: 50px;
          transform: rotate(540deg);
          top: -9px;
        }

        @media (max-width: 480px) {
          left: 12px;
          transform: rotate(0deg);
          top: 94px;
        }
      }
    }
  }
}

.win-odds-live {
  margin: 10px 0px 18px;

  .win-odds-padding {
    margin-bottom: 7px;
  }

  .odds-label {
    max-width: 100% !important;
    margin-top: 15px;
    margin-right: 50px !important;

    @media (max-width: 1023px) {
      margin-right: initial !important;
    }
  }

  .noodds-score {
    margin-right: 0px !important;
  }

  @media (max-width: 599px) {
    .odds-wrap {
      justify-content: space-between;
    }
  }

  .odds-long {
    min-width: 275px !important;
  }
}

.points {
  font-size: 15px;
  text-align: center;

  @media (max-width: 599px) {
    font-size: 11.42px;
    line-height: 14px;
  }
}

.betslip-added {
  background-color: $color-Primary !important;
  color: $color-White;

  span,
  .odds {
    color: $color-White;
  }
}

.tooltip {
  position: absolute;
  background-color: $color-White;
  width: 227px;
  height: auto;
  border: 1.5px solid #e7e9ec;
  padding: 4px 12px 6px;
  border-radius: 8px;
  z-index: 999;
  // top: calc(100% + 5px);
  left: 130px;
  transform: translateX(-36%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  text-align: left;
  top: -30px;

  @media (max-width: 799px) {
    width: 185px;
    transform: translateX(-118%);
    // left: 35px;
    // top: -28px;
    left: 122px;
    top: 36px;
  }

  .tooltip-arrow {
    position: absolute;
    top: 30px;
    left: -12px;
    transform: rotate(90deg);

    @media (max-width: 799px) {
      left: initial;
      // right: -12px;
      // transform: rotate(270deg);

      right: 50px;
      transform: rotate(540deg);
      top: -9px;
    }
  }

  .add-smartbook {
    cursor: auto;
    padding: 6px 10px 6px 8px;
    font-size: 11.42px;
    line-height: 14px;
    font-weight: 600;
    color: #003764;
    font-family: $regulerFont;
    border-bottom: 1px solid #e7e9ec;
    display: flex;
    align-items: center;
  }

  .black-details {
    padding-top: 3px;

    .tooltip-txt {
      padding: 6px 10px;
      font-size: 11.42px;
      line-height: 14px;
      font-weight: 400;
      color: $color-Black;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &:hover {
        background-color: #d4d6d8;
      }
    }

    .tooltip-txt:hover .icon-blacbook circle {
      fill: #003764;
    }

    .tooltip-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.outright-tooltip {
  @media (max-width: 799px) {
    // width: 185px;
    // transform: translateX(-118%);
    // left: 108px;
    // top: 44px;
    left: 26px !important;
    top: -15px !important;
  }

  .tooltip-arrow {
    @media (max-width: 799px) {
      right: -12px !important;
      transform: rotate(270deg) !important;
      top: 22px !important;
    }
  }
}

.show-tooltip {
  left: -170px;
  box-shadow: 10px 10px 5px 0px #0000000f;
  -webkit-box-shadow: 0px 1px 9px 0px #0000000f;
  -moz-box-shadow: 10px 10px 5px 0px #0000000f;

  @media (max-width: 799px) {
    width: 185px;
    transform: translateX(-118%);
    left: 130px;
    // left: 108px;
    top: 44px;
  }

  .tooltip-arrow {
    left: 248px;
    transform: rotate(270deg);

    @media (max-width: 799px) {
      left: initial;
      // right: 36px;
      right: 60px;
      transform: rotate(180deg);
      top: -9px;

      // right: -12px;
      // transform: rotate(270deg);
      // top: 22px;
    }
  }
}

.team-d-flex {
  display: flex;
}

.team-align-center {
  align-items: center;
}

.responsive-team-tooltip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0px;
}

.new-select-wrap {
  max-width: none;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  row-gap: 10px;

  @media only screen and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    row-gap: 37px;
  }

  .w-mobile-100 {
    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  .select-label {
    display: none;

    @media (max-width: 1023px) {
      display: block;
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      font-family: $regulerFont;
      color: $color-Black;
      margin-bottom: 12px;
    }
  }

  .series-select {
    min-width: 257px;
    margin-right: 4px;
    margin-top: 0px;

    @media only screen and (max-width: 1023px) {
      margin-right: 0px;
      min-width: auto;
      width: 100%;
    }
  }

  .odds-select {
    min-width: 257px;
    margin-right: 4px;

    @media only screen and (max-width: 1023px) {
      margin-right: 0px;
      min-width: auto;
      width: 100%;
    }
  }

  .details-search-picker {
    margin: 0;

    // @media only screen and (max-width: 479px) {
    //     grid-column-start: 1;
    //     grid-column-end: 4;
    // }

    .MuiOutlinedInput-root {
      background: #e7e9ec;
      border-radius: 8px;
      padding: 0;
      max-width: 180px;
      margin-right: 4px;

      @media only screen and (max-width: 1023px) {
        max-width: initial;
        width: 100%;
        margin-right: 0px;
      }

      input {
        padding: 9px 8px;
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
      }

      fieldset {
        border: none;
      }

      .MuiIconButton-root {
        padding: 0px 8px 0px 0px;
        margin: 0px;

        &:hover {
          background-color: transparent;
        }

        .MuiTouchRipple-root {
          display: none;
        }
      }
    }
  }

  #date-picker-inline::placeholder {
    color: #191919;
    opacity: 1;
  }

  .team-select {
    min-width: 140px;
    margin-right: 4px;

    @media only screen and (max-width: 1023px) {
      margin-right: 0px;
      min-width: auto;
      width: 100%;
    }
  }

  .market-select {
    min-width: 140px;
    margin-right: 4px;
    max-width: 180px;

    @media only screen and (max-width: 1023px) {
      margin-right: 0px;
      max-width: none;
      width: 100%;
    }
  }

  .disable-state {
    .select__single-value {
      opacity: 0.4;
    }

    .select__indicators {
      opacity: 0.4;
    }
  }

  .stepper-input {
    display: flex;
    border: 1px solid #c9c9c9;
    border-radius: 8px;
    max-height: 36px;

    .stepper-input__button {
      padding: 10px 14px;
      min-width: 35px;
      cursor: pointer;
      border-radius: 0px;

      border-left: 1px solid #c9c9c9;
      border-collapse: collapse;

      &:first-child {
        border-left: 0px;
        border-right: 1px solid #c9c9c9;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }

    .stepper-input__content {
      font-family: $regulerFont;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      padding: 8px 26px;

      color: $color-Black;
      width: 100%;
      text-align: center;
    }

    @media only screen and (max-width: 479px) {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }

  .select__control {
    background-color: $color-Light-grey;
    border-radius: 8px;
    max-height: 36px;
    max-width: 400px;

    @media (max-width: 1023px) {
      max-width: none;
    }

    .select__single-value {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 600;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__placeholder {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 600;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__input-container {
      font-family: $regulerFont !important;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 600;
      color: $color-Black;
      padding: 0px 8px;
    }

    .select__indicator-separator {
      width: 0px;
    }

    .select__dropdown-indicator {
      padding: 10px;
    }
  }

  .select__menu-portal {
    z-index: 99;
  }

  .show-all-fixtures {
    .fixtures-all {
      font-family: $regulerFont;
      font-size: 16px;
      line-height: 22.4px;
      font-weight: 600;
      color: $color-White;
      background-color: $color-Primary;
      border-radius: 8px;
      text-transform: capitalize;

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .switch-box {
    margin-right: 30px;

    @media (max-width: 1023px) {
      margin-right: 0px;
      width: 100%;
    }

    .MuiFormControlLabel-root {
      margin-left: 5px;
      max-width: max-content;

      @media (max-width: 1023px) {
        justify-content: space-between;
        width: 100%;
        max-width: none;
        margin-left: 0px;
      }

      .MuiSwitch-root {
        padding: 0px;
        width: 39px;
        height: 20px;
        border-radius: 10px;
        margin-left: 9px;

        .MuiButtonBase-root {
          padding: 0px;
        }

        .Mui-checked {
          color: #4455c7;
        }

        .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
          background-color: #d6d9f3;
        }
      }

      .MuiTypography-root {
        font-size: 16px;
        line-height: 19px;
        font-weight: 600;
        font-family: $regulerFont;
        width: max-content;
      }
    }
  }
}

.odds-flucs-datepicker {
  padding: 0px 33px 15px;
  border-bottom: 1px solid $color-grey;

  @media (max-width: 1023px) {
    padding: 0px 12px 15px !important;
  }

  .details-search-picker {
    margin: 0;

    @media (max-width: 1023px) {
      width: 100%;
    }

    .MuiOutlinedInput-root {
      border: 1px solid $color-grey;
      border-radius: 8px;
      padding: 0;
      max-width: 240px;

      @media only screen and (max-width: 1023px) {
        max-width: initial;
        width: 100%;
        margin-right: 0px;
        max-width: none;
      }

      input {
        padding: 9px 8px;
        font-family: $regulerFont !important;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        color: $color-Black;
      }

      fieldset {
        border: none;
      }

      .MuiIconButton-root {
        padding: 0px 8px 0px 0px;
        margin: 0px;

        &:hover {
          background-color: transparent;
        }

        .MuiTouchRipple-root {
          display: none;
        }
      }
    }
  }

  #date-picker-inline::placeholder {
    color: #191919;
    opacity: 1;
  }
}

.filter-drawer-section {
  .MuiDrawer-paper {
    height: calc(100vh - 70px);
    background: linear-gradient(
      180deg,
      rgba(229, 234, 239, 1) 0%,
      rgba(147, 160, 173, 1) 98%
    );
    border-radius: 16px 16px 0px 0px;

    .filter-header {
      background-color: $color-Primary;
      border-radius: 16px 16px 0px 0px;
      padding: 11px 12px 9px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      width: calc(100% - 24px);
      z-index: 9;

      p {
        font-size: 22.4px;
        line-height: 28px;
        font-family: $primaryFont;
        font-weight: 400;
        letter-spacing: 0px;
        color: $color-White;
      }

      svg {
        path {
          fill: $color-White;
        }
      }
    }

    .filter-body {
      padding: 28px 12px 27px;
      margin-top: 48px;
    }

    .filter-footer {
      position: fixed;
      bottom: 0px;
      width: 100%;
      padding-bottom: 15px;

      .footer-text {
        padding: 0px 12px;
        font-size: 11.42px;
        line-height: 14px;
        font-weight: 400;
        font-family: $regulerFont;
        color: $color-Black;
        margin-bottom: 27px;
        padding-bottom: 27px;
        border-bottom: 1px solid $color-Primary;
      }

      .button-section {
        padding: 0px 12px;

        .filter-apply {
          width: 100%;
          padding: 8px 24px 12px 24px;
          background-color: $color-Accent-1;
          color: $color-White;
          font-size: 16px;
          line-height: 20px;
          font-weight: 400;
          font-family: $regulerFont;
          border-radius: 8px;
          text-transform: capitalize;
          cursor: pointer;
          margin-bottom: 9px;
        }
      }

      .reset-all {
        display: flex;
        align-items: center;
        justify-content: center;

        .reset-all-text {
          font-size: 13px;
          line-height: 16px;
          font-weight: 400;
          color: $color-White;
          font-family: $regulerFont;
          border-bottom: 1px solid $color-White;
        }
      }
    }
  }
}
